import React, { PureComponent } from 'react';
import {
  BarChart, Bar, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

// const data = [
//   { "name": "Enero", "Caracas": 4000, "Valencia": 2400 },
//   { "name": "Febrero", "Caracas": 3000, "Valencia": 1398 },
//   { "name": "Marzo", "Caracas": 2000, "Valencia": 9800 },
//   { "name": "Abril", "Caracas": 2780, "Valencia": 3908 },
//   { "name": "Mayo", "Caracas": 1890, "Valencia": 4800 },
//   { "name": "Junio", "Caracas": 2390, "Valencia": 3800 },
//   { "name": "Julio", "Caracas": 3490, "Valencia": 4300 }
// ]
//   ;

var obj = {
  "name": "1 - Hambre cero",
  "values": [
    {
      "key": "Cantidad Aliados",
      "value": 300
    }
  ]
}
var dataProps = [];
var linesProps = [];
var data = [];
var lines = []
var color = "";

export default class BarGraph extends PureComponent {
  //   static jsfiddleUrl = 'https://jsfiddle.net/alidingling/30763kr7/';

  state = {
    activeIndex: 0,
    lines : [],
    key: "",
    data: [],
    color: "",
  };
  componentDidMount() {
    dataProps = this.props.data;
    linesProps = this.props.lines;
    lines = [];
    data = [];
    // alert(linesProps)
      
    dataProps.map(element => {
      data.push({ name: element.name, [element.values[0].key]: element.values[0].value,color: element.values[0].color })
    })

    linesProps.map(line => {
      lines.push({ dataKey: line.key, fill: line.color })
    })

     

    this.setState({
      data: data,
      lines : lines
    })

  }

  render() {
    return (
      // <ResponsiveContainer width={700} height="80%">
      <div style={{overflowY: "auto"}}>
      <BarChart
        width={600}
        height={300}
        data={this.state.data}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis hide={true} dataKey="name" />
        <YAxis />
        <Tooltip />
        {/* <Legend /> */}
        {
          this.state.lines.map((bar,i) => (
            <Bar dataKey={"Cantidad Aliados"} fill={bar.fill} />
          ))
         
        }   
      </BarChart>
      </div>
    );
  }
}
