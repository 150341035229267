import { SAVING_JSONTABLES } from '../Constants'

export const saveForms = (state = {} ,action) => {
    switch (action.type) {
        
        case SAVING_JSONTABLES:
            return {...state, 
                saveJSONTABLES: action.payload,
            }   
        default:
            return state;
    }
}