
import axios from 'axios'
import { ALL_USERS } from '../../Constants';
import {showErrorModal} from '../_actionShowErrorModal'

export function getAllUsers() {
    
    return dispath => {
        let properties = JSON.parse(localStorage.getItem('properties'));
        let ALLUSERS_ENPOINT = properties.ENPOINTS.ALLUSERS_ENPOINT;
        let Authorization = localStorage.getItem('Authorization');
        axios({
            method: 'GET',
            url: ALLUSERS_ENPOINT,
            headers: {
                "Content-Type":"application/json",
                "Authorization": "Bearer " + Authorization
            }
        }).then((response) => {
            localStorage.setItem('allUsers', JSON.stringify(response));
            let totalPagePagination = Math.ceil(response.data.data.length / properties.totalRegisterForPagination)
            
            dispath({
                type: ALL_USERS,
                payload: true,
                allUsersObject: response.data.data,
                totalPagePagination: totalPagePagination
            })
        })
            .catch(error => {
                // dispath(circularProgress(false))
                // dispath(openModalChangePassword(false))
                let message = properties.message
                if(error.response){
                properties.errorMessage.map((element) => {
                    if(element.value === error.response.data.code){
                        message = element.message
                    }
                })
                }
                dispath(showErrorModal(true, message))
            });
    }
}
