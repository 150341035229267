// DEPENDENCIAS
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import { styles } from './constants';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';


// ACTIONS
import {
    openModalChangePasswordUser,
} from '../../../../Actions/_actionUserManagement';
import {postRememberPassword} from '../../../../Actions/Services/postRememberPassword'
//CONSTANTES
const patternConst = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,9}$/i

// COMPONENTE TIPO CLASE
class ChangePasswordUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    // FUNCIONES
    componentDidMount = () => {
        this.setState({disabledButton: true, email:this.props.userEmailChangePassword})
        if(this.props.userEmailChangePassword !== ''){
            this.setState({disabledButton: false})
        }
        
    };

    openModalChangePasswordUser = () => {
        this.setState({ openModalChangePasswordUser: !this.state.openModalChangePasswordUser})
    };
    
    changePasswordUserEmail = () => {
        this.props.openModalChangePasswordUser(false)
        this.props.postRememberPassword(this.state.email, 'private')
        this.setState({disabledButton: true})
    }

    closeModal = () => {
        this.props.openModalChangePasswordUser(false)
    };

    onChangeValues = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        })
        this.validateTextField(name,value)
    };

    validateTextField = (name, value) => {
        this.setState({['newErrorInput'+name]: true})
        switch (name) {
            case 'email':
              this.validateWhitPattern(value, name, patternConst)
              break;
          }
    }

    validateWhitPattern = (value, name, patternConst) => {
        if(patternConst.test(value)){
            this.setState({['bool'+name]: true},
            ()=> { 
                if(this.state.email !== patternConst){
                    this.setState({boolemail: true})
                    this.setState({disabledButton: false})
                }else {
                    this.setState({boolemail: false})
                    this.setState({disabledButton: false})
                }
            })
        }else {
            this.setState({['bool'+name]: false})
            this.setState({disabledButton: true})
        } 
    }

    render() {

        const { classes } = this.props;
        
        return (
            <>
                <Dialog
                    open={true}
                    onClose={this.state.openModalChangePasswordUser}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="xs"
                >
                    <div className={classes.Xclose} onClick={this.closeModal}>
                    <ClearIcon ></ClearIcon>
                    </div>
                    <DialogTitle id="alert-dialog-title" className={classes.title}>
                        <stron>Reseteo de Clave</stron>
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container  direction="row" spacing={1} >
                            <Grid item xs={12} md={12}>
                                <TextField
                                    color="primary"
                                    autoFocus
                                    margin="dense"
                                    required
                                    fullWidth
                                    label="Correo"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.onChangeValues}
                                    InputProps={{
                                        endAdornment:
                                        <>
                                        {this.state.newErrorInputemail &&
                                          <>
                                            {this.state.boolemail ?
                                              <InputAdornment>
                                                <CheckIcon style={{ marginRight: '6px', color: 'green' }} />
                                              </InputAdornment>
                                            :
                                              <InputAdornment>
                                                <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                              </InputAdornment>
                                            }   
                                          </>
                                        }
                                          
                                        </>
                                      }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div className={classes.alignButton}>
                            <Button
                            margin="dense"
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.closeModal}
                            >
                            <strong>Cerrar</strong>
                            </Button>
                        </div>
                        <div className={classes.alignButton}>
                            <Button
                            margin="dense"
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={this.state.disabledButton}
                            onClick={this.changePasswordUserEmail}
                            >
                            <strong>Recuperar</strong>
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
          </>
        )
        
    }
}

const changePasswordUser = withStyles(styles, { withTheme: true })(ChangePasswordUser);

function mapStateToProps(state) {
    return {
        viewChangePasswordModal: state.userManagement.viewChangePasswordModal,
        userEmailChangePassword: state.userManagement.userEmailChangePassword
    };
}

export default connect(
    mapStateToProps,
    {
        openModalChangePasswordUser,
        postRememberPassword
    }
)(changePasswordUser);