import React from 'react';
import {Switch} from 'react-router-dom';
import Route from './routes';
import { ThemeProvider } from '@material-ui/styles';
import { withRouter } from "react-router-dom";

//COMPONENTES
import Home from "../Components/Dashbord";
import Login from "../Components/Login";

//CONSTANTES
import { MIUER_THEME } from '../Constants';

const theme = MIUER_THEME;

export default function Routes() {
    return (
        <Switch>
            <ThemeProvider theme={theme}>  
                <Route path="/" exact component={Login} />
                <Route path="/home" component={Home} isPrivate/>
            </ThemeProvider>
        </Switch>
    );
}