import { NOMBRE_DE_LA_CONTSTANTE1, NOMBRE_DE_LA_CONTSTANTE2, NOMBRE_DE_LA_CONTSTANTE3 } from '../Constants'

export const ejemplo = (state = {} ,action) => {

    switch (action.type) {
        
        case NOMBRE_DE_LA_CONTSTANTE1:
            return {...state, valor1: action.payload1}   

        case NOMBRE_DE_LA_CONTSTANTE2:
            return {...state, valor1: action.payload2}   

        case NOMBRE_DE_LA_CONTSTANTE3:
            return {...state, 
                valor1: action.payload3,
                valor2: action.other,
            }   

        default:
            return state;
    }
}