// DEPENDENCIAS
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import { styles } from './constants';

// ACTIONS
import {
    openModalViewUser,
} from '../../../../Actions/_actionUserManagement';
import {getTypeUser} from '../../../../Actions/Services/getTypeUser'
import {getCountries} from '../../../../Actions/Services/getCountries'
import { putUpdateUser } from '../../../../Actions/Services/putUpdateUser'
var countries
var typeUserCombo
 class ViewUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            confirmEditModal: false,

        }
    }

    componentDidMount = () => {
        countries = JSON.parse(localStorage.getItem('countries'));
        typeUserCombo = JSON.parse(localStorage.getItem('typeUserCombo'));      
        
        this.setState({
            selectCountry:this.props.userCountry,
            userEmail: this.props.userEmail,
            userName: this.props.userName,
            userSurname: this.props.userSurname,
            userType: this.props.userType,
            userCountry: this.props.userCountry,
        })

    }

    openModalViewUser = () => {
        this.setState({ openModalViewUser: !this.state.openModalViewUser})
    };
    
    closeModal = () => {
        this.props.openModalViewUser(false)

    };

    updateUser = () => {
        this.props.openModalViewUser(false)
        this.props.putUpdateUser(
            this.props.userById.user_id,
            this.state.userName, 
            this.state.userSurname, 
            this.state.userEmail, 
            this.state.userCountry,
            this.state.userType,
            this.props.applications)
    }
    
    onChangeValues = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        });
    }

    confirmationEdit = () => {
        this.setState({open: false, confirmEditModal: true,})      
    }

    backModal = () => {
        this.setState({open: true, confirmEditModal: false,})
    }

    onChangeValue = (event) =>{
        let name = event.target.name
        let value = event.target.value
        this.setState({
            [name]: value
        })
    }

    render() {

        const { classes } = this.props;
        
        return (
            <>
                <Dialog
                    open={this.state.open}
                    onClose={this.state.openModalViewUser}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="xs"
                >
                    <div className={classes.Xclose} onClick={this.closeModal}>
                    <ClearIcon ></ClearIcon>
                    </div>
                    <DialogTitle id="alert-dialog-title" className={classes.title}>
                        
                        {this.props.editOption ?
                            <stron>Datos del Usuario</stron>
                        :
                            <stron>Editar Usuario</stron>
                        }
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container  direction="row" spacing={1} >
                            <Grid item xs={12} md={12}>
                                <TextField
                                    color="primary"
                                    margin="dense"
                                    required
                                    fullWidth
                                    label="Email"
                                    name="userEmail"
                                    value={this.state.userEmail}
                                    onChange={this.onChangeValues}
                                    // disabled={this.props.editOption}
                                    InputProps={{
                                        readOnly: this.props.editOption,
                                      }}                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    color="primary"
                                    margin="dense"
                                    required
                                    fullWidth
                                    label="Nombre"
                                    name="userName"
                                    value={this.state.userName}
                                    onChange={this.onChangeValues}
                                    // disabled={this.props.editOption}
                                    InputProps={{
                                        readOnly: this.props.editOption,
                                      }}                                />
                            </Grid>       
                            <Grid item xs={12} md={12}>
                                <TextField
                                    color="primary"
                                    margin="dense"
                                    required
                                    fullWidth
                                    label="Apellido"
                                    name="userSurname"
                                    value={this.state.userSurname}
                                    onChange={this.onChangeValues}
                                    // disabled={this.props.editOption}
                                    InputProps={{                                                                                                                           
                                        readOnly: this.props.editOption,
                                    }}
                                />
                            </Grid>       
                            <Grid item xs={12} md={12}>
                                {this.props.editOption ?
                                    <FormControl className={classes.select} fullWidth>
                                        <InputLabel >Tipo de Usuario</InputLabel>
                                        <Select
                                        name="userType"
                                        onChange={this.onChangeValues}
                                        value={this.props.userType}
                                        inputProps={{ readOnly: this.props.editOption }}  
                                        >
                                        {typeUserCombo && 
                                            typeUserCombo.map((type) => (
                                            <MenuItem key={type.id_rol} value={type.id_rol} >{type.nombre_rol}</MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                :
                                    <FormControl className={classes.select} fullWidth>
                                        <InputLabel >Tipo de Usuario</InputLabel>
                                        <Select
                                        name="userType"
                                        onChange={this.onChangeValues}
                                        value={this.state.userType}
                                        >
                                        {typeUserCombo && 
                                            typeUserCombo.map((type) => (
                                            <MenuItem key={type.id_rol} value={type.id_rol} >{type.nombre_rol}</MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                }
                            </Grid>
                            <Grid item xs={12} md={12}>
                                
                                {this.props.editOption ?
                                    <FormControl className={classes.select} fullWidth>
                                        <InputLabel>País</InputLabel>
                                        <Select
                                        name="userCountry"
                                        value={this.state.userCountry}
                                        onChange={this.onChangeValue}
                                        inputProps={{ readOnly: this.props.editOption }}   
                                        >
                                        {countries &&
                                            countries.map((element) => (
                                            <MenuItem key={element.id_pais} value={element.id_pais} >{element.nombre_pais}</MenuItem>
                                        ))} 
                                        </Select>
                                    </FormControl>
                                :
                                    <FormControl className={classes.select} fullWidth>
                                        <InputLabel>País</InputLabel>
                                        <Select
                                        name="userCountry"
                                        value={this.state.userCountry}
                                        onChange={this.onChangeValue}
                                        >
                                        {countries &&
                                            countries.map((element) => (
                                            <MenuItem key={element.id_pais} value={element.id_pais} >{element.nombre_pais}</MenuItem>
                                        ))} 
                                        </Select>
                                    </FormControl>
                                }
                                
                            </Grid>
                        </Grid>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div className={classes.alignButton}>
                            {this.props.editOption === false &&
                                <Button
                                    margin="dense"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={this.closeModal}
                                >
                                    <strong onClick={this.confirmationEdit}>Cerrar</strong>
                                </Button>
                            }
                            {this.props.editOption ?
                                <Button
                                    margin="dense"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={this.closeModal}
                                    >
                                    <strong>Cerrar</strong>
                                </Button>
                            :
                                <Button
                                    margin="dense"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={this.confirmationEdit}
                                    >
                                    <strong>Editar</strong>
                                </Button>
                            }
                        </div>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.confirmEditModal}
                    // onClose={this.state.removeUserOpenModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="xs"
                >
                    <div className={classes.Xclose} onClick={this.closeModal}>
                    <ClearIcon ></ClearIcon>
                    </div>
                    <DialogTitle id="alert-dialog-title" className={classes.title}>
                        <stron>Editar Usuario</stron>
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container  direction="row" spacing={1} >
                            <Grid item xs={12} md={12}>
                                <Typography className={classes.typografia}>
                                    <strong>¿Seguro que desea editar usuario?</strong>
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div className={classes.alignButton}>
                            <Button
                            margin="dense"
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.backModal}
                            >
                            <strong>Regresar</strong>
                            </Button>
                        </div>
                        <div className={classes.alignButton}>
                            <Button
                            margin="dense"
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.updateUser}
                            >
                            <strong>Aceptar</strong>
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
          </>
        )
        
    }
}

const viewUser = withStyles(styles, { withTheme: true })(ViewUser);

function mapStateToProps(state) {
    return {
        viewUserModal: state.userManagement.viewUserModal,
        editOption: state.userManagement.editOption,
        userEmail: state.userManagement.userEmail,
        userName: state.userManagement.userName,
        userSurname: state.userManagement.userSurname,
        userType: state.userManagement.userType,
        userCountry: state.userManagement.userCountry,
        applications: state.userManagement.applications,
        getCountriesCombo: state.getCountries.getCountriesCombo,
        typeUser: state.userType.typeUser,
        userById: state.getUserById.userById,
    };
}

export default connect(
    mapStateToProps,
    {
        openModalViewUser,
        getTypeUser,
        getCountries,
        putUpdateUser
    }
)(viewUser);