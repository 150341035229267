
import axios from 'axios'
import { LOGIN } from '../../Constants';
import { showErrorModal } from '../_actionShowErrorModal'
import { loader } from '../../Actions/_actionLoader'
import { getDashboard } from '../Services/getDashboard'

export function postLogin(username, password, payload) {

    return dispath => {
        var properties = JSON.parse(localStorage.getItem('properties'));
        var AUTHETICATOR_ENDPOINT = properties.ENPOINTS.AUTHETICATOR_ENDPOINT;
        axios({
            method: 'POST',
            url: AUTHETICATOR_ENDPOINT,
            data: {
                "user": username,
                "password": password
            }
        }).then((response) => {
            dispath(loader(false))
            localStorage.setItem('Authorization', response.data.data.token)
            dispath({
                type: LOGIN,
                payload: true,
            })
            if (response.data.data.aplicaciones !== null && response.data.data.aplicaciones !== undefined && response.data.data.aplicaciones !== '' && response.data.data.aplicaciones[0].menu !== null && response.data.data.aplicaciones[0].menu !== '' && response.data.data.aplicaciones[0].menu !== undefined) {
                localStorage.setItem('signed', true)

                localStorage.setItem('Rol', response.data.data.aplicaciones[0].roles[0].id_rol)
                localStorage.setItem('responseLogin', JSON.stringify(response));
                window.location.reload()
                var menuLeft = [];
                var menuLeftChild = [];
                var menuTop = [];
                response.data.data.aplicaciones.map((element) => {
                    if (element.nombre_aplicacion === properties.nameMenuUsed) {
                        element.menu.map((value, index) => {

                            if (value.parent_id === null) {
                                if (value.position === properties.ubicationMenu.iconMenuLeft) {
                                    menuLeft.push({
                                        'icon': value.icon,
                                        'id_menu': value.id_menu,
                                        'priority': value.priority,
                                        'siplay_value': value.display_value,
                                        'react_component': value.react_component,
                                        'childs': []
                                    })
                                }
                            } else {
                                menuLeftChild.push({
                                    'icon': value.icon,
                                    'id_menu': value.id_menu,
                                    'priority': value.priority,
                                    'siplay_value': value.display_value,
                                    'parent_id': value.parent_id,
                                    'react_component': value.react_component,
                                })
                            }
                            if (value.position === properties.ubicationMenu.iconMenuTop) {
                                menuTop.push({
                                    'icon': value.icon,
                                    'id_menu': value.id_menu,
                                    'priority': value.priority,
                                    'siplay_value': value.display_value,
                                    'react_component': value.react_component,
                                })
                            }
                        })

                    }
                });
                menuLeft.map((val) => {
                    menuLeftChild.map((value) => {
                        if (parseInt(value.parent_id) === val.id_menu) {
                            val.childs.push(value)
                        }
                    })
                })
                localStorage.setItem('menuLeft', JSON.stringify(menuLeft));
                localStorage.setItem('menuTop', JSON.stringify(menuTop));

            } else {
                dispath(showErrorModal(true, "Usuario no autorizado para ingresar en esta aplicación."))
            }

        })
            .catch(error => {
                dispath(loader(false))
                let message = properties.message
                if (error.response) {
                    properties.errorMessage.map((element) => {
                        if (element.value === error.response.data.code) {
                            message = element.message
                        }
                    })
                }
                dispath(showErrorModal(true, message))

            });
    }
}
