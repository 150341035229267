
import axios from 'axios'
import { USERTYPE_COMBO } from '../../Constants';
import {showErrorModal} from '../_actionShowErrorModal';

function firstCharUp(string){
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getTypeUser() {
    return dispath => {
        let properties = JSON.parse(localStorage.getItem('properties'));
        let TYPEUSERCOMBO_ENPOINT = properties.ENPOINTS.TYPEUSERCOMBO_ENPOINT;
        axios({
            method: 'GET',
            url: TYPEUSERCOMBO_ENPOINT,
            headers: {
                "Content-Type":"application/json",
            }
        }).then((response) => {
            var typeCombo = []
            response.data.data.map((element, i) => {
                if(element.id_aplicacion === 1){
                    let nombreRol = firstCharUp(element.nombre_rol.toLowerCase());
                    typeCombo.push({
                        id_rol: element.id_rol,
                        es_publico: element.es_publico,
                        nombre_rol: nombreRol,
                        id_aplicacion: element.id_aplicacion,
                        nombre_aplicacion: element.nombre_aplicacion,
                    })
                }
            })
            localStorage.setItem('typeUserCombo', JSON.stringify(typeCombo));
            dispath({
                type: USERTYPE_COMBO,
                payload: true,
                typeCombo: typeCombo
            })
        })
            .catch(error => {
                let errorObject = JSON.parse(JSON.stringify(error));
                console.log(errorObject)
                dispath({
                    type: USERTYPE_COMBO,
                    payload: false,
                    typeCombo: null,
                })
            });
    }
}
