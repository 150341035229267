// DEPENDENCIAS
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { styles } from './constants';

// ACTIONS
import {openModalChangePassword} from '../../../Actions/_actionDashboard';
import {putChangePassword} from '../../../Actions/Services/putChangePassword';
import { loader } from '../../../Actions/_actionLoader';
import { circularProgress } from '../../../Actions/_actionCircularProgress';

// COMPONENTES
import ModalAlert from '../../ModalAlert'

//CONSTANTES
const properties = JSON.parse(localStorage.getItem('properties'));
const patternConst = /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\d\X])(?=.*[-+_!@#$%^&*.,?]).*$/

// COMPONENTE TIPO CLASE
 class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            open: true,
            openMessage: false,
            auth: true,
            anchorEl: null,
            currentPassword: '',
            newPassword: '',
        }
    }

    // FUNCIONES
    componentDidMount = () => {
        this.props.loader(true)
    };
    
    openModalChangePassword = () => {
        this.setState({ openModalChangePassword: !this.state.openModalChangePassword})
    };
    
    closeModal = () => {
        this.props.openModalChangePassword(false)
    };
    
    changePassword = () => {
      this.props.circularProgress(true)
      this.props.loader(true)
      // this.props.openModalChangePassword(false)
      this.props.putChangePassword(this.state.currentPassword,this.state.newPassword)
      // this.setState({
      //   open: false, 
      // })
    };

    onChangeValues = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        })
        this.validateTextField(name,value)
    };

    validateTextField = (name, value) => {
        this.setState({['newErrorInput'+name]: true})
        switch (name) {
            case 'currentPassword':
              this.validateWhitPattern(value, name, patternConst)
              break;
            case 'newPassword':
              this.validateWhitPattern(value, name, patternConst)
              break;
            case 'repeatNewPassword':
              this.validateWhitPattern(value, name, patternConst)
              break;
            default:
              break;
          }
    }

    validateWhitPattern = (value, name, pattern) => {
        if(pattern.test(value)){
            this.setState({['bool'+name]: true},
            ()=> { 
                if(this.state.repeatNewPassword !== this.state.newPassword){
                    this.setState({boolrepeatNewPassword: false})
                    this.props.loader(true)
                }else {
                    this.setState({boolrepeatNewPassword: true})
                    if(this.state.currentPassword !== '' && this.state.currentPassword !== undefined && this.state.boolcurrentPassword === true){
                      this.props.loader(false)
                    }
                }
            })
        }else {
            this.setState({['bool'+name]: false})
            this.props.loader(true)
        }    
      }

    // RENDERIZADO
    render() {
        const { classes } = this.props;
        return (
            <>
                <Dialog
                    open={this.state.open}
                    onClose={this.state.openModalChangePassword}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="xs"
                >
                    <div className={classes.Xclose} onClick={this.closeModal}>
                    <ClearIcon ></ClearIcon>
                    </div>
                    <DialogTitle id="alert-dialog-title" className={classes.title}>
                        <stron>Cambio de contraseña</stron>
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <TextField
                          color="primary"
                          margin="dense"
                          required
                          fullWidth
                          label="Contraseña Actual"
                          type="password"
                          name="currentPassword"
                          value={this.state.currentPassword}
                          onChange={this.onChangeValues}
                          InputProps={{
                            endAdornment:
                            <>
                            {this.state.newErrorInputcurrentPassword &&
                              <>
                                {this.state.boolcurrentPassword ?
                                  <InputAdornment>
                                    <CheckIcon style={{ marginRight: '6px', color: 'green' }} />
                                  </InputAdornment>
                                :
                                  <InputAdornment>
                                    <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                  </InputAdornment>
                                }   
                              </>
                            }
                              
                            </>
                          }}
                        />
                        <Tooltip title={properties.formatPassword} placement="right-start">
                        <TextField
                          color="primary"
                          margin="dense"
                          required
                          fullWidth
                          label="Nueva Contraseña"
                          type="password"
                          name="newPassword"
                          value={this.state.newPassword}
                          onChange={this.onChangeValues}
                          InputProps={{
                            endAdornment:
                            <>
                            {this.state.newErrorInputnewPassword &&
                              <>
                                {this.state.boolnewPassword ?
                                  <InputAdornment>
                                    <CheckIcon style={{ marginRight: '6px', color: 'green' }} />
                                  </InputAdornment>
                                :
                                  <InputAdornment>
                                    <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                  </InputAdornment>
                                }   
                              </>
                            }
                              
                            </>
                          }}
                        />
                        </Tooltip>
                        <Tooltip title={properties.formatPassword} placement="right-start">
                        <TextField
                          color="primary"
                          margin="dense"
                          required
                          fullWidth
                          label="Repita Nueva Contraseña"
                          type="password"
                          name="repeatNewPassword"
                          value={this.state.repeatNewPassword}
                          onChange={this.onChangeValues}
                          InputProps={{
                            endAdornment:
                            <>
                            {this.state.newErrorInputrepeatNewPassword &&
                              <>
                                {this.state.boolrepeatNewPassword ?
                                  <InputAdornment>
                                    <CheckIcon style={{ marginRight: '6px', color: 'green' }} />
                                  </InputAdornment>
                                :
                                  <InputAdornment>
                                    <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                  </InputAdornment>
                                }   
                              </>
                            }
                            </>
                          }}
                        />
                        </Tooltip>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div className={classes.alignButton}>
                            <Button
                            margin="dense"
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.changePassword}
                            disabled={this.props.loaderPayload}
                            >
                            <strong>Cambiar contraseña</strong>
                            </Button>
                            {this.props.circularProgressPayload && <CircularProgress className={classes.circularProgress} color="primary"/>}
                        </div>
                    </DialogActions>
                </Dialog>
          </>
        )
    }
}

const changePassword = withStyles(styles, { withTheme: true })(ChangePassword);

function mapStateToProps(state) {
    return {
        changePasswordModal: state.dashboard.changePasswordModal,
        changePassword: state.changePass.changePassword,
        loaderPayload: state.loader.loaderPayload,
        showModal: state.errorShowModal.showModal,
        circularProgressPayload: state.progressCircular.circularProgressPayload
    };
}

export default connect(
    mapStateToProps,
    {
        openModalChangePassword,
        putChangePassword,
        loader,
        circularProgress,
    }
)(changePassword);