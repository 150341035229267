// DEPENDENCIAS
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TextField from '@material-ui/core/TextField';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Pagination from '@material-ui/lab/Pagination';
import { withStyles } from "@material-ui/core/styles";
import { styles } from './constants';
import { ReactComponent as IconSee } from './img/see.svg';
import { ReactComponent as IconEdit } from './img/edit.svg';
import { ReactComponent as IconDelete } from './img/delete.svg';
import { ReactComponent as IconAlert } from './img/alert.svg';



// COMPONENTES
import ModalDeleteOrganization from './ModalDeleteOrganization'

// ACTIONS
import {
    wizardAddOrganization,
    organizationDash
} from '../../../Actions/_actionDashboard';
import { deleteOrganization } from '../../../Actions/Services/deleteOrganization'
import { uploadImageMyOrganization, uploadImageDirection } from '../../../Actions/_actionUploadImage'
import { openModalDeleteOrganization } from '../../../Actions/_actionOrganizationDash'
import { getOrganizationById } from '../../../Actions/Services/getOrganizationById'
import { getOrganizationByUser } from '../../../Actions/Services/getOrganizationByUser'

// CONSTANTES
const style = styles;
const properties = JSON.parse(localStorage.getItem('properties'));

const objeto2 = {
    AgentData: {
        MisOrganizaciones: {
            NombreyApellido: '',
            siglas: '',
            TipoDonante: '',
            NumeroId: '',
            PaisOrigen: '',
            TipoAgente: '',
            AñosExp: '',
            Email: '',
            Web: '',
            Facebook: '',
            Twitter: '',
            Linkedin: '',
            MisionVision: '',
            Lineamientos: '',
        },
        DatosPersonaContacto: {
            Nombre: '',
            Apellido: '',
            Saludo: '',
            Cargo: '',
            Titulo: '',
            Correo: '',
            Telefono: '',
            MostrarContacto: ''
        },
    },
    DirectionData: {
        AreaTematica: '',
        AlcanceGeografico: '',
        PaisOpera: '',
        Longitud: -66.902,
        latitud: 10.491,
        TelefonoCentral: '',
        IdOrgien: '',
        NumeroOficina: '',
        TipoOng: '',
        ObjetivoDesarrollo: '',
        Personalidades: ''
    },
    ComplementaryData: {
        Nombre: ' ',
        Año: '',
        Monto: '',
        Pais: '',
        Porcentaje: '',
        MontoMonedaLocal: '',
        NombreMonedaLocal: '',
        MontoDolares: '',
        AreaTematica: '',
    },
}

const icons =
    [
        {
            icono: <IconButton style={{ background: '#494377', width: '30px', height: '30px', padding: '3px', marginRight: '4px', blockSize: '30px' }}><IconSee /></IconButton>,
            pointer: 1
        },
        {
            icono: <IconButton style={{ background: '#494377', width: '30px', height: '30px', padding: '6px', marginRight: '4px', blockSize: '30px' }}><IconEdit /></IconButton>,
            pointer: 2,
        },
        {
            icono: <IconButton style={{ background: '#494377', width: '30px', height: '30px', padding: '6px', marginRight: '4px', blockSize: '30px' }}><IconDelete /></IconButton>,
            pointer: 3,
        },

    ]

// COMPONENTE TIPO CLASE
class Organizations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            registerForPage: properties.totalRegisterForPagination,
            indexOfFirstUser: 0,
            indexOfLastUser: properties.totalRegisterForPagination,
        }
    }

    pagination = (object, page) => {
        let limitLast = page * this.state.registerForPage;
        let limitFirst = limitLast - this.state.registerForPage;
        this.setState({ indexOfFirstUser: limitFirst, indexOfLastUser: limitLast })
    }

    buttonsOption = (option, id_organizacion) => {
        this.props.uploadImageMyOrganization(false, null) //Borrar LOGO de empresa cargada en el store
        this.props.uploadImageDirection(false, null) //Borrar ICONO de empresa cargada en el store
        switch (option) {
            case 1: //Icono VER Organizaciones
                this.props.getOrganizationById(id_organizacion)
                localStorage.setItem("readOnlyOrg", 1)
                // localStorage.setItem('forms', JSON.stringify(objeto))
                // this.props.wizardAddOrganization(true, true, objeto);
                // this.props.organizationDash(false);
                break;
            case 2: //Icono EDITAR Organizaciones
                // localStorage.setItem('forms', JSON.stringify(objeto))
                this.props.getOrganizationById(id_organizacion)
                localStorage.setItem("readOnlyOrg", 0)
                // this.props.wizardAddOrganization(true, false, objeto);
                // this.props.organizationDash(false);
                break;
            case 3: //Icono ELIMINAR Organizaciones
                // this.props.deleteOrganization(id_organizacion)
                this.props.openModalDeleteOrganization(true, id_organizacion)
                break;
            default:
                break;
        }
    }

    //Función para boton AGREGAR Organizaciones
    addOrganization = () => {
        localStorage.removeItem('imgMisOrganizaciones');
        localStorage.removeItem('imgMisOrganizaciones');
        this.props.uploadImageMyOrganization(false)
        this.props.uploadImageDirection(false)
        // localStorage.setItem('forms', JSON.stringify(objeto2))
        this.props.wizardAddOrganization(true, null, objeto2);
        this.props.organizationDash(false);


        //SE SETEA LA PRIMERA SECCIÓN DEL FORMULARIO VACÍO
        let jsonAgentData = []

        jsonAgentData.push({
            "NameSurname": "",
            "IdNumber": "",
            "acronym": "",
            "typeDonor": [],
            "originCountry": null,
            "agentType": [],
            "selectedDate": null,
            "yearExp": null,
            "UserOwner": "",
            "Email": "",
            "Web": "",
            "Facebook": "",
            "Twitter": "",
            "Linkedin": "",
            "Instagram": "",
            "MisionVision": "",
            "guidelines": "",
            "Name": "",
            "Surname": "",
            "Greeting": [],
            "Position": "",
            "Title": "",
            "Email2": "",
            "Phone": "",
            "ShowContact": null,
        })
        localStorage.setItem('jsonAgentData', JSON.stringify(jsonAgentData))
        localStorage.setItem('imgMisOrganizaciones', JSON.stringify(""))

        //SE SETEA LA SEGUNDA SECCIÓN DEL FORMULARIO VACÍO
        let jsonDirectionData = []

        jsonDirectionData.push({
            "ThematicArea": [],
            "GeographicalScope": [],
            "CountryOperation": [],
            "PubliGoal": [],
            "Length": 0,
            "Latitude": 0,
            "CentralPhone": "",
            // "OriginId": this.state.OriginId,
            "OficceNumber": "",
            // "TypeOng": this.state.TypeOng,
            "ObjetiveDev": [],
            "NetworkBelongs": [],
            // "Personalities": this.state.Personalities,
            "checkbox": false,
            "checkbox2": false,
        })
        localStorage.setItem('jsonDirectionData', JSON.stringify(jsonDirectionData))
        localStorage.setItem('imgDirection', JSON.stringify(""))

        //SE SETEA LA TERCERA SECCIÓN DEL FORMULARIO VACÍO
        let table1 = [];
        let table2 = [];
        let table3 = [];
        let table4 = [];
        let table5 = [];
        let table6 = [];
        let jsonTables = [];
        
        jsonTables.push({
            "awardsTable": table1,
            "tableBeneficiary": table2,
            "executionInvSocialTable": table3,
            "investmentCountryTable": table4,
            "investmentAreaTable": table5,
            "investmentYearTable": table6,
        })
        localStorage.setItem('jsonTables', JSON.stringify(jsonTables));
    }

    onChangeValues = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]: value
        })
        if(this.state.inputSearch !== ''){
            this.setState({errorEmptyInputSearch: false})
        }
    }

    searchOrganization = () =>{
        if(this.state.inputSearch !== '' && this.state.inputSearch !== undefined){
            this.props.getOrganizationByUser(this.state.inputSearch)
        }else{
            this.setState({errorEmptyInputSearch: true})
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                <Grid container>
                    <Grid item item xs={12}>
                        {properties.user.type === 1 ?
                            <Typography variant="h6" className={classes.title}>
                                Bienvenido, {properties.user.rol}
                            </Typography>
                            :
                            <Typography variant="h6" className={classes.title}>
                                Bienvenido
                        </Typography>
                        }

                    </Grid>
                    <Grid container >
                        <Grid item xs={12} sm={12} md={8}>
                            <Grid container className={classes.titleButton}>
                                <Grid item xs={5}>
                                    <Typography className={classes.titleTable}>
                                        <strong>Mis organizaciones</strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    {properties.user.type === 1 ?
                                        <div className={classes.inline}>
                                            <TextField
                                                id="inputSearch"
                                                onChange={(event) => this.onChangeValues(event)}
                                                // style={{ marginTop: '15px'}}
                                                variant='outlined'
                                                margin='dense'
                                                size="small"
                                                fullWidth
                                                color="primary"
                                                label="Buscar"
                                                name="inputSearch"
                                                value={this.state.inputSearch}
                                                InputProps={{
                                                    endAdornment:
                                                        <>
                                                            {this.state.errorEmptyInputSearch &&
                                                                <InputAdornment>
                                                                    <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                                                </InputAdornment>
                                                            }
                                                        </>,
                                                    form: {
                                                        autocomplete: 'off',
                                                    },
                                                  }}
                                            />
                                            <Button
                                                margin="dense"
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className={classes.buttons}
                                                onClick={this.searchOrganization}
                                            >
                                                <strong className={classes.textButton}>Buscar</strong>
                                            </Button>
                                            <Button
                                                margin="dense"
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className={classes.buttons}
                                            >
                                                <strong onClick={this.addOrganization}>+Agregar</strong>
                                            </Button>
                                        </div>
                                        :
                                        <div>
                                            <Button
                                                margin="dense"
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className={classes.buttons2}
                                            >
                                                <strong onClick={this.addOrganization}>+Agregar</strong>
                                            </Button>
                                        </div>
                                    }

                                </Grid>
                            </Grid>
                            <hr />
                            <TableContainer className={classes.marginTable}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="lfet"><strong>Nombre</strong></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody id='tableBody'>

                                    {this.props.listOrganization ?
                                    <>
                                        {this.props.totalPagePaginationOrganizations > 1 ?
                                        <>
                                            {this.props.listOrganization.slice(this.state.indexOfFirstUser, this.state.indexOfLastUser).map((element, index) => (
                                                <>
                                                    <TableRow key={element.nombre_organizacion}>
                                                        <TableCell align="left" component="th" scope="row">{element.nombre_organizacion}</TableCell>
                                                        <TableCell align="right">
                                                            {icons.map((icon) => (
                                                                <a onClick={() => this.buttonsOption(icon.pointer, element.id_organizacion)}>{icon.icono}</a>
                                                            ))}
                                                        </TableCell>
                                                    </TableRow>
                                                    
                                                </>
                                            ))}
                                        </>
                                        :
                                        <>
                                            {this.props.listOrganization.map((element, index) => (
                                                <>
                                                    <TableRow key={element.nombre_organizacion}>
                                                        <TableCell align="left" component="th" scope="row">{element.nombre_organizacion}</TableCell>
                                                        <TableCell align="right">
                                                            {icons.map((icon) => (
                                                                <a onClick={() => this.buttonsOption(icon.pointer, element.id_organizacion)}>{icon.icono}</a>
                                                            ))}
                                                        </TableCell>
                                                    </TableRow>
                                                    
                                                </>
                                            ))}
                                        </> 
                                        }
                                    </>
                                    :
                                    <>
                                    {this.props.listOrganization == undefined ?
                                        <TableRow>
                                            <TableCell>Cargando</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    :
                                        <TableRow>
                                            <TableCell>No se encontraron resultados</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    }
                                    </>
                                    }                                    
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {this.props.listOrganization && this.props.totalPagePaginationOrganizations > 1 &&
                            <>
                                <div className={classes.pagination}>
                                    {this.props.totalPagePaginationOrganizations &&
                                        <Typography style={{textAlign: 'right'}}>{(this.state.indexOfFirstUser+1) + "-" + (this.state.indexOfLastUser > this.props.listOrganization.length ? this.props.listOrganization.length : this.state.indexOfLastUser ) + " de " + this.props.listOrganization.length +" Registros"}</Typography>
                                    }

                                    <Pagination onChange={this.pagination} count={this.props.totalPagePaginationOrganizations} variant="outlined" shape="rounded" showFirstButton showLastButton />
                                </div>
                            </>
                            }
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <Paper className={classes.rootPaper} elevation={2}>
                                <List
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                    subheader={
                                        <ListSubheader id="nested-list-subheader">
                                            <strong>Mensajes</strong>
                                        </ListSubheader>
                                    }
                                >
                                    {this.props.messagesOrganizations?
                                        this.props.messagesOrganizations.map((element) => (
                                            <ListItem button dense={true}>
                                                <ListItemIcon>
                                                    <IconAlert />
                                                </ListItemIcon>
                                                <ListItemText primary={element.mensaje}/>
                                            </ListItem>
                                        ))
                                        :
                                        <ListSubheader id="nested-list-subheader">
                                            <strong>No hay mensajes</strong>
                                        </ListSubheader>
                                    }
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                    {this.props.deleteOrganizationModal &&
                        <ModalDeleteOrganization />
                    }
                </Grid>

            </>
        );
    }
}

const organizations = withStyles(styles, { withTheme: true })(Organizations);

function mapStateToProps(state) {

    return {
        listOrganization: state.getOrganizationByUserReducer.listOrganization,
        totalPagePaginationOrganizations: state.getOrganizationByUserReducer.totalPagePaginationOrganizations,
        id_organization: state.organizationDash.id_organization,
        deleteOrganizationModal: state.organizationDash.deleteOrganizationModal,
        messagesOrganizations: state.getMessages.messagesOrganizations,
    };
}

export default connect(
    mapStateToProps,
    {
        wizardAddOrganization,
        organizationDash,
        deleteOrganization,
        uploadImageMyOrganization,
        uploadImageDirection,
        openModalDeleteOrganization,
        getOrganizationById,
        getOrganizationByUser,
    }
)(organizations);