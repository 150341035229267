import { height } from "@material-ui/system";

export const styles = theme => ({
    root: {
        // minHeight: "50vh",
        // minWidth: "50vw"
    },
    gridsContainer: {
        margin: theme.spacing(1, 1, 1, 0),
    },
    forms: {
        padding: theme.spacing(1, 1, 1, 1),
    },
    Autocomplete: {
        margin: theme.spacing(1, 0,0, 0),
    },
    sectionFile: {
        marginTop: '2%',
        height: "95%",
        width: "100%",
        border: '2px dashed grey',
    },
    buttonFile: {
        marginTop: '0%', 
        borderRadius: '0%'
    },
    checkbox: {
        margin: theme.spacing(2, 0,0, ),
        // float: "right",
        // display: "inline"
    },
    checkbox2: {
        margin: theme.spacing(2, 0,0, ),
        // float: "right",
        // display: "inline"
    },
    IconImg: {
        marginTop: '8%',
        width: '20%',
        height: '20%'
    },
  });