import { OPEN_MODAL_DELETE_ORGANIZATION } from '../Constants'

export const organizationDash = (state = {} ,action) => {
    switch (action.type) {
        case OPEN_MODAL_DELETE_ORGANIZATION:
            return {...state, 
                deleteOrganizationModal: action.payload,
                id_organization: action.id_organization,
            }   
        default:
            return state;
    }
}