
import axios from 'axios'
import { ALL_USERS } from '../../Constants';
import {showErrorModal} from '../../Actions/_actionShowErrorModal'
import { getAllUsers } from '../../Actions/Services/getAllUsers'

export function deleteUser(id,) {
    
    return dispath => {
        let properties = JSON.parse(localStorage.getItem('properties'));
        let USER_DELETE_ENDPOINT = properties.ENPOINTS.USER_DELETE_ENDPOINT;
        let Authorization = localStorage.getItem('Authorization');
        let responseLogin = JSON.parse(localStorage.getItem('responseLogin'))
    
        let transaction_id = responseLogin.data.data.transaction_id
        axios({
            method: 'DELETE',
            url: USER_DELETE_ENDPOINT,
            params: {
                value: id,
                transaction_id: transaction_id
            },
            headers: {
                "Content-Type":"application/json",
                "Authorization": "Bearer " + Authorization
            }
        }).then((response) => {
            dispath(getAllUsers())
        }).catch(error => {
            let message = properties.message
            if(error.response){
                properties.errorMessage.map((element) => {
                    if(element.value === error.response.data.code){
                        message = element.message
                    }
                })
            }
            dispath(showErrorModal(true, message))
        });
    }
}
