
import axios from 'axios'
import { CHANGE_PASSWORD } from '../../Constants';
import {openModalChangePassword} from '../../Actions/_actionDashboard'
import {showErrorModal} from '../_actionShowErrorModal'
import {loader} from '../../Actions/_actionLoader'
import {circularProgress} from '../../Actions/_actionCircularProgress'

export function putChangePassword(current_password, new_password) {
    
    return dispath => {
        let properties = JSON.parse(localStorage.getItem('properties'));
        let responseLogin = JSON.parse(localStorage.getItem('responseLogin'));
        let Authorization = localStorage.getItem('Authorization');
        let CHANGEPASSWORD_ENPOINT = properties.ENPOINTS.CHANGEPASSWORD_ENPOINT;
        axios({
            method: 'PUT',
            url: CHANGEPASSWORD_ENPOINT,
            headers: {
                'Authorization': 'Bearer ' + Authorization
            },
            data: {
                "user":responseLogin.data.data.email,
                "aplication":"UER",
                "current_password":current_password,
                "new_password":new_password,
                "app_user":responseLogin.data.data.user_id,
                "transaction_id":responseLogin.data.data.transaction_id
            }
        }).then((response) => {
            dispath(loader(false))
            dispath(circularProgress(false))
            dispath(openModalChangePassword(false))
            if(response.data.code === "200"){
                dispath(showErrorModal(true, '0', null, null, true))
            }else{
                dispath(showErrorModal(true, '1', null, null, false))
            }
        }).catch(error => {   
            dispath(loader(false))
            dispath(circularProgress(false))
            dispath(openModalChangePassword(false))
            let message = properties.message
            if(error.response){
                properties.errorMessage.map((element) => {
                    if(element.value === error.response.data.code){
                        message = element.value
                    }                    
                })
            }
            dispath(showErrorModal(true, message, null, null, false))
        });
    }
}
