import axios from 'axios'
import { MESSAGES_USER } from '../../Constants';
import {showErrorModal} from '../../Actions/_actionShowErrorModal'

export function getNetworkBelongs() {

    return dispath => {
        let properties = JSON.parse(localStorage.getItem('properties'));
        let responseLogin = JSON.parse(localStorage.getItem('responseLogin'))
        let Authorization = localStorage.getItem('Authorization');
        let NETWORK_BELONGS_ENDPOINT = properties.ENPOINTS.NETWORK_BELONGS_ENDPOINT;
        let loginInfo = responseLogin.data.data
        axios({
            method: 'GET',
            url: NETWORK_BELONGS_ENDPOINT,
            headers: {
                "Authorization": "Bearer " + Authorization
            }
        }).then((response) => {
            localStorage.setItem('NetworkBelongs', JSON.stringify(response.data.data));

        }).catch(error => {
            // let message = undefined
            // if(error.response.data.code){
            //     properties.errorMessage.map((element) => {
            //         if(element.value === error.response.data.code){
            //             message = error.response.data.code
            //         }
            //     })
            // }
            // dispath(showErrorModal(true, message))
        });
    }
}