// DEPENDENCIAS
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from "@material-ui/core/styles";
import { styles } from './constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconImg from './img/img.png';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import FormData from 'form-data'
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import esLocale from "date-fns/locale/es";

//ACCIONES
import { postUploadImage } from '../../../../../Actions/Services/postUploadImage'
import { spinnerImageMyOrganization } from '../../../../../Actions/_actionWizardProps'
import { loader } from '../../../../../Actions/_actionLoader';
import { uploadImageMyOrganization } from '../../../../../Actions/_actionUploadImage';

// CONSTANTES
const style = styles;

// COMPONENTE TIPO CLASE
class AgentData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            objeto: this.props.objeto,
            NameSurname: '',
            IdNumber: '',
            acronym: '',
            year: '',
            valueSelect0: '',
            typeDonor: '',
            originCountry: '',
            agentType: '',
            // selectedDate: null,
            yearExp: '',
            UserOwner: '',
            Email: '',
            Facebook: '',
            Twitter: '',
            Linkedin: '',
            Instagram: '',
            Web: '',
            MisionVision: '',
            guidelines: '',
            Name: '',
            Surname: '',
            Greeting: '',
            Position: '',
            Title: '',
            Email2: '',
            Phone: '',
            ShowContact: null,
            jsonAgentData: [],
            rol: JSON.parse(localStorage.getItem('Rol')),
        }
        this.validateToNextStep = this.validateToNextStep.bind(this); //BIND DE FUNCIÓN QUE SE EJECUTA DESDE COMPONENTE PADRE (WizardAddOrganization)
    }

    /////////////////////////// VALIDACIÓN DE LOS CAMPOS PARA PODER CONTINUAR A LA SEGUNDA SECCIÓN //////////////////////////////////
    /**
     * Función para validar campos requeridos si en caso positivo, 
     * continuar a la segunda sección dejando de ejecutar "this.props.validateFields()"
     * "this.props.validateFields()" es una función del componente padre "WizardAddOrganization"
     */
    validateToNextStep() { //FUNCIÓN QUE SE EJECUTA DESDE COMPONENTE PADRE (WizardAddOrganization)

        if(this.state.rol === 1){ //SI EL ROL ES ADMINISTRADOR SE DEBE VALIDAR ESE CAMPO
            if(this.state.UserOwner === '' || this.state.UserOwner === undefined){
                this.props.validateFields()
                this.setState({errorUserOwner: true})
            } else {this.setState({errorUserOwner: false})}
        }

        if(this.state.NameSurname === '' || this.state.NameSurname === undefined){
            this.props.validateFields()
            this.setState({errorEmptyNameSurname: true})
        }
        if(this.state.typeDonor == '' || this.state.typeDonor === undefined){
            this.props.validateFields()
            this.setState({errorTypeDonor: true})
        } else {this.setState({errorTypeDonor: false})}

        if(this.state.agentType == '' || this.state.agentType === undefined){
            this.props.validateFields()
            this.setState({errorAgentType: true})
        } else {this.setState({errorAgentType: false})}

        // if(this.state.acronym !== '' && this.state.acronym !== null && this.state.acronym !== undefined){
        //     if(this.state.acronym.length < 3){
        //         this.props.validateFields()
        //     }
        // }
        
        if(this.state.Email !== '' && this.state.Email !== null && this.state.Email !== undefined){
            let pattern = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,9}$/i;
            if(!pattern.test(this.state.Email)){
                this.props.validateFields()
            }
        }
        
        if(this.state.Email2 !== '' && this.state.Email2 !== null && this.state.Email2 !== undefined){
            let pattern = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,9}$/i;
            if(!pattern.test(this.state.Email2)){
                this.props.validateFields()
            }
        }
        
        if(this.state.Title !== '' && this.state.Title !== null && this.state.Title !== undefined){
            if(this.state.Title.length > 4){
                this.props.validateFields()
            }
        }

        if(this.state.Title !== '' && this.state.Title !== null && this.state.Title !== undefined){
            if(this.state.Title.length > 4){
                this.props.validateFields()
            }
        }
        
        if(this.state.Web !== '' && this.state.Web !== null && this.state.Web !== undefined){
            let pattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
            if(!pattern.test(this.state.Web)){
                this.props.validateFields()
            }
        }
        if(this.state.Facebook !== '' && this.state.Facebook !== null && this.state.Facebook !== undefined){
            let pattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
            if(!pattern.test(this.state.Facebook)){
                this.props.validateFields()
            }
        }
        if(this.state.Twitter !== '' && this.state.Twitter !== null && this.state.Twitter !== undefined){
            let pattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
            if(!pattern.test(this.state.Twitter)){
                this.props.validateFields()
            }
        }
        if(this.state.Linkedin !== '' && this.state.Linkedin !== null && this.state.Linkedin !== undefined){
            let pattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
            if(!pattern.test(this.state.Linkedin)){
                this.props.validateFields()
            }
        }
        if(this.state.Instagram !== '' && this.state.Instagram !== null && this.state.Instagram !== undefined){
            let pattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
            if(!pattern.test(this.state.Instagram)){
                this.props.validateFields()
            }
        }
    }

    /**
     * Función para validar campos de texto y campos email y en función de eso pintar check en caso positivo y "x" en caso negativo
     * Esta función es llamada desde el "onChange" que setea los estados en los campos
     * @param value valor del campo
     * @param name nombre del estado
     */
    validateFieldsRequired = (value, name) =>{ //FUNCIÓN QUE SE EJECUTA A RAÍZ DEL "ONCHANGE" PARA VALIDAR LOS FORMATOS DE LOS CAMPOS DE TEXTO Y EMAIL Y PINTAR EL CHECK O LA "X"
        let pattern = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,9}$/i; //Patrón de validación de emails
        let patterURL = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
        switch (name) {
            case 'NameSurname':
              if(value !== ''){
                  this.setState({errorEmptyNameSurname: true, errorNameSurname: true});
              }else {
                  this.setState({errorNameSurname: false}); 
                }
            break;
            case 'acronym':
                // if(value.length > 2){
                //     this.setState({errorEmptyAcronym: true, errorAcronym: true});
                // }else{
                //     this.setState({errorEmptyAcronym: true, errorAcronym: false});
                //     if(value === ''){this.setState({errorEmptyAcronym: false});}
                // }
            break;
            case 'Email':
              if(!pattern.test(value)){
                    this.setState({errorEmptyEmail: true, errorEmail: false});
                    if(value === ''){this.setState({errorEmptyEmail: false});}
                }else {
                    this.setState({errorEmptyEmail: true, errorEmail: true}); 
              }
            break;
            case 'Email2':
              if(!pattern.test(value)){
                    this.setState({errorEmptyEmail2: true, errorEmail2: false});
                    if(value === ''){this.setState({errorEmptyEmail2: false});}
                }else {
                    this.setState({errorEmptyEmail2: true, errorEmail2: true}); 
              }
            break;
            case 'Title':
                if(value.length > 4){
                    this.setState({errorEmptyTitle: true, errorTitle: false});
                    if(value === ''){this.setState({errorEmptyTitle: false});}
                }else{
                    this.setState({errorEmptyTitle: true, errorTitle: true});
                    if(value === ''){this.setState({errorEmptyTitle: false});}
                }
            break;
            case 'Web':
              if(!patterURL.test(value)){
                    this.setState({errorEmptyWeb: true, errorWeb: false});
                    if(value === ''){this.setState({errorEmptyWeb: false});}
                }else {
                    this.setState({errorEmptyWeb: true, errorWeb: true}); 
              }
            break;
            case 'Facebook':
              if(!patterURL.test(value)){
                    this.setState({errorEmptyFacebook: true, errorFacebook: false});
                    if(value === ''){this.setState({errorEmptyFacebook: false});}
                }else {
                    this.setState({errorEmptyFacebook: true, errorFacebook: true}); 
              }
            break;
            case 'Twitter':
              if(!patterURL.test(value)){
                    this.setState({errorEmptyTwitter: true, errorTwitter: false});
                    if(value === ''){this.setState({errorEmptyTwitter: false});}
                }else {
                    this.setState({errorEmptyTwitter: true, errorTwitter: true}); 
              }
            break;
            case 'Linkedin':
              if(!patterURL.test(value)){
                    this.setState({errorEmptyLinkedin: true, errorLinkedin: false});
                    if(value === ''){this.setState({errorEmptyLinkedin: false});}
                }else {
                    this.setState({errorEmptyLinkedin: true, errorLinkedin: true}); 
              }
            break;
            case 'Instagram':
              if(!patterURL.test(value)){
                    this.setState({errorEmptyInstagram: true, errorInstagram: false});
                    if(value === ''){this.setState({errorEmptyInstagram: false});}
                }else {
                    this.setState({errorEmptyInstagram: true, errorInstagram: true}); 
              }
            break;

            default:
              break;
          }
    }
    /////////////////////////// VALIDACIÓN DE LOS CAMPOS PARA PODER CONTINUAR A LA SEGUNDA SECCIÓN //////////////////////////////////


    componentDidMount = () => {
        this.props.setClick(this.validateToNextStep); //FUNCIÓN PROPS "setClick" (ACCESORIO) QUE ES INICIALIZADA PARA EJECUTAR "validateFields"

        let ListLov = JSON.parse(localStorage.getItem('ListLov'));
        let jsonAgentData = JSON.parse(localStorage.getItem('jsonAgentData'));
        // let jsonAgentData = JSON.parse(localStorage.getItem('jsonAgentData'));

        let tipoDonante = [];
        let tipoAgente = [];
        let tituloPersona = [];
        let currencyList = [];

        if (ListLov) {
            ListLov.map((element) => {
                if (element.nombre_dominio === 'TIPO_DONANTE') {
                    element.valores_dominio.map((val) => (
                        tipoDonante.push({
                            "codigo_valor": val.codigo_valor,
                            "presentacion_valor": val.presentacion_valor
                        })
                    ))
                }
                if (element.nombre_dominio === 'TIPO_ORGANIZACION') {
                    element.valores_dominio.map((val) => (
                        tipoAgente.push({
                            "codigo_valor": val.codigo_valor,
                            "presentacion_valor": val.presentacion_valor
                        })
                    ))
                }
                if (element.nombre_dominio === 'TITULO_PERSONA') {
                    element.valores_dominio.map((val) => (
                        tituloPersona.push({
                            "codigo_valor": val.codigo_valor,
                            "presentacion_valor": val.presentacion_valor
                        })
                    ))
                }
            })
        }

        this.setState({
            tipoDonante: tipoDonante,
            tipoAgente: tipoAgente,
            tituloPersona: tituloPersona,
            countries: JSON.parse(localStorage.getItem('countries')),
            ListUserByRol: JSON.parse(localStorage.getItem('ListUserByRol')),
        })

        if (jsonAgentData !== null) {
            this.setState({
                NameSurname: jsonAgentData[0].NameSurname,
                IdNumber: jsonAgentData[0].IdNumber,
                acronym: jsonAgentData[0].acronym,
                typeDonor: jsonAgentData[0].typeDonor,
                originCountry: jsonAgentData[0].originCountry,
                agentType: jsonAgentData[0].agentType,
                selectedDate: jsonAgentData[0].selectedDate, 
                yearExp: jsonAgentData[0].yearExp,
                UserOwner: jsonAgentData[0].UserOwner,
                Email: jsonAgentData[0].Email,
                Web: jsonAgentData[0].Web,
                Facebook: jsonAgentData[0].Facebook,
                Twitter: jsonAgentData[0].Twitter,
                Linkedin: jsonAgentData[0].Linkedin,
                Instagram: jsonAgentData[0].Instagram, 
                MisionVision: jsonAgentData[0].MisionVision,
                guidelines: jsonAgentData[0].guidelines,
                Name: jsonAgentData[0].Name,
                Surname: jsonAgentData[0].Surname,
                Greeting: jsonAgentData[0].Greeting, 
                Position: jsonAgentData[0].Position,
                Title: jsonAgentData[0].Title,
                Email2: jsonAgentData[0].Email2,
                Phone: jsonAgentData[0].Phone,
                ShowContact: jsonAgentData[0].ShowContact,
            })
        }
    }

    componentWillUnmount = () => {
        this.state.jsonAgentData.push({
            "NameSurname": this.state.NameSurname,
            "IdNumber": this.state.IdNumber,
            "acronym": this.state.acronym,
            "typeDonor": this.state.typeDonor,
            "originCountry": this.state.originCountry,
            "agentType": this.state.agentType,
            "selectedDate": this.state.selectedDate,
            "yearExp": this.state.yearExp,
            "UserOwner": this.state.UserOwner,
            "Email": this.state.Email,
            "Web": this.state.Web,
            "Facebook": this.state.Facebook,
            "Twitter": this.state.Twitter,
            "Linkedin": this.state.Linkedin,
            "Instagram": this.state.Instagram,
            "MisionVision": this.state.MisionVision,
            "guidelines": this.state.guidelines,
            "Name": this.state.Name,
            "Surname": this.state.Surname,
            "Greeting": this.state.Greeting,
            "Position": this.state.Position,
            "Title": this.state.Title,
            "Email2": this.state.Email2,
            "Phone": this.state.Phone,
            "ShowContact": this.state.ShowContact,
        })
        localStorage.setItem('jsonAgentData', JSON.stringify(this.state.jsonAgentData))
    }

    handleChangeField = (event) => {
        var value = event.target.value;
        var name = event.target.name;
        var checked = event.target.checked
        
        if(name === 'Phone'){
            if(/^([+0-9]*)$/.test(value)){
                this.setState({[name]: value})
            }
        }else{
            this.setState({
                [name]: value,
            },
                () => {
                    if (name === "ShowContact") {
                        this.setState({
                            [name]: checked,
                        })
                    }
                    if (name === 'agentType'){
                        this.setState({errorAgentType: false})
                    }
                    if (name === 'typeDonor'){
                        this.setState({errorTypeDonor: false})
                    }
                    if (name === 'UserOwner'){
                        this.setState({errorUserOwner: false})
                    }
                    this.validateFieldsRequired(value, name)
                }
            )
        }
    }

    upLoadImage = (event) => {
        this.props.spinnerImageMyOrganization(true)
        let file = event.target.files[0];
        this.setState({ file: file }, () => {
            let file = this.state.file
            let formData = new FormData()
            formData.append('image', file);
            this.props.postUploadImage(formData, 'imgMisOrganizaciones')
        })
    }
    
    changeImage = (event) => {
        this.props.uploadImageMyOrganization(false, null)
        // localStorage.removeItem('imgMisOrganizaciones');
    }

    render() {
        const { classes } = this.props;
        const handleDateChange = date => {
            this.setState({
                selectedDate: date,
            })
        };

        return (
            <>
                <Grid container className={classes.root}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Mis organizaciones
                        </Typography>
                        <hr />
                    </Grid>
                    <Grid container className={classes.gridsContainer}>
                        <Grid item xs={4} className={classes.forms} >
                            {this.props.showUploadImage ?
                                    <>
                                        <img style={{ width: '100%', height: '80%' }} src={this.props.imageURL} alt="Logo" />
                                        <Button style={{borderRadius: '0px'}} disabled={this.props.disable} onClick={this.changeImage} size="small" color="primary" variant="contained">Eliminar imagen</Button>
                                    </>

                                    // <div className={classes.containerImage} style={{ width: '90%', height: '90%' }}>
                                    //     <img style={{ width: '100%', height: '100%' }} className={classes.imgLogo} src={this.props.imageURL} alt="Logo" />
                                    //     <Button size="small" onClick={this.changeImage} className={classes.fixedButton}><CloseIcon/></Button>
                                    // </div>
                                :
                                <div className={classes.sectionFile}>
                                    {this.props.spinnerLoadOrganization ?
                                        <center>
                                            <CircularProgress style={{ marginTop: '10%' }}></CircularProgress>
                                        </center>
                                        :
                                        <center>
                                            <img src={IconImg} style={{ marginTop: '10%' }} alt="Logo" />
                                        </center>
                                    }
                                    <center>
                                        <Button variant="contained" disabled={this.props.disable} inputProps={{ readOnly: this.props.disable }} className={classes.buttonFile} component="label" color="primary">
                                            Cargar Logo
                                            <input
                                                onChange={this.upLoadImage}
                                                type="file"
                                                style={{ display: "none" }}
                                            />
                                        </Button>
                                    </center>
                                </div>
                            }
                        </Grid>
                        <Grid item xs={4} className={classes.forms}>
                            <TextField
                                required
                                fullWidth
                                color="primary"
                                label="Nombre"
                                name="NameSurname"
                                InputProps={{
                                    endAdornment:
                                      <>
                                        {this.state.errorEmptyNameSurname &&
                                          <>
                                            {this.state.errorNameSurname?
                                              <InputAdornment>
                                                <CheckIcon style={{ marginRight: '6px', color: 'green' }} />
                                              </InputAdornment>
                                              :
                                              <InputAdornment>
                                                <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                              </InputAdornment>
                                            }
                                          </>
                                        }

                                      </>,
                                     form: {
                                      autocomplete: 'off',
                                    },
                                    readOnly: this.props.disable
                                  }}
                                value={this.state.NameSurname}
                                onChange={this.handleChangeField}
                            />
                            <TextField
                                fullWidth
                                label="Número de Identificación"
                                color="primary"
                                type="number"
                                name="IdNumber"
                                inputProps={{ readOnly: this.props.disable }}
                                value={this.state.IdNumber}
                                onChange={this.handleChangeField}
                            />
                            <TextField
                                fullWidth
                                color="primary"
                                label="Siglas"
                                name="acronym"
                                value={this.state.acronym}
                                onChange={this.handleChangeField}
                                InputProps={{
                                    endAdornment:
                                      <>
                                        {this.state.errorEmptyAcronym &&
                                          <>
                                            {this.state.errorAcronym?
                                              <InputAdornment>
                                                <CheckIcon style={{ marginRight: '6px', color: 'green' }} />
                                              </InputAdornment>
                                              :
                                              <InputAdornment>
                                                <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                              </InputAdornment>
                                            }
                                          </>
                                        }

                                      </>,
                                     form: {
                                      autocomplete: 'off',
                                    },
                                    readOnly: this.props.disable
                                }}
                            />
                            <FormControl fullWidth required>
                                <InputLabel 
                               
                                >Tipo de Agente</InputLabel>
                                <Select
                                    color="primary"
                                    name="agentType"
                                    inputProps={{ readOnly: this.props.disable }}
                                    value={this.state.agentType}
                                    onChange={this.handleChangeField}
                                    IconComponent={props => (
                                        <i {...props}  className={`material-icons ${props.className}`}>
                                            {this.state.errorAgentType ?
                                                <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                            :
                                                ''
                                                // <CheckIcon style={{ marginRight: '6px', color: 'green' }} />
                                            }
                                            <ArrowDropDown style={{ marginRight: '0px' }} />
                                        </i>
                                      )}
                                >
                                    {this.state.tipoAgente &&
                                        this.state.tipoAgente.map((element) => (
                                            <MenuItem key={element.codigo_valor} value={element.codigo_valor}>{element.presentacion_valor}</MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} className={classes.forms}>
                            <FormControl fullWidth required>
                                <InputLabel >Tipo de Donante</InputLabel>
                                <Select
                                    color="primary"
                                    name='typeDonor'
                                    inputProps={{ readOnly: this.props.disable }}
                                    value={this.state.typeDonor}
                                    onChange={this.handleChangeField}
                                    IconComponent={props => (
                                        <i {...props}  className={`material-icons ${props.className}`}>
                                            {this.state.errorTypeDonor ?
                                                <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                            :
                                                ''
                                                // <CheckIcon style={{ marginRight: '6px', color: 'green' }} />
                                            }
                                            <ArrowDropDown style={{ marginRight: '0px' }} />
                                        </i>
                                      )}
                                >
                                    {this.state.tipoDonante &&
                                        this.state.tipoDonante.map((element) => (
                                            <MenuItem key={element.codigo_valor} value={element.codigo_valor}>{element.presentacion_valor}</MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth >
                                <InputLabel >País de Origen</InputLabel>
                                <Select
                                    color="primary"
                                    name='originCountry'
                                    inputProps={{ readOnly: this.props.disable }}
                                    value={this.state.originCountry}
                                    onChange={this.handleChangeField}
                                >
                                    {this.state.countries &&
                                        this.state.countries.map((element) => (
                                            <MenuItem key={element.id_pais} value={element.id_pais}>{element.nombre_pais}</MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <KeyboardDatePicker
                                    fullWidth
                                    color="primary"
                                    views={["year"]}
                                    label="Año de Creación"
                                    format="yyyy"
                                    name="selectedDate"
                                    invalidDateMessage="Formato inválido"
                                    maxDateMessage="Superó el límite máximo"
                                    minDateMessage="Superó el límite minimo"
                                    readOnly={this.props.disable}
                                    inputProps={{ readOnly: this.props.disable }} 
                                    value={this.state.selectedDate}
                                    InputAdornmentProps={{ position: "end" }}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                        'color': 'primary'
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            <TextField
                                fullWidth
                                color="primary"
                                type="number"
                                label="Años de experiencia"
                                name="yearExp"
                                inputProps={{ readOnly: this.props.disable }}
                                value={this.state.yearExp}
                                onChange={this.handleChangeField}
                            />
                        </Grid>
                        <Grid item xs={4} className={classes.forms}>
                            {this.state.rol === 1 &&
                                <>
                                    <InputLabel required>Usuario Propietario</InputLabel>
                                    <Select
                                        color="primary"
                                        name="UserOwner"
                                        fullWidth
                                        inputProps={{ readOnly: this.props.disable }}
                                        value={this.state.UserOwner}
                                        onChange={this.handleChangeField}
                                        IconComponent={props => (
                                            <i {...props}  className={`material-icons ${props.className}`}>
                                                {this.state.errorUserOwner ?
                                                    <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                                :
                                                    ''
                                                    // <CheckIcon style={{ marginRight: '6px', color: 'green' }} />
                                                }
                                                <ArrowDropDown style={{ marginRight: '0px' }} />
                                            </i>
                                          )}
                                    >
                                        {this.state.ListUserByRol &&
                                            this.state.ListUserByRol.map((element) => (
                                                <MenuItem key={element.id} value={element.id}>{element.email}</MenuItem>
                                            ))}
                                    </Select>
                                </>
                            }
                            <TextField
                                fullWidth
                                color="primary"
                                type="email"
                                label="Email"
                                name="Email"
                                value={this.state.Email}
                                onChange={this.handleChangeField}
                                InputProps={{
                                    endAdornment:
                                      <>
                                        {this.state.errorEmptyEmail &&
                                          <>
                                            {this.state.errorEmail?
                                              <InputAdornment>
                                                <CheckIcon style={{ marginRight: '6px', color: 'green' }} />
                                              </InputAdornment>
                                              :
                                              <InputAdornment>
                                                <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                              </InputAdornment>
                                            }
                                          </>
                                        }
                                      </>,
                                     form: {
                                      autocomplete: 'off',
                                    },
                                    readOnly: this.props.disable
                                  }}
                            />
                            <TextField
                                fullWidth
                                color="primary"
                                label="Web"
                                placeholder="Ej: http://web.com"
                                name="Web"
                                value={this.state.Web}
                                onChange={this.handleChangeField}
                                InputProps={{
                                    endAdornment:
                                      <>
                                        {this.state.errorEmptyWeb &&
                                          <>
                                            {this.state.errorWeb?
                                              <InputAdornment>
                                                <CheckIcon style={{ marginRight: '6px', color: 'green' }} />
                                              </InputAdornment>
                                              :
                                              <InputAdornment>
                                                <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                              </InputAdornment>
                                            }
                                          </>
                                        }

                                      </>,
                                     form: {
                                      autocomplete: 'off',
                                    },
                                    readOnly: this.props.disable
                                }}
                            />
                            <TextField
                                fullWidth
                                color="primary"
                                label="Facebook"
                                placeholder="Ej: http://facebook.com"
                                name="Facebook"
                                value={this.state.Facebook}
                                onChange={this.handleChangeField}
                                InputProps={{
                                    endAdornment:
                                      <>
                                        {this.state.errorEmptyFacebook &&
                                          <>
                                            {this.state.errorFacebook?
                                              <InputAdornment>
                                                <CheckIcon style={{ marginRight: '6px', color: 'green' }} />
                                              </InputAdornment>
                                              :
                                              <InputAdornment>
                                                <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                              </InputAdornment>
                                            }
                                          </>
                                        }

                                      </>,
                                     form: {
                                      autocomplete: 'off',
                                    },
                                    readOnly: this.props.disable
                                }}
                            />
                            <TextField
                                fullWidth
                                color="primary"
                                label="Twitter"
                                placeholder="Ej: http://twitter.com"
                                name="Twitter"
                                value={this.state.Twitter}
                                onChange={this.handleChangeField}
                                InputProps={{
                                    endAdornment:
                                      <>
                                        {this.state.errorEmptyTwitter &&
                                          <>
                                            {this.state.errorTwitter?
                                              <InputAdornment>
                                                <CheckIcon style={{ marginRight: '6px', color: 'green' }} />
                                              </InputAdornment>
                                              :
                                              <InputAdornment>
                                                <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                              </InputAdornment>
                                            }
                                          </>
                                        }

                                      </>,
                                     form: {
                                      autocomplete: 'off',
                                    },
                                    readOnly: this.props.disable
                                }}
                            />
                            <TextField
                                
                                fullWidth
                                color="primary"
                                label="Linkedin"
                                placeholder="Ej: http://linkedin.com"
                                name="Linkedin"
                                value={this.state.Linkedin}
                                onChange={this.handleChangeField}
                                InputProps={{
                                    endAdornment:
                                      <>
                                        {this.state.errorEmptyLinkedin &&
                                          <>
                                            {this.state.errorLinkedin?
                                              <InputAdornment>
                                                <CheckIcon style={{ marginRight: '6px', color: 'green' }} />
                                              </InputAdornment>
                                              :
                                              <InputAdornment>
                                                <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                              </InputAdornment>
                                            }
                                          </>
                                        }

                                      </>,
                                     form: {
                                      autocomplete: 'off',
                                    },
                                    readOnly: this.props.disable
                                }}
                            />
                            <TextField
                                
                                fullWidth
                                color="primary"
                                label="Instagram"
                                placeholder="Ej: http://instagram.com"
                                name="Instagram"
                                value={this.state.Instagram}
                                onChange={this.handleChangeField}
                                InputProps={{
                                    endAdornment:
                                      <>
                                        {this.state.errorEmptyInstagram &&
                                          <>
                                            {this.state.errorInstagram?
                                              <InputAdornment>
                                                <CheckIcon style={{ marginRight: '6px', color: 'green' }} />
                                              </InputAdornment>
                                              :
                                              <InputAdornment>
                                                <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                              </InputAdornment>
                                            }
                                          </>
                                        }

                                      </>,
                                     form: {
                                      autocomplete: 'off',
                                    },
                                    readOnly: this.props.disable
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} className={classes.forms}>
                            <TextField
                                multiline
                                color="primary"
                                label="Misión y visión"
                                rows="10"
                                variant="outlined"
                                name="MisionVision"
                                className={classes.textarea}
                                inputProps={{ readOnly: this.props.disable }}
                                value={this.state.MisionVision}
                                onChange={this.handleChangeField}
                            />
                        </Grid>
                        <Grid item xs={4} className={classes.forms}>
                            <TextField
                                multiline
                                color="primary"
                                label="Lineamientos Estratégicos"
                                rows="10"
                                variant="outlined"
                                name="guidelines"
                                inputProps={{ readOnly: this.props.disable }}
                                className={classes.textarea}
                                value={this.state.guidelines}
                                onChange={this.handleChangeField}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.gridsContainer}>
                        <Typography variant="h6">
                            Datos de la Persona Contacto
                        </Typography>
                        <hr />
                        <Grid container>
                            <Grid item xs={4} className={classes.forms}>
                                <TextField
                                    
                                    fullWidth
                                    color="primary"
                                    label="Nombre"
                                    name="Name"
                                    inputProps={{ readOnly: this.props.disable }}
                                    value={this.state.Name}
                                    onChange={this.handleChangeField}
                                />
                            </Grid>
                            <Grid item xs={4} className={classes.forms}>
                                <TextField
                                    
                                    fullWidth
                                    color="primary"
                                    label="Apellido"

                                    name="Surname"
                                    inputProps={{ readOnly: this.props.disable }}
                                    value={this.state.Surname}
                                    onChange={this.handleChangeField}
                                />
                            </Grid>
                            <Grid item xs={4} className={classes.forms}>
                                {/* <TextField
                                    
                                    fullWidth
                                    color="primary"
                                    label="Saludo"
                                    name="Greeting"
                                    inputProps={{ readOnly: this.props.disable }} 
                                    value={this.state.Greeting}
                                    onChange={this.handleChangeField}

                                /> */}
                                <FormControl fullWidth>
                                    <InputLabel >Saludo</InputLabel>
                                    <Select
                                        color="primary"
                                        name="Greeting"
                                        inputProps={{ readOnly: this.props.disable }}
                                        value={this.state.Greeting}
                                        onChange={this.handleChangeField}
                                    >
                                        {this.state.tituloPersona &&
                                            this.state.tituloPersona.map((element) => (
                                                <MenuItem key={element.codigo_valor} value={element.codigo_valor}>{element.codigo_valor}</MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} className={classes.forms}>
                                <TextField
                                    
                                    fullWidth
                                    color="primary"
                                    label="Cargo"
                                    name="Position"
                                    inputProps={{ readOnly: this.props.disable }}
                                    value={this.state.Position}
                                    onChange={this.handleChangeField}
                                />
                            </Grid>
                            <Grid item xs={4} className={classes.forms}>
                                <TextField
                                    
                                    fullWidth
                                    color="primary"
                                    label="Título"
                                    name="Title"
                                    value={this.state.Title}
                                    onChange={this.handleChangeField}
                                    InputProps={{
                                        endAdornment:
                                          <>
                                            {this.state.errorEmptyTitle &&
                                              <>
                                                {this.state.errorTitle?
                                                  <InputAdornment>
                                                    <CheckIcon style={{ marginRight: '6px', color: 'green' }} />
                                                  </InputAdornment>
                                                  :
                                                  <InputAdornment>
                                                    <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                                  </InputAdornment>
                                                }
                                              </>
                                            }
    
                                          </>,
                                         form: {
                                          autocomplete: 'off',
                                        },
                                        readOnly: this.props.disable
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4} className={classes.forms}>
                                <TextField
                                    
                                    fullWidth
                                    color="primary"
                                    type="email"
                                    label="Correo Electrónico"
                                    name="Email2"
                                    value={this.state.Email2}
                                    onChange={this.handleChangeField}
                                    InputProps={{
                                        endAdornment:
                                          <>
                                            {this.state.errorEmptyEmail2 &&
                                              <>
                                                {this.state.errorEmail2?
                                                  <InputAdornment>
                                                    <CheckIcon style={{ marginRight: '6px', color: 'green' }} />
                                                  </InputAdornment>
                                                  :
                                                  <InputAdornment>
                                                    <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                                  </InputAdornment>
                                                }
                                              </>
                                            }
                                          </>,
                                        form: {
                                          autocomplete: 'off',
                                        },
                                        readOnly: this.props.disable
                                    }}    
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.forms}>
                                <TextField
                                    
                                    fullWidth
                                    placeholder="Ej: +581234567890 o 04121234567"
                                    color="primary"
                                    label="Teléfono"
                                    type="text"
                                    name="Phone"
                                    inputProps={{ readOnly: this.props.disable }}
                                    value={this.state.Phone}
                                    onChange={this.handleChangeField}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.forms}>
                                {/* <TextField
                                    
                                    fullWidth
                                    color="primary"
                                    label="Mostrar Contacto"
                                    name="ShowContact"
                                    inputProps={{ readOnly: this.props.disable }} 
                                    value={this.state.ShowContact}
                                    onChange={this.handleChangeField}
                                /> */}
                                <div style={{ marginTop: '5%', marginLeft: '10%' }}>
                                    <a style={{ color: 'grey' }}>¿Es un Aliado de UER?</a>
                                    <Checkbox

                                        color="primary"
                                        name="ShowContact"
                                        disabled={this.props.disable}
                                        checked={this.state.ShowContact}
                                        onChange={this.handleChangeField}
                                    >
                                    </Checkbox>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const agentData = withStyles(styles, { withTheme: true })(AgentData);

function mapStateToProps(state) {

    return {
        loaderPayload: state.loader.loaderPayload,
        showUploadImage: state.showUploadImageMyOrganization.showUploadImage,
        imageURL: state.showUploadImageMyOrganization.imageURL,
        spinnerLoadOrganization: state.wizardProps.spinnerLoadOrganization,
        disable: state.dashboard.disableField
    };
}

export default connect(
    mapStateToProps,
    {
        postUploadImage,
        spinnerImageMyOrganization,
        loader,
        uploadImageMyOrganization,
    }
)(agentData);