
import axios from 'axios'
import { BLOCK_USER } from '../../Constants';
import { getAllUsers } from '../Services/getAllUsers'

export function postBlockUser(email, block) {
    
    return dispath => {
        let properties = JSON.parse(localStorage.getItem('properties'));
        let responseLogin = JSON.parse(localStorage.getItem('responseLogin'));
        let Authorization = localStorage.getItem('Authorization');
        let BLOCKUSER_ENPOINT = properties.ENPOINTS.BLOCKUSER_ENPOINT;
        axios({
            method: 'POST',
            url: BLOCKUSER_ENPOINT,
            headers: {
                'Content-Type':'application/json',
                "Authorization": "Bearer " + Authorization
            },
            data: {
                "user":email,
                "aplication":"UER",
                "app_user":"1",
                "transaction_id":"UER12121",
                "block":block
            }
        }).then((response) => {
            if(response.status === 200 ){
                dispath({
                    type: BLOCK_USER,
                    payload: true,
                })
                dispath(getAllUsers())
            }
        })
            .catch(error => {
                let errorObject = JSON.parse(JSON.stringify(error));
                console.log(errorObject)
                dispath({
                    type: BLOCK_USER,
                    payload: false,
                })
            });
    }
}
