// http://10.192.98.93:8080/dashboard

import axios from 'axios'
import { DASHBOARD } from '../../Constants';
import {showErrorModal} from '../_actionShowErrorModal'

export function getDashboard() {
 
    return dispath => {
        let properties = JSON.parse(localStorage.getItem('properties'));
        let DASHBOARD_ENDPOINT = properties.ENPOINTS.DASHBOARD_ENDPOINT;
        let Authorization = localStorage.getItem('Authorization');
        axios({
            method: 'GET',
            url: DASHBOARD_ENDPOINT,
            headers: {
                "Content-Type":"application/json",
                "Authorization": "Bearer " + Authorization
            }
        }).then((response) => {
         
          
            localStorage.setItem('dashboard', JSON.stringify(response.data.data));
          
            dispath({
                type: DASHBOARD,
                payload: true
            })
        })
            .catch(error => {
                // dispath(circularProgress(false))
                // dispath(openModalChangePassword(false))
                // alert(JSON.stringify(error))
                let message = properties.message
                if(error.response){
                properties.errorMessage.map((element) => {
                    if(element.value === error.response.data.code){
                        message = element.message
                    }
                })
                }
                dispath(showErrorModal(true, message))
            });
    }
}
