import { 
    OPEN_MODAL_NEW_USER,
    OPEN_MODAL_VIEW_USER,
    OPEN_MODAL_CHANGE_PASSWORD_USER,
    OPEN_MODAL_REMOVE_USER,
 } from '../Constants'


export const userManagement = (state = {} ,action) => {

    switch (action.type) {
        
        case OPEN_MODAL_NEW_USER:

            return {...state, newUserModal: action.payload}  
            
        case OPEN_MODAL_VIEW_USER:

            return {...state, 
                viewUserModal: action.payload,
                editOption: action.editOption,
                userEmail: action.email,
                userName: action.userName,
                userSurname: action.userSurname,
                userType: action.userType,
                userCountry: action.userCountry,
                applications:action.aplicaciones
            }  
        
        case OPEN_MODAL_CHANGE_PASSWORD_USER:

            return {...state, 
                openModalChangePasswordUser: action.payload,
                userEmailChangePassword: action.email,
            }  
    
        case OPEN_MODAL_REMOVE_USER:

            return {...state, 
                removeUserModal: action.payload,
            }  
            
        default:
            return state;
    }
}