import { CHANGE_PASSWORD } from '../../Constants';

export const changePass = (state = {} ,action) => {
    switch (action.type) {
        case CHANGE_PASSWORD:
            return {...state, changePassword: action.payload}  
    
        default:
            return state;
    }
}