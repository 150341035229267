import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, Legend} from 'recharts';
import { element } from 'prop-types';

// const  data = [
//   {name: 'Group A', value: 400}, 
//   {name: 'Group B', value: 300},
//   {name: 'Group C', value: 300}, 
//   {name: 'Group D', value: 200}
// ];


var dataProps = [];
var data = [];
var color = "";


const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Cant. ${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};


export default class Graph extends PureComponent {
  state = {
    activeIndex: 0,
    key: "",
    data: [],
    color: "",
  };

  componentDidMount() {
    dataProps = this.props.data;
    data = [];

    color = this.props.color
    var key = "";
    dataProps.map(element => {
      data.push({ name: element.name, [element.values[0].key]: element.values[0].value, color: element.values[0].color })
      key = [element.values[0].key]
    })

    this.setState({
      color: color,
      data: data,
      key: key.toString()
    })

  }

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  render() {

    return (
      <div style={{overflowY: "auto"}}>
      <PieChart width={500} height={320} style={{marginTop: -40,marginBottom: 15 }}>
        <Pie
          activeIndex={this.state.activeIndex}
          activeShape={renderActiveShape}
          data={this.state.data}
          cx={230}
          cy={170}
          innerRadius={60}
          outerRadius={85}
          dataKey={this.state.key}
          onMouseEnter={this.onPieEnter}
        >
   
          {
            this.state.data.map((cell) => <Cell fill={cell.color} />)
          }
        </Pie>
      </PieChart>
      </div>
    );
  }
}
