// DEPENDENCIAS
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { styles } from './constants';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

// ACCIONES
import {showErrorModal} from '../../Actions/_actionShowErrorModal'
import {loader} from '../../Actions/_actionLoader'
import {organizationDash, wizardAddOrganization} from '../../Actions/_actionDashboard'
import { getOrganizationByUser } from '../../Actions/Services/getOrganizationByUser'


const properties = JSON.parse(localStorage.getItem('properties'));

const propsLocalStorage = JSON.parse(localStorage.getItem('properties'));
 class ModalAlert extends Component {
    
    constructor(props) {
        let properties = JSON.parse(localStorage.getItem('properties'));
        super(props);
        this.state = {
            open: true,
            object: {title: 'Mensaje', message: properties.messages.generalError, button: 'Aceptar'}
        }
    }

    componentDidMount () {
        const properties = JSON.parse(localStorage.getItem('properties'));
        properties.errorMessage.map((element) => {
            if(element.value === this.props.errorModal){
                let object = {title: element.title, message: element.message, button: element.button}
                this.setState({object: object})
            }
        })
        properties.modalMessageError.map((element) => {
            if(element.value === this.props.errorModal){
                let object = {title: element.title, message: element.message, button: element.button}
                this.setState({object: object})
            }
        })
        if(this.props.typeRegister === 'private'){
            let object = {title: 'Mensaje', message: this.props.errorModal, button: 'Aceptar'}
            this.setState({object: object})
        }
    }
    
    closeModal = () => {
        this.setState({ open: !this.state.open})
        this.props.loader(false)
        this.props.showErrorModal(false, '')

        if(this.props.errorModal === properties.messages.successCreateOrg){ //EN CASO DE CERRAR EL MODAL LUEGO DE "CREAR" UNA ORGANIZACIÓN CON ÉXITO REDIRECCIONA A LA LISTA DE ORGANIZACIONES
            this.props.getOrganizationByUser('')
            this.props.organizationDash(true);
            this.props.wizardAddOrganization(false)
        }
        if(this.props.errorModal === properties.messages.successUpdateOrg){ //EN CASO DE CERRAR EL MODAL LUEGO DE "EDITAR" UNA ORGANIZACIÓN CON ÉXITO REDIRECCIONA A LA LISTA DE ORGANIZACIONES
            this.props.getOrganizationByUser('')
            this.props.organizationDash(true);
            this.props.wizardAddOrganization(false)
        }
    };

    render() {

        const { classes } = this.props;
        
        return (
            <>
                <Dialog
                    open={this.state.open}
                    onClose={this.state.removeUserOpenModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="xs"
                >
                    <div className={classes.Xclose} onClick={this.closeModal}>
                        <ClearIcon ></ClearIcon>
                    </div>
                    <DialogTitle id="alert-dialog-title" className={classes.title}>
                        <stron>{this.state.object.title}</stron>
                    </DialogTitle>
                    <div className={classes.errorIcon}>
                    {this.props.inconMessageModal?
                        <CheckCircleOutlineIcon
                            className={classes.iconCheck}
                        />
                    :
                        <CancelOutlinedIcon
                                className={classes.icon}
                        />
                    }
                    </div>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container  direction="row" spacing={1} >
                            <Grid item xs={12} md={12}>
                                <Typography className={classes.typografia}>
                                    <strong>{this.state.object.message}</strong>
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div className={classes.alignButton}>
                            <Button
                            margin="dense"
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.closeModal}
                            >
                            <strong>{this.state.object.button}</strong>
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
          </>
        )
        
    }
}

const modalAlert = withStyles(styles, { withTheme: true })(ModalAlert);

function mapStateToProps(state) {
    return {
        errorModal: state.errorShowModal.errorModal,
        typeRegister: state.errorShowModal.typeRegister,
        inconMessageModal: state.errorShowModal.inconMessageModal
    };
}

export default connect(
    mapStateToProps,
    {
        showErrorModal,
        loader,
        organizationDash,
        wizardAddOrganization,
        getOrganizationByUser,
    }   
)(modalAlert);