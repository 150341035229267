import { CIRCULAR_PROGRESS } from '../Constants'

export const progressCircular = (state = {} ,action) => {

    switch (action.type) {
        
        case CIRCULAR_PROGRESS:
            return {...state, circularProgressPayload: action.payloadUtilizado}   

        default:
            return state;
    }
}