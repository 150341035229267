// DEPENDENCIAS
import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import logo from '../Login/img/logoUER.png';
import Zoom from '@material-ui/core/Zoom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import { styles } from './constants';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import esLocale from "date-fns/locale/es";
import { ReactComponent as Bell } from './img/bell.svg';
import { ReactComponent as IconGu } from './img/GU.svg';
import { ReactComponent as IconOrganization } from './img/Organizaciones.svg';
import { ReactComponent as IconMessageSquare } from './img/message-square.svg';


// COMPONENTES
import ChangePassword from './ChangePassword'
import UserManagement from './UserManagement'
import AdminSummary from './AdminSummary'
import Organizations from './Organizations'
import WizardAddOrganization from './Organizations/WizardAddOrganization'
import ModalAlert from '../ModalAlert'

// ACTIONS
import {
    openModalChangePassword,
    openModalNewUser,
    organizationDash,
    wizardAddOrganization,
} from '../../Actions/_actionDashboard';
import { getAllUsers } from '../../Actions/Services/getAllUsers'
import { getOrganizationByUser } from '../../Actions/Services/getOrganizationByUser'
import { getCountries } from '../../Actions/Services/getCountries'
import { getTypeUser } from '../../Actions/Services/getTypeUser'
import { getListLov } from '../../Actions/Services/getListLov'
import { getAreaOfInterest } from '../../Actions/Services/getAreaOfInterest'
import { getOrganizationObjectives } from '../../Actions/Services/getOrganizationObjectives'
import { getOrganizationBeneficiaries } from '../../Actions/Services/getOrganizationBeneficiaries'
import { getListUserByRol } from '../../Actions/Services/getListUserByRol'
import { getMessageById } from '../../Actions/Services/getMessageById'
import "./index.css";
import { getNetworkBelongs } from '../../Actions/Services/getNetworkBelongs';
import { getDashboard} from '../../Actions/Services/getDashboard'

const properties = JSON.parse(localStorage.getItem('properties'));
const rol = JSON.parse(localStorage.getItem('Rol'));
const responseLogin = JSON.parse(localStorage.getItem('responseLogin'));
var menuLeft = JSON.parse(localStorage.getItem('menuLeft'));
var menuTop = JSON.parse(localStorage.getItem('menuTop'));



class Dashboard extends Component {
    constructor(props) {
        let stateCollapse = []
        if(menuLeft !== null){
            stateCollapse = new Array(menuLeft.length)
        }
        super(props);
        this.state = {
            mobileOpen: false,
            open: true,
            auth: true,
            anchorEl: null,
            home: true,
            openCollapse: stateCollapse,
        }
    }

    componentDidMount = () => {
        this.props.getMessageById(responseLogin.data.data.user_id)
        // this.props.messagesOrganizations.map((data, index) =>{
        //     alert(index)
        // })
        if(rol === 2){
            this.clickMenu('organizaciones_component')
        }else{
            this.props.getDashboard()
        }
        let { openCollapse: array } = this.state;
        menuLeft.map((val, i) => {
            array[i] = false
        })
        this.setState({ openCollapse: array });

        let currentDate = new Date()
        let currentYear = currentDate.getFullYear()
        let currentMonth = currentDate.getMonth()
        let allMonth = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
        allMonth.map((month, index) =>{
            if(index === currentMonth)
            {
                currentMonth = month
            }
        })
        this.setState({currentYear: currentYear, currentMonth: currentMonth})
    }

    logout = () => {
        localStorage.clear();
        window.location.reload()
    }

    handleChange = event => {
        this.setState({ auth: event.target.checked });
    };

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen })
    };

    changePassword = () => {
        this.props.openModalChangePassword(true);
    };

    openCollapse(index, component) {
        let newArray = [...this.state.openCollapse];
        newArray[index] = !newArray[index];
        this.setState({ openCollapse: newArray });
        this.clickMenu(component)
    }

    clickMenu = (id) => {
        switch (id) {
            case 'dashboard_component':
                this.setState({ table: false })
                this.setState({ home: true })
                this.props.organizationDash(false);
                this.props.wizardAddOrganization(false)
                localStorage.setItem('style', 1)
                break;
            case 'organizaciones_component':
                localStorage.removeItem('jsonDirectionData');
                localStorage.removeItem('jsonAgentData');
                localStorage.removeItem('imgMisOrganizaciones');
                localStorage.removeItem('imgMisOrganizaciones');
                localStorage.removeItem('jsonTables');
                var ListUserByRol = JSON.parse(localStorage.getItem('ListUserByRol'));
                var countries = JSON.parse(localStorage.getItem('countries'));
                var typeUserCombo = JSON.parse(localStorage.getItem('typeUserCombo'));
                var ListLov = JSON.parse(localStorage.getItem('ListLov'));
                var areaOfInterest = JSON.parse(localStorage.getItem('areaOfInterest'));
                var organizationObjectives = JSON.parse(localStorage.getItem('organizationObjectives'));
                var organizationBeneficiaries = JSON.parse(localStorage.getItem('organizationBeneficiaries'));
                var NetworkBelongs = JSON.parse(localStorage.getItem('NetworkBelongs'));

                this.props.getMessageById(responseLogin.data.data.user_id)

                if (rol === 1) {
                    if (ListUserByRol === null) {
                        this.props.getListUserByRol()
                    }
                }
                if (countries === null)
                    this.props.getCountries()
                if (typeUserCombo === null)
                    this.props.getTypeUser()
                if (ListLov === null)
                    this.props.getListLov()
                if (areaOfInterest === null)
                    this.props.getAreaOfInterest()
                if (organizationObjectives === null)
                    this.props.getOrganizationObjectives(32)
                if (organizationBeneficiaries === null)
                    this.props.getOrganizationBeneficiaries()
                if (NetworkBelongs === null)
                    this.props.getNetworkBelongs()


                this.props.getOrganizationByUser('')
                this.setState({ home: false })
                this.setState({ table: false })
                this.props.organizationDash(true);
                this.props.wizardAddOrganization(false)
                break;
            case 'user_component':
                var countries = JSON.parse(localStorage.getItem('countries'));
                var typeUserCombo = JSON.parse(localStorage.getItem('typeUserCombo'));
                if (countries === null)
                    this.props.getCountries()
                if (typeUserCombo === null)
                    this.props.getTypeUser()
                this.setState({ table: true })
                this.props.getAllUsers()
                this.setState({ home: false })
                this.props.wizardAddOrganization(false);
                this.props.organizationDash(false);
                localStorage.setItem('style', 2)
                break;

            default:
                console.log("Default")
                break;
        }
    };

    clickMenuTop = (id) => {
        switch (id) {
            case 'miperfil_component':
                console.log("Mi perfil")
                break;
            case 'clave_component':
                this.props.openModalChangePassword(true);
                break;

            case 'cerrar_component':
                localStorage.clear();
                window.location.reload()
                break;

            default:
                console.log("Default")
                break;
        }
    }

    render() {
        const { classes } = this.props;
        const { auth, anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const defaultProps = {
            color: 'error',
            children: <Bell/>,
          };
        return (
            <>
                <div className={classes.root} >
                    <CssBaseline />
                    <AppBar position="fixed" className={classes.appBar} color="primary">
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={this.handleDrawerToggle}
                                className={classes.menuButton}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography className={classes.title}>
                                <strong>Caracas, {this.state.currentMonth} del {this.state.currentYear}</strong>

                            </Typography>
                            {/* <NotificationsNoneRoundedIcon className={classes.menuIcon}></NotificationsNoneRoundedIcon> */}
                            <div>
                                <Badge anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }} 
                                    className={classes.menuIcon} 
                                    badgeContent={this.props.messagesOrganizations&&this.props.messagesOrganizations.length} 
                                    max={999} 
                                    {...defaultProps} 
                                />
                            </div>
                            <Typography >
                                <strong>{responseLogin.data.data.name} {responseLogin.data.data.lastname}</strong>
                            </Typography>
                            {auth && (
                                <div>
                                    <IconButton
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={this.handleMenu}
                                        color="inherit"
                                    >
                                        <AccountCircle />
                                    </IconButton>
                                    <Menu
                                        style={{ marginTop: '3%' }}
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'center',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'center',
                                            horizontal: 'right',
                                        }}
                                        open={open}
                                        onClose={this.handleClose}
                                    >
                                        {menuTop.map((element, index) => (
                                            <MenuItem onClick={() => this.clickMenuTop(element.react_component)}>{element.siplay_value}</MenuItem>
                                        ))}

                                    </Menu>
                                </div>
                            )}
                        </Toolbar>
                    </AppBar>
                    <nav className={classes.drawer} aria-label="mailbox folders">

                        <Hidden smUp implementation="css">

                            <Drawer
                                variant="temporary"
                                anchor={'rtl2' === 'rtl' ? 'right' : 'left'}
                                open={this.state.mobileOpen}
                                onClose={this.handleDrawerToggle}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                ModalProps={{
                                    keepMounted: true,
                                }}
                            >
                                <div>
                                    <div className={classes.toolbar} />
                                    <List>

                                        {menuLeft.map((object, index) => (
                                            <>
                                                <ListItem button onClick={() => this.openCollapse(index, object.react_component)}>
                                                    <ListItemIcon>{index % 2 === 0 ? <ChatBubbleOutlineIcon /> : <MailIcon />}</ListItemIcon>
                                                    <ListItemText primary={object.siplay_value} />
                                                    {object.childs.length > 0 &&
                                                        <>
                                                            {this.state.openCollapse[index] ? <ExpandLess /> : <ExpandMore />}
                                                        </>
                                                    }
                                                </ListItem>
                                                <Collapse in={this.state.openCollapse[index]} timeout="auto" unmountOnExit>
                                                    {object.childs.length > 0 &&
                                                        object.childs.map((child) => (
                                                            <List component="div" disablePadding>
                                                                <ListItem button>
                                                                    <ListItemIcon>
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={child.siplay_value} />
                                                                </ListItem>
                                                            </List>
                                                        ))
                                                    }
                                                </Collapse>
                                            </>
                                        ))}
                                    </List>

                                </div>
                            </Drawer>
                        </Hidden>
                        <Hidden xsDown implementation="css">
                            <Drawer
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                variant="permanent"
                                open
                            >
                                <div>
                                    <div className={classes.toolbar} />
                                    <div className={classes.centered}>
                                        {rol !== 1 ?
                                            <img src={logo} className={classes.logo} />
                                        :
                                            <img onClick={() => this.clickMenu('dashboard_component')} src={logo} className={classes.logo} />
                                        }
                                    </div>
                                    <List>

                                        {menuLeft.map((object, index) => (
                                            <>

                                                <ListItem button onClick={() => this.openCollapse(index, object.react_component)}>

                                                    <ListItemIcon>
                                                        <i className={"uer-app " + object.icon} />
                                                    </ListItemIcon>
                                                    <ListItemText primary={object.siplay_value} />
                                                    {object.childs.length > 0 &&
                                                        <>
                                                            {this.state.openCollapse[index] ? <ExpandLess /> : <ExpandMore />}
                                                        </>
                                                    }
                                                </ListItem>
                                                <Collapse in={this.state.openCollapse[index]} timeout="auto" unmountOnExit>
                                                    {object.childs.length > 0 &&
                                                        object.childs.map((child) => (
                                                            <List component="div" disablePadding>
                                                                <ListItem button>
                                                                    <ListItemIcon>
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={child.siplay_value} />
                                                                </ListItem>
                                                            </List>
                                                        ))
                                                    }
                                                </Collapse>
                                            </>
                                        ))}
                                    </List>
                                </div>
                            </Drawer>
                        </Hidden>
                    </nav>
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        {this.state.table && <UserManagement />}
                        {this.state.home &&
                            <>
                                {properties.user.type === rol ?
                                    <Typography variant="h6" className={classes.title}>
                                        Bienvenido, {properties.user.rol}
                                    </Typography>
                                    :
                                    <Typography variant="h6" className={classes.title}>
                                        Bienvenido
                                    </Typography>
                                }
                            </>

                        }
                        {this.state.home &&
                            <Zoom in={true}>
                                <AdminSummary />
                            </Zoom>}
                        {this.props.changePasswordModal && <ChangePassword />}
                        {this.props.organizationTable && <Organizations />}
                        {this.props.addOrganizationWizard && <WizardAddOrganization disable={this.props.disableField} />}
                        {this.props.errorModal && <ModalAlert />}
                    </main>
                    {/* {this.props.circularProgressPayload && <CircularProgress className={classes.circularProgress} color="primary"/>} */}
                </div>
            </>
        )
    }
}

const dashboard = withStyles(styles, { withTheme: true })(Dashboard);

function mapStateToProps(state) {

    return {
        changePasswordModal: state.dashboard.changePasswordModal,
        organizationTable: state.dashboard.organizationTable,
        addOrganizationWizard: state.dashboard.addOrganizationWizard,
        disableField: state.dashboard.disableField,
        errorModal: state.errorShowModal.errorModal,
        messagesOrganizations: state.getMessages.messagesOrganizations,
        // circularProgressPayload: state.progressCircular.circularProgressPayload
    };
}

export default connect(
    mapStateToProps,
    {
        openModalChangePassword,
        organizationDash,
        wizardAddOrganization,
        getAllUsers,
        getOrganizationByUser,
        getCountries,
        getTypeUser,
        getListLov,
        getAreaOfInterest,
        getOrganizationObjectives,
        getOrganizationBeneficiaries,
        getListUserByRol,
        getMessageById,
        getNetworkBelongs,
        getDashboard
    }
)(dashboard);



