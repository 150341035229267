
import axios from 'axios'
import { uploadImageMyOrganization, uploadImageDirection } from '../_actionUploadImage'
import { spinnerImageMyOrganization, spinnerImageDirection } from '../_actionWizardProps'
import { showErrorModal } from '../_actionShowErrorModal'

export function postUploadImage(image, name) {

    return dispath => {
        var properties = JSON.parse(localStorage.getItem('properties'));
        let Authorization = localStorage.getItem('Authorization');
        var UPLOAD_IMAGE_ENDPOINT = properties.ENPOINTS.UPLOAD_IMAGE_ENDPOINT;
        axios({
            method: 'POST',
            url: UPLOAD_IMAGE_ENDPOINT,
            headers: {
                "Authorization": "Bearer " + Authorization,
            },
            data: image
        }).then((response) => {
            // localStorage.setItem(name, JSON.stringify(response.data));
            if (name === 'imgMisOrganizaciones') {
                dispath(uploadImageMyOrganization(true, response.data))
                dispath(spinnerImageMyOrganization(false))
                localStorage.setItem(name, JSON.stringify(response.data));
            }
            if (name === 'imgDirection') {
                dispath(uploadImageDirection(true, response.data))
                dispath(spinnerImageDirection(false))
                localStorage.setItem(name, JSON.stringify(response.data));
            }
        }).catch(error => {
            console.log(error)
            let message = undefined
            if (error.response.data.code) {
                properties.errorMessage.map((element) => {
                    if (element.value === error.response.data.code) {
                        message = error.response.data.code
                    }
                })
            }
            dispath(showErrorModal(true, message))
        });
    }
}
