export const styles = theme => ({
    submit: {
      margin: theme.spacing(0, 2, 1, 1),
      backgroundColor: '#494377',
      borderRadius: '0px !important',
    },
    title: {
      margin: theme.spacing(0, 0, 0, 0),
      marginBottom: '-15px'
    },
    Xclose: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      margin: theme.spacing(1, 2, 0, 1),
      cursor: 'pointer',
  },
  });