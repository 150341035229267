
import axios from 'axios'
import { ALL_USERS } from '../../Constants';
import {showErrorModal} from '../../Actions/_actionShowErrorModal'
export function putUpdateUser(user_id, name, surname, email, country_id, user_type, application_id) {
    
    return dispath => {
        let properties = JSON.parse(localStorage.getItem('properties'));
        let response = JSON.parse(localStorage.getItem('responseLogin'));
        let info = response.data.data
        let USERBYID_ENPOINT = properties.ENPOINTS.USERBYID_ENPOINT;
        let Authorization = localStorage.getItem('Authorization');
        axios({
            method: 'PUT',
            url: "http://10.192.98.93:8080/user/update",
            headers: {
                "Content-Type":"application/json",
                "Authorization": "Bearer " + Authorization
            },
            data: {
                "user_id":user_id,                 //id de la persona que edita ADMINIS
                "name":name,                            //nombre del usuario editado USU
                "lastname":surname,                     //apellido del usuario editado USU
                "email":email,                          //email del usuario editado USU
                "transaction_id":info.transaction_id,   //transaction id de usuario que edita ADMI
                "country_id":country_id,                //id país de usuario editado USU
                "application_roles":
                [
                    {
                        "IdAplicacion":1,             //id app? ADMI
                        "Roles":
                        [
                            {
                                "IdRol":user_type             //rol? USU
                            },
                        ]
                    }
                ]
            }
        }).then((response) => {
            // if(response.data.code === "200"){
            //     dispath(showErrorModal(true, properties.messages.successUpdateUser))
            // }else{
            //     dispath(showErrorModal(true, properties.messages.failRegister))
            // }
        }).catch(error => {
            let message = properties.message
            if(error.response){
                properties.errorMessage.map((element) => {
                    if(element.value === error.response.data.code){
                        message = element.message
                    }
                })
            }
            dispath(showErrorModal(true, error.response.data.code))
        });
    }
}
