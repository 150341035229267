import { UPLOADIMAGE_MYORGANIZATION, UPLOADIMAGE_DIRECTION } from '../Constants'

export const showUploadImageMyOrganization = (state = {}, action) => {
    switch (action.type) {

        case UPLOADIMAGE_MYORGANIZATION:
            return {
                ...state,
                showUploadImage: action.payload,
                imageURL: action.image,
            }
        case UPLOADIMAGE_DIRECTION:
            return {
                ...state,
                showUploadImageDirection: action.payload,
                imageURLDirection: action.image,
            }
        default:
            return state;
    }
}