import { SHOW_ERROR_MODAL  } from '../Constants'

export const showErrorModal = (payload, error, typeRegister, response, inconMessageModal) => {
    return {
        type: SHOW_ERROR_MODAL,
        payload: payload,
        error: error,
        typeRegister: typeRegister,
        response: response,
        inconMessageModal: inconMessageModal
    }
}