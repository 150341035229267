import { ORGANIZATION_BY_USER } from '../../Constants';

export const getOrganizationByUserReducer = (state = {} ,action) => {
    switch (action.type) {
        case ORGANIZATION_BY_USER:
            return {...state, 
                payload: action.payload,
                listOrganization: action.listOrganizationByUser,
                totalPagePaginationOrganizations: action.totalPagePaginationOrganizations,
            }
        default:
            return state;
    }
}