import { MESSAGES_USER } from '../../Constants';

export const getMessages = (state = {} ,action) => {
    switch (action.type) {
        case MESSAGES_USER:
            return {...state, 
                messagesOrganizations: action.messagesResponse,
            }
    
        default:
            return state;
    }
}