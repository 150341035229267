import { SPINNER_MYORGANIZATION, SPINNER_DIRECTION  } from '../Constants'

export const spinnerImageMyOrganization = (payload) => {
    return {
        type: SPINNER_MYORGANIZATION,
        payload: payload,
    }
}

export const spinnerImageDirection = (payload) => {
    return {
        type: SPINNER_DIRECTION,
        payload: payload,
    }
}