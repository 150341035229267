// DEPENDENCIAS
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import { styles } from './constants';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

// ACTIONS
import {
    openModalNewUser,
} from '../../../../Actions/_actionUserManagement';
import {getCountries} from '../../../../Actions/Services/getCountries';
import {getTypeUser} from '../../../../Actions/Services/getTypeUser';
import { postPublicUserRegister } from '../../../../Actions/Services/postPublicUserRegister';
import { loader } from './../../../../Actions/_actionLoader';

// CONSTANTES
var countries = JSON.parse(localStorage.getItem('countries'));
var typeUserCombo = JSON.parse(localStorage.getItem('typeUserCombo'));
const patternPassword = /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\d\X])(?=.*[-+_!@#$%^&*.,?]).*$/
const patternEmail = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,9}$/i
const patternName = /^([^0-9-+_!/@#$%^&*.,?]*)$/
const patternSelect = /\w/
const inputsValidate =['name', 'surname', 'emailR', 'passwordR', 'rememberPassword', 'selectUserType', 'selectCountry']

// COMPONENTE TIPO CLASE
class NewUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
        }
    }

    //FUNCIONES
    validateTextField = (name, value) => {
        this.setState({['newErrorInput'+name]: true})
        switch (name) {
            case 'name':
                this.patterns(value, name, patternName)
                break;
            case 'surname':
                this.patterns(value, name, patternName)
                break;
            case 'selectUserType':
                this.patterns(value, name, patternSelect)
                break;
            case 'emailR':
              this.patterns(value, name, patternEmail)
              break;
            case 'passwordR':
              this.patterns(value, name, patternPassword)
              break;
            case 'rememberPassword':
              this.patterns(value, name, patternPassword)
              break;
              case 'selectCountry':
                this.patterns(value, name, patternSelect)
                break;
            default:
              break;
          }
    }

    patterns = (value, name, pattern) => {
        if(pattern.test(value)){
            this.setState({
                ['bool' + name]: true
              },
                () => {
                  let allInputsAux = true
                  inputsValidate.map((element) => {
                    if (this.state['bool' + element] !== true) {
                      allInputsAux = false
                    }
                  })
        
                  if (this.state.passwordR !== this.state.rememberPassword) {
                    allInputsAux = false
                    this.props.loader(true)
                    this.setState({ boolrememberPassword: false })
                  } else this.setState({ boolrememberPassword: true })
        
                  if (allInputsAux === true) {
                    this.props.loader(false)
                  }
                })
        } else {
            this.setState({
                ['bool' + name]: false
            },
                () => {
                    inputsValidate.map((element) => {
                        if (this.state['bool' + element] === false) {
                        this.props.loader(true)
                        }
                    })
                })
            }

      }

    componentDidMount = () => {
        this.props.loader(true)
    }

    openModalNewUser = () => {
        this.setState({ openModalNewUser: !this.state.openModalNewUser})
    };
    
    closeModal = () => {
        this.props.openModalNewUser(false)
    };

    register = () => {
        this.props.openModalNewUser(false)
        this.props.postPublicUserRegister(this.state.name, this.state.surname, this.state.emailR, this.state.passwordR, this.state.selectUserType, this.state.selectCountry, 'private');
        this.props.loader(true)
    };

    onChangeValues = (event) => {
        var value = event.target.value;
        var name = event.target.name;
        this.setState({[name]: value})
        this.validateTextField(name,value)
    }

    render() {

        const { classes } = this.props;
        
        return (
            <>
                <Dialog
                    open={true}
                    onClose={this.state.openModalNewUser}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="xs"
                >
                    <div className={classes.Xclose} onClick={this.closeModal}>
                    <ClearIcon ></ClearIcon>
                    </div>
                    <DialogTitle id="alert-dialog-title" className={classes.title}>
                        <stron>Registrar Nuevo Usuario</stron>
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container  direction="row" spacing={1} >
                            <Grid item xs={12} md={12}>
                                <TextField
                                    margin="dense"
                                    required
                                    fullWidth
                                    label="Nombre"
                                    onChange={this.onChangeValues}
                                    value={this.state.name}
                                    id="name"
                                    name="name"
                                    type="text"
                                    InputProps={{
                                        endAdornment:
                                        <>
                                        {this.state.newErrorInputname &&
                                          <>
                                            {this.state.boolname ?
                                              <InputAdornment>
                                                <CheckIcon style={{ marginRight: '6px', color: 'green' }} />
                                              </InputAdornment>
                                            :
                                              <InputAdornment>
                                                <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                              </InputAdornment>
                                            }   
                                          </>
                                        }
                                        </>
                                    }}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    margin="dense"
                                    required
                                    fullWidth
                                    label="Apellido"
                                    onChange={this.onChangeValues}
                                    value={this.state.surname}
                                    id="surname"
                                    name="surname"
                                    type="text"                                  
                                    InputProps={{
                                        endAdornment:
                                        <>
                                        {this.state.newErrorInputsurname &&
                                            <>
                                            {this.state.boolsurname ?
                                                <InputAdornment>
                                                <CheckIcon style={{ marginRight: '6px', color: 'green' }} />
                                                </InputAdornment>
                                            :
                                                <InputAdornment>
                                                <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                                </InputAdornment>
                                            }   
                                            </>
                                        }
                                        </>
                                    }}
                                    color="primary"
                                />
                            </Grid>       
                            <Grid item xs={12} md={12}>
                                <TextField
                                    margin="dense"
                                    required
                                    fullWidth
                                    label="Correo eletrónico"
                                    onChange={this.onChangeValues}
                                    value={this.state.emailR}
                                    id="emailR"
                                    name="emailR"
                                    type="email"
                                    InputProps={{
                                        endAdornment:
                                        <>
                                        {this.state.newErrorInputemailR &&
                                          <>
                                            {this.state.boolemailR ?
                                              <InputAdornment>
                                                <CheckIcon style={{ marginRight: '6px', color: 'green' }} />
                                              </InputAdornment>
                                            :
                                              <InputAdornment>
                                                <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                              </InputAdornment>
                                            }   
                                          </>
                                        }
                                        </>
                                    }}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl className={classes.select} fullWidth>
                                    <InputLabel >Tipo de Usuario</InputLabel>
                                    <Select
                                    name="selectUserType"
                                    value={this.state.userType}
                                    onChange={this.onChangeValues}
                                    >
                                    {typeUserCombo && 
                                        typeUserCombo.map((type) => (
                                        <MenuItem key={type.id_rol} value={type.nombre_rol}>{type.nombre_rol}</MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>                            
                                <TextField
                                    margin="dense"
                                    required
                                    fullWidth
                                    label="Contraseña"
                                    onChange={this.onChangeValues}
                                    value={this.state.passwordR}
                                    id="passwordR"
                                    name="passwordR"
                                    type="password"
                                    InputProps={{
                                        endAdornment:
                                        <>
                                        {this.state.newErrorInputpasswordR &&
                                          <>
                                            {this.state.boolpasswordR ?
                                              <InputAdornment>
                                                <CheckIcon style={{ marginRight: '6px', color: 'green' }} />
                                              </InputAdornment>
                                            :
                                              <InputAdornment>
                                                <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                              </InputAdornment>
                                            }   
                                          </>
                                        }
                                        </>
                                    }}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    margin="dense"
                                    required
                                    fullWidth
                                    label="Repita Contraseña"
                                    onChange={this.onChangeValues}
                                    value={this.state.rememberPassword}
                                    id="rememberPassword"
                                    name="rememberPassword"
                                    type="password"
                                    InputProps={{
                                        endAdornment:
                                        <>
                                        {this.state.newErrorInputrememberPassword &&
                                          <>
                                            {this.state.boolrememberPassword ?
                                              <InputAdornment>
                                                <CheckIcon style={{ marginRight: '6px', color: 'green' }} />
                                              </InputAdornment>
                                            :
                                              <InputAdornment>
                                                <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                              </InputAdornment>
                                            }   
                                          </>
                                        }
                                        </>
                                    }}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl className={classes.select} fullWidth>
                                    <InputLabel>País</InputLabel>
                                    <Select
                                    name="selectCountry"
                                    value={this.state.country}
                                    onChange={this.onChangeValues}
                                    >
                                    {countries &&
                                      countries.map((element) => (
                                      <MenuItem key={element.id_pais} value={element.nombre_pais} >{element.nombre_pais}</MenuItem>
                                    ))} 
                                    
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div className={classes.alignButton}>
                            <Button
                            margin="dense"
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.register}
                            disabled={this.props.loaderPayload}
                            >
                            <strong>Registrar</strong>
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
          </>
        )
        
    }
}

const newUser = withStyles(styles, { withTheme: true })(NewUser);

function mapStateToProps(state) {
    return {
        changePasswordModal: state.dashboard.changePasswordModal,
        getCountriesCombo: state.getCountries.getCountriesCombo,
        typeUser: state.userType.typeUser,
        loaderPayload: state.loader.loaderPayload,
    };
}

export default connect(
    mapStateToProps,
    {
        openModalNewUser,
        postPublicUserRegister,
        getTypeUser,
        getCountries,
        loader,
    }
)(newUser);