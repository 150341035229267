
import axios from 'axios'
import { USER_BY_ID } from '../../Constants';
import {openModalViewUser} from '../../Actions/_actionUserManagement'
import {showErrorModal} from '../../Actions/_actionShowErrorModal'

export function getUserById(id, formOption) {
    
    return dispath => {
        let properties = JSON.parse(localStorage.getItem('properties'));
        let USERBYID_ENPOINT = properties.ENPOINTS.USERBYID_ENPOINT;
        let Authorization = localStorage.getItem('Authorization');
        axios({
            method: 'GET',
            url: USERBYID_ENPOINT,
            params: {
                id: id
            },
            headers: {
                "Content-Type":"application/json",
                "Authorization": "Bearer " + Authorization
            }
        }).then((response) => {
            let res = response.data.data
            let role
            if(res){
                dispath({
                    type: USER_BY_ID,
                    userById: res,
                })
                if(res.aplicaciones){
                    res.aplicaciones.map((element) => {
                        role = element.roles[0].id_rol
                    })
                }
            }
            dispath(openModalViewUser(true, formOption, res.email, res.name, res.lastname, role, res.id_pais))
        }).catch(error => {
            let message = properties.message
            if(error.response){
                properties.errorMessage.map((element) => {
                    if(element.value === error.response.data.code){
                        message = element.message
                    }
                })
            }
            dispath(showErrorModal(true, message))
        });
    }
}
