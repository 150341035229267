
import axios from 'axios'
import { REMEMBER_PASSWORD } from '../../Constants';
import {showErrorModal} from '../_actionShowErrorModal'
import {loader} from '../../Actions/_actionLoader'

export function postRememberPassword(email, type) {
    
    return dispath => {
        var properties = JSON.parse(localStorage.getItem('properties'));
        var REMEMBERPASSWORD_ENPOINT = properties.ENPOINTS.REMEMBERPASSWORD_ENPOINT;
        axios({
            method: 'POST',
            url: REMEMBERPASSWORD_ENPOINT,
            data: {
                "user":email,
            }
        }).then((response) => {
            dispath(loader(false))
            if(response.data.code === "200"){
                dispath(showErrorModal(true, properties.messages.successRecovery, type, 0, true))
            }else{
                dispath(showErrorModal(true, properties.message, type, 1, false))
            }
            
        }).catch(error => {
            dispath(loader(false))
            let message = properties.message
            if(error.response){
                properties.errorMessage.map((element) => {
                    if(element.value === error.response.data.code){
                        message = element.message
                    }
                })
            }
            dispath(showErrorModal(true, message, type, 1, false))
        });
    }
}
