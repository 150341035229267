import { 
    OPEN_MODAL_DELETE_ORGANIZATION,
 } from '../Constants'

export const openModalDeleteOrganization = (payload, id_organization) => {
    return {
        type: OPEN_MODAL_DELETE_ORGANIZATION,
        payload: payload,
        id_organization: id_organization,
    }
}