import { LOADER } from '../Constants'


export const loader = (state = {} ,action) => {

    switch (action.type) {
        
        case LOADER:
            return {...state, loaderPayload: action.payload}   

        default:
            return state;
    }
}