import { SHOW_ERROR_MODAL } from '../Constants'

export const errorShowModal = (state = {} ,action) => {
    switch (action.type) {
        
        case SHOW_ERROR_MODAL:
            return {...state, 
                showModal: action.payload,
                errorModal: action.error,
                typeRegister: action.typeRegister,
                statusResponse: action.response,
                inconMessageModal: action.inconMessageModal
            }   
        default:
            return state;
    }
}