import { 
    OPEN_MODAL_CHANGE_PASSWORD,
    OPEN_MODAL_NEW_USER,
    ORGANIZATION_DASH,
    WIZARD_ADD_ORGANIZATION,
    DASHBOARD
 } from '../Constants'


export const dashboard = (state = {} ,action) => {

    switch (action.type) {

        case DASHBOARD:

        return {...state, data: action.payload}  
        
        case OPEN_MODAL_CHANGE_PASSWORD:

            return {...state, changePasswordModal: action.payload}   

        case OPEN_MODAL_NEW_USER:

            return {...state, newUserModal: action.payload} 
        
        case ORGANIZATION_DASH:

            return {...state, organizationTable: action.payload} 
        
        case WIZARD_ADD_ORGANIZATION:

            return {...state, 
                addOrganizationWizard: action.payload,
                disableField: action.disable,
                objeto: action.objeto
            }
             
        default:
            return state;
    }
}