
import axios from 'axios'
import { COUNTRIES_COMBO } from '../../Constants';

export function getCountries() {
    
    return dispath => {
        let properties = JSON.parse(localStorage.getItem('properties'));
        let GETCOUNTRIES_ENPOINT = properties.ENPOINTS.GETCOUNTRIES_ENPOINT;
        axios({
            method: 'GET',
            url: GETCOUNTRIES_ENPOINT,
            headers: {
                "Content-Type":"application/json",
            }
        }).then((response) => {
            var countries = []
            var regionCountry = response.data.data
            response.data.data.map(element => {
                if(element.es_region === 0)
                {
                    countries.push(element)
                }
            })
            localStorage.setItem('countries', JSON.stringify(countries));
            localStorage.setItem('regionCountry', JSON.stringify(regionCountry));
            dispath({
                type: COUNTRIES_COMBO,
                payload: true,
                countries: countries
            })
        })
            .catch(error => {
                let errorObject = JSON.parse(JSON.stringify(error));
                console.log(errorObject)
                dispath({
                    type: COUNTRIES_COMBO,
                    payload: false,
                    countries: null,
                })
            });
    }
}
