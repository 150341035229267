import img1 from './img/login.jpeg';
import img2 from './img/register.jpg';
import logo from './img/logoUER.png';
const properties = JSON.parse(localStorage.getItem('properties'));

export const styles = theme => ({
  root: {
    height: '100vh',
    weight: '100vw',
  },
  image: {
    // background: 'url(https://source.unsplash.com/random)',
    // backgroundRepeat: 'no-repeat',
    // webkitBackgroundSize: 'cover',
    // mozBackgroundSize: 'cover',
    // oBackgroundSize: 'cover',
    // backgroundSize: 'cover',
    backgroundColor: '#efefef',
    backgroundPosition: 'center center',
    backgroundRepeat:' no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
  },
  gridPaper: {
    backgroundColor: '#ffffffbf',
    width: '100%',
    // marginLeft: '12%',
    // marginTop: '-2%',
  },
  paper: {
    margin: theme.spacing(2, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
  },
  title: {
    marginTop: theme.spacing(0),
  },
  button: {
    margin: theme.spacing(3, 0, 1),
    backgroundColor: '#ec008c',
    borderRadius: '0px !important',
  },
  alignButton: {
    textAlign: 'center',
    // display: 'inline',
    // width: 'auto',
    // margin: 'auto',
    // textAlign: 'left',
  },
  textButton: {
    padding: '3px 25px 3px 25px'
  },
  select: {
    // margin: theme.spacing(0),
    minWidth: "100%",
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorMessage: {
    margin: theme.spacing(1, 0, 0, 1),
  }

});

//////////////////////////////////////  FORMULARIO INICIO SESIÓN  ////////////////////////////////////////////////////////

export const LOGINFORM = {
  id: 1, // 1 = Login, 2 = Cambio de clave, 3 = Registro,
  logoStyles: {
    marginTop: '10%',
    marginBottom: '2%',
    height: '100px',
  },
  background: img1, // 1 = La imagen de login, 2 = La imagen de registro y cambio de clave
  title: "Inicio de sesión",
  inputs: [
    {
      label: "Correo",
      type: "email",
      name: "email",
      format: properties.formatEmail,
      pattern: /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,9}$/i,
      tooltip: undefined,
      autoFocus: true,
      xs: 12,
      md: 12
    },
    {
      label: "Contraseña",
      type: "password",
      name: "password",
      format: properties.formatPassword,
      pattern: /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\d\X])(?=.*[-+_!@#$%^&*.,?]).*$/,
      tooltip: undefined,
      xs: 12,
      md: 12,
    },
  ],
  button: 
    [
      {
        pointer: "1",
        name: "Ingresar",
        xs: '12',
        md: '12',
      },
    ],
  links: [
    {
      id: "5",
      label: "¿Olvidó su contraseña?",
      link: "Recuperar clave aquí",
      strong: 1 //0 =  subrayado, 1 = negrita
    },
    {
      id: "6",
      label: "Resgistrate en UER",
      link: "Crea tu cuenta aquí",
      strong: 1 //0 =  subrayado, 1 = negrita
    },


  ],
  reCaptcha: 0, // 0 = ocultar, 1 = mostrar
  logo: logo,
}

//////////////////////////////////////  FORMULARIO RECUPERAR CONTRASEÑA  ////////////////////////////////////////////////////////

export const RECOVERYFORM = {
  id: 2, // 1 = Login, 2 = Cambio de clave, 3 = Registro,
  logoStyles: {
    marginTop: '10%',
    marginBottom: '2%',
    height: '100px',
  },
  background: img2, // 1 = La imagen de login, 2 = La imagen de registro y cambio de clave
  title: "Recuperar clave",
  inputs: [
    {
      label: "Correo",
      type: "email",
      name: "emailRecovery",
      pattern: /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,9}$/i,
      tooltip: false,
      autoFocus: true,
      xs: '12',
      md: '12'
    },
  ],
  button: 
  [
    {
      pointer: "4",
      name: "Volver",
      autoFocus: false,
      xs: '6',
      md: '6'
    },
    {
      pointer: "2",
      name: "Recuperar",
      autoFocus: false,
      xs: '6',
      sm: '6',
      md: '6',
    },
  ],
  links: [

  ],
  reCaptcha: 1, // 0 = ocultar, 1 = mostrar
  logo: logo,
}

//////////////////////////////////////  FORMULARIO REGISTRO  ////////////////////////////////////////////////////////
export const REGISTERFORM = {
  id: 3, // 1 = Login, 2 = Cambio de clave, 3 = Registro,
  logoStyles: {
    marginTop: '2%',
    marginBottom: '1%',
    height: '100px',
  },
  background: img2, // 1 = La imagen de login, 2 = La imagen de registro y cambio de clave
  title: "Registro de usuario",
  inputs: [
    {
      label: "Nombre",
      type: "text",
      name: "name",
      format: '',
      pattern: /^([^0-9-+_!/@#$%^&*.,?]*)$/,
      tooltip: false,
      autoFocus: true,
      xs: 12,
      md: 12
    },
    {
      label: "Apellido",
      type: "text",
      name: "surname",
      format: '',
      pattern: /^([^0-9-+_!/@#$%^&*.,?]*)$/,
      tooltip: false,
      autoFocus: false,
      xs: 12,
      md: 12
    },
    {
      label: "Correo electrónico",
      type: "email",
      name: "emailR",
      format: properties.formatEmail,
      pattern: /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,9}$/i,
      tooltip: false,
      autoFocus: false,
      xs: 12,
      md: 12
    },
    {
      label: "Tipo de usuario",
      type: "select",
      name: "userType",
      pattern: /\w/,
      autoFocus: false,
      xs: 12,
      md: 12
    },
    {
      label: "Contraseña",
      type: "password",
      name: "passwordR",
      format: properties.formatPassword,
      pattern: /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\d\X])(?=.*[-+_!@#$%^&*.,?]).*$/,
      tooltip: undefined,
      autoFocus: false,
      xs: 12,
      md: 6
    },
    {
      label: "Confirmar contraseña",
      type: "password",
      name: "confirmPassword",
      format: properties.formatPassword,
      pattern: /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\d\X])(?=.*[-+_!@#$%^&*.,?]).*$/,
      tooltip: undefined,
      autoFocus: false,
      xs: 12,
      md: 6
    },
    {
      label: "País",
      type: "select",
      name: "country",
      pattern: /\w/,
      autoFocus: false,
      xs: 12,
      md: 12
    },

  ],
  button: 
  [
    {
      pointer: "4",
      name: "Volver",
      xs: '6',
      md: '6'
    },
    {
      pointer: "3",
      name: "Registrar",
      xs: '6',
      sm: '6',
      md: '6',
    },
  ],
  headerLinks: [
    {
      id: "1",
      label: "Al registrarte en el portal de Unidos en Red, estarás aceptando nuestra",
      link: "Política de Privacidad",
      strong: 0 //0 =  subrayado, 1 = negrita
    },
  ],
  links: [
  ],
  reCaptcha: 1, // 0 = ocultar, 1 = mostrar
  logo: logo,
  functions: {
    backToLogin: 'backToLogin',
  }
}

export const resetInputs = {
    email: '',
    password: '',
    emailRecovery: '',
    name: '',
    surname: '',
    emailR: '',
    userType: '',
    passwordR: '',
    confirmPassword: '',
    country: '',
    newErrorInputemail: '',
    newErrorInputpassword: '',
    newErrorInputemailRecovery: '',
    newErrorInputname: '',
    newErrorInputsurname: '',
    newErrorInputemailR: '',
    newErrorInputuserType: '',
    newErrorInputpasswordR: '',
    newErrorInputconfirmPassword: '',
    newErrorInputcountry: '',
}