// DEPENDENCIAS
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from '@material-ui/lab/Pagination';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import { styles } from './constants';
import { ReactComponent as IconSee } from './img/see.svg';
import { ReactComponent as IconEdit } from './img/edit.svg';
import { ReactComponent as IconPadlock } from './img/padlock.svg';
import { ReactComponent as IconPadlockOpen } from './img/open-lock.svg';
import { ReactComponent as IconPassword } from './img/password.svg';
import { ReactComponent as IconDelete } from './img/delete.svg';

// COMPONENTES
import NewUser from './NewUser'
import ViewUser from './ViewUser'
import ChangePasswordUser from './ChangePasswordUser'
import RemoveUser from './RemoveUser'

// ACTIONS
import {
    openModalNewUser,
    openModalViewUser,
    openModalChangePasswordUser,
    openModalRemoveUser
} from '../../../Actions/_actionUserManagement';
import { getUserById } from '../../../Actions/Services/getViewUserById'
import { getTypeUser } from '../../../Actions/Services/getTypeUser'
import { getCountries } from '../../../Actions/Services/getCountries'
import { postBlockUser } from '../../../Actions/Services/postBlockUser'
import { getUserSearch } from '../../../Actions/Services/getUserSearch'
import { loader } from '../../../Actions/_actionLoader'

import './index.css'
// CONSTANTES
const style = styles;
const properties = JSON.parse(localStorage.getItem('properties'));

function createData(email, name, surname, userType, country, actions, estado) {
    return { email, name, surname, userType, country, actions, estado };
}

const icons =
    [
        {
            icono: <IconButton style={{ background: '#494377', width: '30px', height: '30px', padding: '3px', marginRight: '4px', blockSize: '30px' }}><IconSee /></IconButton>,
            icono2: <IconButton style={{ background: '#494377', width: '30px', height: '30px', padding: '3px', marginRight: '4px', blockSize: '30px' }}><IconSee /></IconButton>,
            pointer: 1
        },
        {
            icono: <IconButton style={{ background: '#494377', width: '30px', height: '30px', padding: '6px', marginRight: '4px', blockSize: '30px' }}><IconEdit /></IconButton>,
            icono2: <IconButton style={{ background: '#494377', width: '30px', height: '30px', padding: '6px', marginRight: '4px', blockSize: '30px' }}><IconEdit /></IconButton>,
            pointer: 2
        },
        {
            icono: <IconButton style={{ background: '#494377', width: '30px', height: '30px', padding: '2px', marginRight: '4px', blockSize: '30px' }}><IconPadlock /></IconButton>,
            icono2: <IconButton style={{ background: '#494377', width: '30px', height: '30px', padding: '2px', marginRight: '4px', blockSize: '30px' }}><IconPadlockOpen /></IconButton>,
            pointer: 3
        },
        {
            icono: <IconButton style={{ background: '#494377', width: '30px', height: '30px', padding: '6px', marginRight: '4px', blockSize: '30px' }}><IconPassword /></IconButton>,
            icono2: <IconButton style={{ background: '#494377', width: '30px', height: '30px', padding: '6px', marginRight: '4px', blockSize: '30px' }}><IconPassword /></IconButton>,
            pointer: 4
        },
        {
            icono: <IconButton style={{ background: '#494377', width: '30px', height: '30px', padding: '6px', marginRight: '4px', blockSize: '30px' }}><IconDelete /></IconButton>,
            icono2: <IconButton style={{ background: '#494377', width: '30px', height: '30px', padding: '6px', marginRight: '4px', blockSize: '30px' }}><IconDelete /></IconButton>,
            pointer: 5,

        },
    ]

const rows = [
    createData('AdaLovelace@email.com1', 'Nombre 1', 'Apellido 1', 'Lorem Ipsum1', 'Venezuela1', icons, false),
    createData('AdaLovelace@email.com2', 'Nombre 2', 'Apellido 2', 'Lorem Ipsum2', 'Venezuela2', icons, true),
    createData('AdaLovelace@email.com3', 'Nombre 3', 'Apellido 3', 'Lorem Ipsum3', 'Venezuela3', icons, false),
    createData('AdaLovelace@email.com4', 'Nombre 4', 'Apellido 4', 'Lorem Ipsum4', 'Venezuela4', icons, true),
    createData('AdaLovelace@email.com5', 'Nombre 5', 'Apellido 5', 'Lorem Ipsum5', 'Venezuela5', icons, false),
    createData('AdaLovelace@email.com6', 'Nombre 6', 'Apellido 6', 'Lorem Ipsum6', 'Venezuela6', icons, true),
    createData('AdaLovelace@email.com7', 'Nombre 7', 'Apellido 7', 'Lorem Ipsum7', 'Venezuela7', icons, false),
    createData('AdaLovelace@email.com8', 'Nombre 8', 'Apellido 8', 'Lorem Ipsum8', 'Venezuela8', icons, false),
    createData('AdaLovelace@email.com9', 'Nombre 9', 'Apellido 9', 'Lorem Ipsum9', 'Venezuela9', icons, true),
];


// COMPONENTE TIPO CLASE
class UserManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            userDelete: '',
            response: rows,
            registerForPage: properties.totalRegisterForPagination,
            indexOfFirstUser: 0,
            indexOfLastUser: properties.totalRegisterForPagination,
        }
    }

    SearchTable = (event) => {
        // Declare variables 
        var value = event.target.value;
        this.setState({ value: value })
        var input, filter, table, tr, td, i, j, visible;
        input = document.getElementById("inputSearch");
        filter = input.value.toUpperCase();
        table = document.getElementById("tableBody");
        tr = table.getElementsByTagName("tr");

        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
            visible = false;
            /* Obtenemos todas las celdas de la fila, no sólo la primera */
            td = tr[i].getElementsByTagName("td");
            for (j = 0; j < td.length; j++) {
                if (td[j] && td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
                    visible = true;
                }
            }
            if (visible === true) {
                tr[i].style.display = "";
            } else {
                tr[i].style.display = "none";
            }
        }
    }

    pagination = (object, page) => {
        let limitLast = page * this.state.registerForPage;
        let limitFirst = limitLast - this.state.registerForPage;
        this.setState({ indexOfFirstUser: limitFirst, indexOfLastUser: limitLast })
    }

    actions = (event, id, email, name, surname, userType, country, indice, idUser, is_locked_out) => {
        switch (id) {
            case 1: //View user
                event.preventDefault();
                this.props.getUserById(idUser, true)
                break;
            case 2: //Edit user
                event.preventDefault();
                this.props.getUserById(idUser, false)
                break;
            case 3:
                this.openCloseLock(email, is_locked_out);
                break;
            case 4:
                this.props.openModalChangePasswordUser(true, email)
                break;
            case 5:
                this.setState({
                    userDelete: email
                })
                this.props.openModalRemoveUser(true)

                break;
            default:
                break;
        }
    }

    openCloseLock(email, is_locked_out) {
        let block;
        if (is_locked_out === 1) block = 0;
        if (is_locked_out === 0) block = 1;
        this.props.postBlockUser(email, block);
    }

    newUser = () => {
        this.props.openModalNewUser(true)
    }

    userSearch = () => {
        if(this.props.request){
            this.props.getUserSearch(null, true)
            this.props.loader(false)
        }else{
            if(this.state.value !== ''){
                this.props.loader(true)
                this.props.getUserSearch(this.state.value, false)
            }else{
                this.setState({errorEmptyValue: true})
            }
        }
    }

    onValueChange = (event) => {
        var value = event.target.value;
        this.setState({ value: value,  errorEmptyValue: false})
    }


    render() {
        const { classes } = this.props;

        return (
            <>
                <Grid container>
                    <Grid item xs={12} sm={12} md={6} className={classes.titleTable}>
                        <Typography variant="h5">
                            Gestión de Usuarios
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <div className={classes.inline}>
                            <TextField
                                id="inputSearch"
                                onChange={(event) => this.onValueChange(event)}
                                variant='outlined'
                                margin='dense'
                                fullWidth
                                color="primary"
                                label="Buscar"
                                value={this.state.value}
                                InputProps={{
                                    endAdornment:
                                        <>
                                            {this.state.errorEmptyValue &&
                                                <InputAdornment>
                                                    <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                                </InputAdornment>
                                            }
                                        </>,
                                    form: {
                                        autocomplete: 'off',
                                    },
                                  }}
                            />
                            <Button
                                margin="dense"
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.buttons}
                                onClick={this.userSearch}
                            >
                                {this.props.request ?
                                    <>
                                        <strong className={classes.ButtonAndSpinner}>
                                                <CircularProgress size={25} style={{padding: "5%"}} color="white"/>
                                                <div style={{paddingLeft: "5%"}}>Cancelar</div>
                                        </strong>
                                    </>
                                    :
                                    <strong className={classes.textButton}>Buscar</strong>
                                }
                            </Button>
                        </div>
                        <strong style={{ fontSize: '10px' }}>*Para buscar, ingrese cualquiera de los siguientes criterios: email, nombre o apellido</strong>
                    </Grid>
                </Grid>
                <Grid container>
                    <Button
                        margin="dense"
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.buttons}
                    >
                        <strong className={classes.textButton} onClick={this.newUser}>Nuevo</strong>
                    </Button>
                </Grid>
                <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {/* <TableCell align="center"><strong>ID</strong></TableCell> */}
                                <TableCell align="center"><strong>Usuario (email)</strong></TableCell>
                                <TableCell align="center"><strong>Nombre y Apellido</strong></TableCell>
                                <TableCell align="center"><strong>Acciones</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody id='tableBody'>
                            {this.props.allUsersObject ?
                                this.props.allUsersObject.slice(this.state.indexOfFirstUser, this.state.indexOfLastUser).map((element, index) => (
                                    <>
                                        <TableRow key={element.email}>
                                            <TableCell align="center">{element.email}</TableCell>
                                            <TableCell align="center">{element.name} {element.lastname}</TableCell>
                                            <TableCell align="center">
                                                {icons.map((icon) => (
                                                    <>
                                                        {element.is_locked_out === 0 ?
                                                            <a onClick={(event) => this.actions(event, icon.pointer, element.email, element.name, element.lastname, element.userType, element.country, index, element.user_id, element.is_locked_out)}>{icon.icono2}</a>
                                                            :
                                                            <a onClick={(event) => this.actions(event, icon.pointer, element.email, element.name, element.lastname, element.userType, element.country, index, element.user_id, element.is_locked_out)}>{icon.icono}</a>
                                                        }
                                                    </>
                                                ))}
                                            </TableCell>
                                        </TableRow>
                                    </>
                                ))
                                :
                                <TableRow key="No se encontraron usuarios"></TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {this.props.allUsersObject && this.props.allUsersObject.length > 0 &&
                    <div className={classes.pagination}>
                        {this.props.totalPagePagination &&
                            <Typography style={{textAlign: 'right'}}>{(this.state.indexOfFirstUser+1) + "-" + (this.state.indexOfLastUser > this.props.allUsersObject.length ? this.props.allUsersObject.length : this.state.indexOfLastUser ) + " de " + this.props.allUsersObject.length +" Registros"}</Typography>
                        }

                        <Pagination onChange={this.pagination} count={this.props.totalPagePagination} variant="outlined" shape="rounded" showFirstButton showLastButton />
                    </div>
                }

                {/* IMPRESION DE MODALES */}
                {this.props.newUserModal && <NewUser />}
                {this.props.viewUserModal && <ViewUser />}  
                {this.props.userEmailChangePassword && <ChangePasswordUser />}
                {this.props.removeUserModal && <RemoveUser email={this.state.userDelete} />}
            </>
        );
    }
}

const userManagement = withStyles(styles, { withTheme: true })(UserManagement);

function mapStateToProps(state) {

    return {
        newUserModal: state.userManagement.newUserModal,
        viewUserModal: state.userManagement.viewUserModal,
        userName: state.userManagement.userName,
        userSurname: state.userManagement.userSurname,
        userType: state.userManagement.userType,
        userCountry: state.userManagement.userCountry,
        userEmailChangePassword: state.userManagement.userEmailChangePassword,
        removeUserModal: state.userManagement.removeUserModal,
        allUsersObject: state.getAllUsers.allUsersObject,
        totalPagePagination: state.getAllUsers.totalPagePagination,
        userById: state.getUserById.userById,
        request: state.loader.loaderPayload
    };
}

export default connect(
    mapStateToProps,
    {
        openModalNewUser,
        openModalViewUser,
        openModalChangePasswordUser,
        openModalRemoveUser,
        getUserById,
        getTypeUser,
        getCountries,
        postBlockUser,
        getUserSearch,
        loader
    }
)(userManagement);