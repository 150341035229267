// // DEPENDENCIAS
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import Collapse from '@material-ui/core/Collapse';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertTitle } from '@material-ui/lab';
import { withStyles } from "@material-ui/core/styles";
import ReCAPTCHA from "react-google-recaptcha";
import { styles } from './constants';
import { LOGINFORM, REGISTERFORM, RECOVERYFORM, resetInputs } from './constants';

// ACCIONES
import { postLogin } from '../../Actions/Services/postLogin';
import { postRememberPassword } from '../../Actions/Services/postRememberPassword';
import { getTypeUser } from '../../Actions/Services/getTypeUser';
import { getCountries } from '../../Actions/Services/getCountries';
import { postPublicUserRegister } from '../../Actions/Services/postPublicUserRegister';
import { showErrorModal } from '../../Actions/_actionShowErrorModal';
import { loader } from '../../Actions/_actionLoader';

// COMPONENTES
import ModalAlert from '../ModalAlert'

// CONSTANTES
const style = styles;
const typeUserCombo = JSON.parse(localStorage.getItem('typeUserCombo'))
const properties = JSON.parse(localStorage.getItem('properties'));
const recaptchaRef = React.createRef();

// COMPONENTE TIPO CLASE //
class Login extends Component {
  constructor(props) {
    super(props);
    this.childDiv = React.createRef()
    this.state = {
      helpText: "*Inválido*",
      json: LOGINFORM,
      emptyInputsArray: [],
      loginStat: false,
      name: '',
      surname: '',
      email: '',
      password: '',
      emailR: '',
      aux: 0,
      check: false,
      recaptchaResponse: null
    }
  }

  // FUNCIONES //
  componentDidMount() {
    this.props.loader(true)
  }

  validateRecaptchaAndFields = () => {
    if(recaptchaRef.current.getValue() !== "" && this.state.validationsFieldsAux === true){
      this.props.loader(false)
    }else{
      this.props.loader(true)
    }
  }

  // componentDidUpdate () {
  //   if(this.props.response && this.state.emailR != ''){
  //     this.resetForm()
  //   }    
  // }

  handleSubmit = (event, pointer) => {
    switch (pointer) {
      case '1': //BOTON "INGRESAR" JSON LOGINFORM
        event.preventDefault();
        this.props.showErrorModal(false, '')
        this.props.loader(true)
        this.props.postLogin(this.state.email, this.state.password);
        break;
      case '2': //BOTON "RECUPERAR" JSON RECOVERYFORM
        event.preventDefault();
        this.props.showErrorModal(false, '')
        this.props.loader(true)
        this.props.postRememberPassword(this.state.emailRecovery, null)
        break;
      case '3': //BOTON "REGISTRAR" JSON REGISTERFORM
        event.preventDefault();
        this.props.showErrorModal(false, '')
        this.props.loader(true)
        this.props.postPublicUserRegister(this.state.name, this.state.surname, this.state.emailR, this.state.passwordR, this.state.userType, this.state.country, 'public');
        break;
      case '4': //BOTON "VOLVER" PARA PINTAR FORMULARIO LOGIN
        event.preventDefault();
        this.props.loader(true)
        this.props.showErrorModal(false, '')
        this.setState(resetInputs);
        this.setState({
          json: LOGINFORM,
        });
        break;
      case '5': //BOTON "RECUPERAR CLAVE AQUI" PARA PINTAR FORMULARIO RECUPERAR CONTRASEÑA
        this.props.loader(true)
        this.props.showErrorModal(false, '')
        this.setState(resetInputs);
        this.setState({
          json: RECOVERYFORM,
        });
        break;
      case '6': //BOTON "CREA TU CUENTA AQUI" PARA PINTAR FORMULARIO DE REGISTRO
        this.props.loader(true)
        this.props.getTypeUser()
        this.props.getCountries()
        this.props.showErrorModal(false, '')
        this.setState(resetInputs);
        this.setState({
          json: REGISTERFORM,
        });
        break;

      default:
        break;
    }
  }

  onChangeValues = (event) => {
    var value = event.target.value;
    var state = event.target.name;
    this.setState({
      [state]: value,
    })

    this.validate(value, state)
  }

  validate = (value, name) => {
    if (this.state.json === LOGINFORM) {
      this.setState({ ['newErrorInput' + name]: true })
      switch (name) {
        case 'email':
          this.validateWhitPattern(value, name, 0)
          break;
        case 'password':
          this.validateWhitPattern(value, name, 1)
          break;
        default:
          break;
      }
    }
    if (this.state.json === RECOVERYFORM) {
      this.setState({ ['newErrorInput' + name]: true })
      switch (name) {
        case 'emailRecovery':
          this.validateWhitPattern(value, name, 0)
          break;
        default:
          break;
      }
    }
    if (this.state.json === REGISTERFORM) {
      this.setState({ ['newErrorInput' + name]: true })
      switch (name) {
        case 'name':
          this.validateWhitPattern(value, name, 0)
          break;
        case 'surname':
          this.validateWhitPattern(value, name, 1)
          break;
        case 'emailR':
          this.validateWhitPattern(value, name, 2)
          break;
        case 'userType':
          this.validateWhitPattern(value, name, 3)
          break;
        case 'passwordR':
          this.validateWhitPattern(value, name, 4)
          break;
        case 'confirmPassword':
          this.validateWhitPattern(value, name, 5)
          break;
        case 'country':
          this.validateWhitPattern(value, name, 6)
          break;
        default:
          break;
      }
    }
  }

  validateWhitPattern = (value, name, indexOfInput) => {
    let pattern = this.state.json.inputs[indexOfInput].pattern
    if (pattern.test(value)) {
      this.setState({
        ['bool' + name]: true
      },
        () => {
          let allInputsAux = true
          this.state.json.inputs.map((element) => {
            if (this.state['bool' + element.name] !== true) {
              allInputsAux = false
            }
          })

          if (this.state.passwordR !== this.state.confirmPassword) {
            allInputsAux = false
            this.props.loader(true)
            this.setState({ boolconfirmPassword: false })
          } else this.setState({ boolconfirmPassword: true })

          if (allInputsAux === true) {
            if(this.state.json === REGISTERFORM || this.state.json === RECOVERYFORM){
              // if(recaptchaRef.current.getValue() !== ""){
                // this.props.loader(false)
                this.setState({validationsFieldsAux: true})
                this.validateRecaptchaAndFields()
              // }
            }else{
              this.props.loader(false)
            }
          }
        })
    } else {
      this.setState({
        ['bool' + name]: false
      },
        () => {
          this.state.json.inputs.map((element) => {
            if (this.state['bool' + element.name] === false) {
              this.props.loader(true)
            }
          })
        })
    }
  }

  closeMessageError = () => {
    this.props.showErrorModal(false, '')
  }

  resetForm = () => {

    var array = [
      "name",
      "surname",
      "emailR",
      "userType",
      "passwordR",
      "confirmPassword",
      "country",
      "check"
    ]

    array.map(state => {
      this.setState({
        [state]: '',
        ["newErrorInput" + state]: false
      })
    })

  }

  handleChange = () => {
    this.setState({
      check: !this.state.check
    })
  }

  // RENDERIZADO //
  render() {
    const { classes } = this.props;

    return (
      <>
        {this.props.response !== null && this.props.response == 0 && this.state.emailR !== '' ? this.resetForm() : <></>}

        <Grid container component="main" className={classes.root} >
          {this.state.json &&
            <>

              {/* <Grid item xs={12} sm={12} md={12} className={classes.image} style={{ backgroundImage: `url(${this.state.json.background})` }}> */}
              <Grid item xs={12} sm={12} md={12} className={classes.image} style={{ backgroundColor: '#efefef' }}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} className={classes.centered}>
                    <img src={this.state.json.logo} style={this.state.json.logoStyles} />
                  </Grid>
                </Grid>
                <Grid item className={classes.centered}>
                  <Grid item xs={0} sm={4}></Grid>
                  <Grid item xs={12} sm={12} md={4} component={Paper} square className={classes.gridPaper}>
                    <div className={classes.paper}>
                      <Typography component="h1" variant="h4" className={classes.title}>
                        {this.state.json.title}
                      </Typography>
                      <form id="publicForm" className={classes.form} noValidate>
                        <Collapse in={this.props.showModal}>
                          <Alert
                            className={classes.errorMessage}
                            severity={this.props.response !== null && this.props.response == 0 ? 'success' : 'error'}
                            fullWidth
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={this.closeMessageError}
                              >
                                {this.props.response !== null && this.props.response == 0 ?
                                  <CheckIcon fontSize="inherit" style={{color: 'green'}} />
                                  :
                                  <CloseIcon fontSize="inherit" style={{color: 'red'}}/>
                                }
                              </IconButton>
                            }
                          >
                            {this.props.errorModal}
                          </Alert>
                        </Collapse>
                        <Grid container direction="row" spacing={1} >
                          {this.state.json.inputs.map((element) => (
                            <>
                              {element.type != "select" ?
                                <Grid item xs={element.xs} md={element.md}>
                             
                                  <Tooltip
                                    title={<p style={{fontSize:12}}>{element.format}</p>}
                                    open={ element.tooltip}
                                    placement="right-start"
                                  >
                                    <TextField
                                      margin="dense"
                                      fullWidth                                      
                                      autoFocus={element.autoFocus}
                                      color="secondary"
                                      label={element.label}
                                      id={element.name}
                                      name={element.name}
                                      type={element.type}
                                      onChange={this.onChangeValues}
                                      value={
                                        this.state[element.name] !== undefined || this.state[element.name] !== ''
                                          ?
                                          this.state[element.name]
                                          :
                                          ''
                                      }
                                      InputProps={{
                                        endAdornment:
                                          <>
                                            {this.state['newErrorInput' + element.name] &&
                                              <>
                                                {this.state['bool' + element.name] ?
                                                  <InputAdornment>
                                                    <CheckIcon style={{ marginRight: '6px', color: 'green' }} />
                                                  </InputAdornment>
                                                  :
                                                  <InputAdornment>
                                                    <CloseIcon style={{ marginRight: '6px', color: 'red' }} />
                                                  </InputAdornment>
                                                }
                                              </>
                                            }

                                          </>,
                                         form: {
                                          autocomplete: 'off',
                                        },
                                      }}
                                    />
                                  </Tooltip>
                                </Grid>
                                :
                                <FormControl className={classes.select}>
                                  <InputLabel>{element.label}</InputLabel>
                                  <Select
                                    color="secondary"
                                    name={element.name}
                                    value={this.state[element.name]}
                                    onChange={this.onChangeValues}
                                  >
                                    {this.props.typeUser && element.name === "userType" &&
                                      this.props.typeUser.map((type) => (
                                        type.es_publico == 1 &&
                                        <MenuItem value={type.nombre_rol}>{type.nombre_rol}</MenuItem>
                                      ))
                                    }
                                    {this.props.getCountriesCombo && element.name === "country" &&
                                      this.props.getCountriesCombo.map((element) => (
                                        <MenuItem value={element.nombre_pais}>{element.nombre_pais}</MenuItem>
                                      ))}
                                  </Select>
                                </FormControl>
                              }
                            </>
                          ))}
                        </Grid>
                        {this.state.json.id === 3 &&
                          <>
                            <FormControlLabel
                              name="check"
                              control={<Checkbox checked={this.state.check} onChange={this.handleChange} name="check" color="secondary" />}
                              label={<p style={{ fontSize: "0.75rem" }}>¿Quieres recibir nuestro boletín?</p>}
                            />


                            {this.state.json.headerLinks.map((element) => (
                              <Grid item xs>
                                <Typography variant="body2" style={{ fontSize: "0.75rem" }}>
                                  {element.label} <strong>{element.link}</strong>
                                </Typography>
                              </Grid>
                            ))}
                          </>
                        }
                        <div className={classes.centered}>
                          {this.state.json.reCaptcha === 1 &&
                            <ReCAPTCHA
                              style={{ marginTop: 10, marginBottom: -10, contentAling: 'center' }}
                              sitekey={properties.reCAPTCHA_KEY} //produccion
                              // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" //localhost
                              ref={recaptchaRef}
                              onChange={this.validateRecaptchaAndFields}
                            />
                          }
                        </div>
                        <Grid container>
                          {this.state.json.button.map((element) => (
                            <Grid item xs={element.xs} sm={element.sm} md={element.md}>
                              <div className={classes.alignButton}>
                                <Button
                                  margin="dense"
                                  type="submit"
                                  disabled={element.pointer === '1' ? this.props.loaderPayload : element.pointer === '2' ? this.props.loaderPayload : element.pointer === '3' && this.props.loaderPayload}
                                  variant="contained"
                                  color="secondary"
                                  className={classes.button}
                                  onClick={(event) => this.handleSubmit(event, element.pointer)}
                                >
                                  <strong className={classes.textButton}>{element.name}</strong>
                                </Button>
                              </div>
                            </Grid>
                          ))}
                        </Grid>
                        {this.state.json.links.length > 0 && this.state.json.links.map((element) => (
                          <div style={{ marginBottom: '10px', textAlign: 'center', }}>
                            <Grid item xs>
                              <Typography variant="body2" >
                                {element.label} <a onClick={(event) => this.handleSubmit(event, element.id)}>
                                  <strong style={{ cursor: 'pointer' }}>{element.link}</strong></a>
                              </Typography>
                            </Grid>
                          </div>
                        ))}
                      </form>
                    </div>
                  </Grid>
                  <Grid item xs={0} sm={4}></Grid>
                </Grid>
              </Grid>
            </>
          }
        </Grid>

        {this.state.showModal &&
          <>
            <ModalAlert></ModalAlert>
          </>
        }

      </>
    );
  }
}

const login = withStyles(styles, { withTheme: true })(Login);

function mapStateToProps(state) {

  return {
    passwordRemember: state.rememberPass.passwordRemember,
    loginStatus: state.login.loginStatus,
    typeUser: state.userType.typeUser,
    getCountriesCombo: state.getCountries.getCountriesCombo,
    showModal: state.errorShowModal.showModal,
    errorModal: state.errorShowModal.errorModal,
    response: state.errorShowModal.statusResponse,
    loaderPayload: state.loader.loaderPayload
  };
}

export default connect(
  mapStateToProps,
  {
    postLogin,
    postRememberPassword,
    getTypeUser,
    getCountries,
    postPublicUserRegister,
    showErrorModal,
    loader
  }
)(login);