import { LOGIN } from '../../Constants';

export const login = (state = {} ,action) => {
    switch (action.type) {
        case LOGIN:
            return {...state, loginStatus: action.payload}  
    
        default:
            return state;
    }
}