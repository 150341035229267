
const drawerWidth = 240;

export const styles = theme => ({
    body: {
        backgroundColor: 'white !important',
        
    },
    root: {
        display: 'flex',
        position: 'relative'
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${275}px)`,
            margin: theme.spacing(1, 2, 1, 1),
        },
    },
    menuButton: {
        marginRight: theme.spacing(0),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    blockDisplay: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    menuIcon: {
        marginRight: theme.spacing(1.5),
        height: '20px',
        width: '20px',
    
    },
    logo: {
        height: '80px',
        marginTop: '-50px',
        marginBottom: '50px'
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rootContent: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        marginTop: 15,
    },
    papericons: {
        padding: theme.spacing(2),
        // marginTop: 60,
        backgroundColor: '#fafafa',
    },
    organizacionesImg:{
        width: 50,
        height: 40,
        marginBottom: 10,
    },
    volunatariosImg:{
        width: 65,
        height: 40,
        marginBottom: 10,
    },

    labelicons2: {
        position: "absolute",
        marginLeft: 8,
        marginTop:-10,
        fontSize: 18,
        color: '#707070',
    },
    number: {
        color: '#707070',
        // position: 'absolute',
        fontSize: 38,
        marginLeft: 5,
        // marginTop: -5,
        // marginBottom:10
    },
    printIcon: {
        // float: 'right',
        marginTop: 18,
        marginLeft: 8,
        width: 30,
        height: 30,
    },
    datecont1: {
        border: '1px solid #707070',
        width: 150,
        height: 22,
        borderRadius: 5,
        marginBottom: 8,
    },
    datecont2: {
        border: '1px solid #707070',
        width: 100,
        height: 22,
        borderRadius: 5,
    },
    date:{
        marginLeft: 17,
    },
    calendar:{
        marginLeft: 8,
        marginTop: 2,
    },
})
