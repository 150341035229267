import axios from 'axios'
import { ORGANIZATION_BY_USER } from '../../Constants';
import {showErrorModal} from '../../Actions/_actionShowErrorModal'

export function getOrganizationBeneficiaries() {
    
    return dispath => {
        let properties = JSON.parse(localStorage.getItem('properties'));
        let responseLogin = JSON.parse(localStorage.getItem('responseLogin'))
        let Authorization = localStorage.getItem('Authorization');
        let ORGANIZATION_BENEFICIARIES_ENDPOINT = properties.ENPOINTS.ORGANIZATION_BENEFICIARIES_ENDPOINT;
        let loginInfo = responseLogin.data.data
        axios({
            method: 'GET',
            url: ORGANIZATION_BENEFICIARIES_ENDPOINT,
            headers: {
                "Authorization": "Bearer " + Authorization
            }
        }).then((response) => {
            localStorage.setItem('organizationBeneficiaries', JSON.stringify(response.data.data));
        }).catch(error => {
            let message = undefined
            if(error.response.data.code){
                properties.errorMessage.map((element) => {
                    if(element.value === error.response.data.code){
                        message = error.response.data.code
                    }
                })
            }
            dispath(showErrorModal(true, message))
        });
    }
}
