import { createMuiTheme } from '@material-ui/core/styles';

export const NOMBRE_DE_LA_CONTSTANTE1 = "NOMBRE_DE_LA_CONTSTANTE1"; //ESTE ES UN EJEMPLO

export const NOMBRE_DE_LA_CONTSTANTE2 = "NOMBRE_DE_LA_CONTSTANTE2"; //ESTE ES UN EJEMPLO

export const NOMBRE_DE_LA_CONTSTANTE3 = "NOMBRE_DE_LA_CONTSTANTE3"; //ESTE ES UN EJEMPLO

export const OPEN_MODAL_CHANGE_PASSWORD = "OPEN_MODAL_CHANGE_PASSWORD";

export const OPEN_MODAL_NEW_USER = "OPEN_MODAL_NEW_USER";

export const OPEN_MODAL_VIEW_USER = "OPEN_MODAL_VIEW_USER";

export const OPEN_MODAL_CHANGE_PASSWORD_USER = "OPEN_MODAL_CHANGE_PASSWORD_USER";

export const OPEN_MODAL_REMOVE_USER = "OPEN_MODAL_REMOVE_USER";

export const ORGANIZATION_DASH = "ORGANIZATION_DASH";

export const WIZARD_ADD_ORGANIZATION = "WIZARD_ADD_ORGANIZATION";

export const RESET_ALL = "RESET_ALL";

export const LOGIN = "LOGIN";

export const REMEMBER_PASSWORD = "REMEMBER_PASSWORD";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

export const USERTYPE_COMBO = "USERTYPE_COMBO";

export const PUBLICUSER_REGISTER = "PUBLICUSER_REGISTER";

export const COUNTRIES_COMBO = "COUNTRIES_COMBO";

export const BLOCK_USER = "BLOCK_USER";

export const SHOW_ERROR_MODAL = "SHOW_ERROR_MODAL";

export const ALL_USERS = "ALL_USERS";

export const LOADER = "LOADER";

export const CIRCULAR_PROGRESS = "CIRCULAR_PROGRESS";

export const USER_BY_ID = "USER_BY_ID";

export const ORGANIZATION_BY_USER = "ORGANIZATION_BY_USER";

export const UPLOADIMAGE_MYORGANIZATION = "UPLOADIMAGE_MYORGANIZATION";

export const UPLOADIMAGE_DIRECTION = "UPLOADIMAGE_DIRECTION";

export const OPEN_MODAL_DELETE_ORGANIZATION = "OPEN_MODAL_DELETE_ORGANIZATION";

export const SPINNER_MYORGANIZATION = "SPINNER_MYORGANIZATION";

export const SPINNER_DIRECTION = "SPINNER_DIRECTION";

export const MESSAGES_USER = "MESSAGES_USER";

export const SAVING_JSONTABLES = "SAVING_JSONTABLES";

export const DASHBOARD = "DASHBOARD";

export const MIUER_THEME = createMuiTheme({

  palette: {
    primary: {
      main: '#494377',
    },
    secondary: {
      main: '#ec008c',
    },
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  }

});