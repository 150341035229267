import { combineReducers } from 'redux'
import { ejemplo } from './_reducerEjemplo';
import {RESET_ALL} from '../Constants'

//REDUCERS
import { dashboard } from '../Reducers/_reducerDashboard'
import { userManagement } from '../Reducers/_reducerUserManagement'
import { rememberPass } from '../Reducers/Services/postRememberPassword'
import { login } from '../Reducers/Services/postLogin'
import { changePass } from '../Reducers/Services/putChangePassword'
import { userType } from '../Reducers/Services/getTypeUser'
import { getCountries } from '../Reducers/Services/getCountries'
import { errorShowModal } from '../Reducers/_reducerShowErrorModal'
import { getAllUsers } from '../Reducers/Services/getAllUsers'
import { loader } from '../Reducers/_reducerLoader'
import { progressCircular } from '../Reducers/_reducerCircularProgress'
import { getUserById } from '../Reducers/Services/getViewUserById'
import { getOrganizationByUserReducer } from '../Reducers/Services/getOrganizationByUser'
import { showUploadImageMyOrganization } from '../Reducers/_reducerUploadImage'
import { organizationDash } from '../Reducers/_reducerOrganizationDash'
import { wizardProps } from '../Reducers/_reducerWizardProps'
import { getMessages } from '../Reducers/Services/getMessageById'
import { saveForms } from '../Reducers/_reducerSavingJsonTables'


const appReducer = combineReducers({
    ejemplo,
    dashboard,
    userManagement,
    rememberPass,
    login,
    changePass,
    userType,
    getCountries,
    errorShowModal,
    getAllUsers,
    loader,
    progressCircular,
    getUserById,
    getOrganizationByUserReducer,
    showUploadImageMyOrganization,
    organizationDash,
    wizardProps,
    getMessages,
    saveForms,
})

const resetState = combineReducers({
    ejemplo,
    dashboard,
    userManagement,
    rememberPass,
    login,
    changePass,
    userType,
    getCountries,
    errorShowModal,
    getAllUsers,
    loader,
    progressCircular,
    getUserById,
    getOrganizationByUserReducer,
    showUploadImageMyOrganization,
    organizationDash,
    wizardProps,
    getMessages,
    saveForms,
})


const rootReducer = (state, action) => {
    if(action.type === RESET_ALL){
        state = undefined;
        return resetState(state, action)
    }else {
        return appReducer(state, action)
    }
}
export default rootReducer;