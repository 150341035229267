import { COUNTRIES_COMBO } from '../../Constants';

export const getCountries = (state = {} ,action) => {
    switch (action.type) {
        case COUNTRIES_COMBO:
            return {...state, 
                payload: action.payload,
                getCountriesCombo: action.countries,
            }
    
        default:
            return state;
    }
}