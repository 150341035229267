// DEPENDENCIAS
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { styles } from './constants';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
// ACTIONS
import { deleteOrganization } from '../../../../Actions/Services/deleteOrganization';
import { openModalDeleteOrganization } from '../../../../Actions/_actionOrganizationDash';

const properties = JSON.parse(localStorage.getItem('properties'));


class ModalDeleteOrganization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
        }
    }

    closeModal = (option) => {
        if (option) {
            this.props.deleteOrganization(this.props.id_organization)
        }
        this.props.openModalDeleteOrganization(false, null)
        
    };

    render() {

        const { classes } = this.props;

        return (
            <>
                <Dialog
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="xs"
                >
                    <div className={classes.Xclose} onClick={this.closeModal}>
                        <ClearIcon ></ClearIcon>
                    </div>
                    <DialogTitle id="alert-dialog-title" className={classes.title}>
                        <stron>Borrar organización</stron>
                    </DialogTitle>
                    <div className={classes.warning}>
                        <ReportProblemOutlinedIcon
                            className={classes.icon}
                        />
                    </div>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Grid container direction="row" spacing={1} >
                                <Grid item xs={12} md={12}>
                                    <Typography className={classes.typografia}>
                                        <strong>{properties.messages.deleteOrganizationConfirmation}</strong>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div className={classes.alignButton}>
                            <Button
                                margin="dense"
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={() => (this.closeModal(false))}
                            >
                                <strong>Cerrar</strong>
                            </Button>
                        </div>
                        <div className={classes.alignButton}>
                            <Button
                                margin="dense"
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={() => (this.closeModal(true))}
                            >
                                <strong>Borrar</strong>
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

const modalDeleteOrganization = withStyles(styles, { withTheme: true })(ModalDeleteOrganization);

function mapStateToProps(state) {
    return {
        id_organization: state.organizationDash.id_organization
    };
}

export default connect(
    mapStateToProps,
    {
        deleteOrganization,
        openModalDeleteOrganization,
    }
)(modalDeleteOrganization);