// DEPENDENCIAS
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, withProps } from "recompose"
// import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import IconImg from './img/img.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Autocomplete } from '@material-ui/lab';
import { withStyles } from "@material-ui/core/styles";
import { styles } from './constants';

import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'

//ACCIONES
import { postUploadImage } from '../../../../../Actions/Services/postUploadImage'
import { spinnerImageDirection } from '../../../../../Actions/_actionWizardProps'
import { uploadImageDirection } from '../../../../../Actions/_actionUploadImage'

// CONSTANTES
const style = styles;

// COMPONENTE TIPO CLASE
class DirectionData extends Component {

    constructor(props) {
        super(props);
        this.state = {
          value: '',
        //   personName: ([]),
          latitud: 10.491,
          longitud: -66.902,
          checked: null,
          ThematicArea: [],
          GeographicalScope: [],
          CountryOperation: [],
          PubliGoal: [],
          Length: '',
          Latitude: '',
          CentralPhone: '',
          OriginId: '',
          OficceNumber: '',
          TypeOng: '',
          ObjetiveDev: [],
          NetworkBelongs: [],
          Personalities: [],
          checkbox: null,
          checkbox2: null,
          jsonDirectionData: [],
        }
      }

    componentDidMount = () => {

        let jsonDirectionData = JSON.parse(localStorage.getItem('jsonDirectionData'));        
        
        this.setState({
            areaThematicList: JSON.parse(localStorage.getItem('areaOfInterest')),
            countries: JSON.parse(localStorage.getItem('countries')),
            regionCountry: JSON.parse(localStorage.getItem('regionCountry')),
            ObjetiveDevList: JSON.parse(localStorage.getItem('organizationObjectives')),
            PubliGoalList: JSON.parse(localStorage.getItem('organizationBeneficiaries')),
            networkbelongsStorage: JSON.parse(localStorage.getItem('NetworkBelongs'))
        })

        if(jsonDirectionData){
            this.setState({
                ThematicArea: jsonDirectionData[0].ThematicArea,
                GeographicalScope: jsonDirectionData[0].GeographicalScope,
                CountryOperation: jsonDirectionData[0].CountryOperation,
                PubliGoal: jsonDirectionData[0].PubliGoal,
                Length: jsonDirectionData[0].Length,
                Latitude: jsonDirectionData[0].Latitude,
                CentralPhone: jsonDirectionData[0].CentralPhone,
                // OriginId: jsonDirectionData[0].OriginId,
                OficceNumber: jsonDirectionData[0].OficceNumber,
                // TypeOng: jsonDirectionData[0].TypeOng,
                ObjetiveDev: jsonDirectionData[0].ObjetiveDev,
                NetworkBelongs: jsonDirectionData[0].NetworkBelongs,
                // // Personalities: jsonDirectionData[0].Personalities,
                checkbox: jsonDirectionData[0].checkbox,
                checkbox2: jsonDirectionData[0].checkbox2,
            })
        }
    }

    componentWillUnmount = () => {

        this.state.jsonDirectionData.push({
            "ThematicArea": this.state.ThematicArea,
            "GeographicalScope": this.state.GeographicalScope,
            "CountryOperation": this.state.CountryOperation,
            "PubliGoal": this.state.PubliGoal,
            "Length": this.state.Length,
            "Latitude": this.state.Latitude,
            "CentralPhone": this.state.CentralPhone,
            // "OriginId": this.state.OriginId,
            "OficceNumber": this.state.OficceNumber,
            // "TypeOng": this.state.TypeOng,
            "ObjetiveDev": this.state.ObjetiveDev,
            "NetworkBelongs": this.state.NetworkBelongs,
            // "Personalities": this.state.Personalities,
            "checkbox": this.state.checkbox,
            "checkbox2": this.state.checkbox2,
        })
        localStorage.setItem('jsonDirectionData', JSON.stringify(this.state.jsonDirectionData))
      }
      
    onChangeValues = (event) => {
        var value = event.target.value;
        var state = event.target.name;
        var checked = event.target.checked
        if(state === 'CentralPhone' || state === 'OficceNumber'){
            if(/^([+0-9]*)$/.test(value)){
                this.setState({[state]: value})
            }
        }else{
            this.setState({
                [state]: value,
            })
        }
        if(state === "checkbox" || state === "checkbox2"){
            this.setState({
                [state]: checked,
            })
        }
        if(state === "Length"){
            if(/^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/.test(value)){
                this.setState({
                    [state]: value,
                })
            }
        }
        if(state === "Latitude"){
            if(/^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/.test(value)){
                this.setState({
                    [state]: value,
                })
            }
        }
    }

    onChangeAutocomplete = (event, value, name) => {
        this.setState({
            [name]: value,
        })        

    }

    handleChange = (event) => {
        this.setState({ checked: !event.target.checked})
    };    

    upLoadImage = (event) => {   
        this.props.spinnerImageDirection(true)
        let file = event.target.files[0];
        this.setState({file: file}, () => {
            let file = this.state.file
            let formData = new FormData()
            formData.append('image', file);
            this.props.postUploadImage(formData, 'imgDirection')
        })
    }

    changeImage = (event) => {
        this.props.uploadImageDirection(false, null)
        localStorage.removeItem('imgMisOrganizaciones');
    }

    render() {
        const { classes } = this.props;
        const position = [this.state.Latitude, this.state.Length];

        return (
            <>
                <Grid container className={classes.root}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                                Enfoque Geográfico y Temático
                        </Typography>
                        <hr/>
                    </Grid>
                    <Grid container className={classes.gridsContainer}>
                        <Grid item xs={12} className={classes.forms}>
                            <Autocomplete
                                multiple
                                size="small"
                                className={classes.Autocomplete}
                                margin="dense"
                                id="tags-outlined"
                                onChange={(event, value) => this.onChangeAutocomplete(event, value, "ThematicArea")}
                                disabled={this.props.disable}
                                options={this.state.areaThematicList}
                                getOptionLabel={option => option.descripcion_areainteres}
                                value={this.state.ThematicArea}
                                filterSelectedOptions
                                ChipProps={{
                                    'color': 'primary'
                                }}
                                renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Área Temática"
                                    fullWidth
                                    color="primary"
                                />
                                )}
                            />
                            <Autocomplete
                                multiple
                                size="small"
                                id="tags-outlined"
                                className={classes.Autocomplete}
                                onChange={(event, value) => this.onChangeAutocomplete(event, value, "GeographicalScope")}
                                disabled={this.props.disable}
                                options={this.state.regionCountry}
                                getOptionLabel={option => option.nombre_pais}
                                value={this.state.GeographicalScope}
                                filterSelectedOptions
                                ChipProps={{
                                    'color': 'primary'
                                }}
                                renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Alcance Geográfico"
                                    fullWidth
                                />
                                )}
                            />
                            <Autocomplete
                                multiple
                                size="small"
                                id="tags-outlined"
                                className={classes.Autocomplete}
                                onChange={(event, value) => this.onChangeAutocomplete(event, value, "CountryOperation")}
                                disabled={this.props.disable}
                                options={this.state.countries}
                                getOptionLabel={option => option.nombre_pais}
                                value={this.state.CountryOperation}
                                filterSelectedOptions
                                ChipProps={{
                                    'color': 'primary'
                                }}
                                renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="País donde Opera"
                                    fullWidth
                                />
                                )}
                            />
                            <Autocomplete
                                multiple
                                size="small"
                                className={classes.Autocomplete}
                                margin="dense"
                                id="tags-outlined"
                                onChange={(event, value) => this.onChangeAutocomplete(event, value, "PubliGoal")}
                                disabled={this.props.disable}
                                options={this.state.PubliGoalList}
                                getOptionLabel={option => option.nombre_beneficiario}
                                value={this.state.PubliGoal}
                                filterSelectedOptions
                                ChipProps={{
                                    'color': 'primary'
                                }}
                                renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Público Meta"
                                    fullWidth
                                    color="primary"
                                />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.gridsContainer}>
                        <Typography variant="h6">
                                Indica donde será ejecutado el proyecto
                        </Typography>
                        <Grid container>
                            <Grid item xs={12} className={classes.forms}>
                                <Map center={[this.state.Latitude, this.state.Length]} zoom={8}
                                 style={{ width: '100%', height: '300px' }}
                                 >
                                    <TileLayer
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                                    />
                                    <Marker position={position}>
                                        <Popup>
                                            <span>A pretty CSS3 popup. <br/> Easily customizable.</span>
                                        </Popup>
                                    </Marker>
                                </Map>
                            </Grid>
                            <Grid item xs={6} sm={6}  md={6} className={classes.forms}>
                                <TextField                                    
                                    fullWidth
                                    label="Longitud"
                                    type="number"
                                    color="primary"
                                    name="Length"
                                    inputProps={{ readOnly: this.props.disable }}
                                    value={this.state.Length}
                                    onChange={this.onChangeValues}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}  md={6} className={classes.forms}>
                                <TextField                                    
                                    fullWidth
                                    label="Latitud"
                                    type="number"
                                    color="primary"
                                    name="Latitude"
                                    onChange={this.onChangeValues}
                                    inputProps={{ readOnly: this.props.disable }}
                                    value={this.state.Latitude}
                                    onChange={this.onChangeValues}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}  md={6} className={classes.forms}>
                                <TextField                                    
                                    fullWidth
                                    color="primary"
                                    placeholder="Ej: +581234567890 o 04121234567"
                                    label="Telefono Central"
                                    type="text"
                                    name="CentralPhone"
                                    inputProps={{ readOnly: this.props.disable }}
                                    value={this.state.CentralPhone}
                                    onChange={this.onChangeValues}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}  md={6} className={classes.forms}>
                                <TextField                                    
                                    fullWidth
                                    color="primary"
                                    placeholder="Ej: +581234567890 o 04121234567"
                                    label="Teléfono de oficina"
                                    type="text"
                                    name="OficceNumber"
                                    inputProps={{ readOnly: this.props.disable }}
                                    value={this.state.OficceNumber}
                                    onChange={this.onChangeValues}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.gridsContainer} >
                        <Typography variant="h6">
                                Información Adicional
                        </Typography>
                        <Grid container>
                            <Grid item xs={12} md={4} className={classes.forms}>
                                {this.props.showUploadImageDirection ?
                                    <>
                                        <img style={{ width: '100%', height: '100%' }} src={this.props.imageURLDirection} alt="Logo" />
                                        <Button style={{borderRadius: '0px'}} disabled={this.props.disable} onClick={this.changeImage} size="small" color="primary" variant="contained">Eliminar imagen</Button>
                                    </>
                                :
                                    <div className={classes.sectionFile}>
                                        {this.props.spinnerLoadDirection?
                                            <center>
                                                <CircularProgress style={{ marginTop: '10%' }}></CircularProgress>
                                            </center>
                                        :
                                            <center>
                                                <img src={IconImg} style={{ marginTop: '10%' }} alt="Logo" />
                                            </center>  
                                        }
                                        <center>
                                            <Button variant="contained" disabled={this.props.disable} className={classes.buttonFile} component="label" color="primary">                          
                                                    Cargar Icono para los mapas
                                                <input
                                                    type="file"
                                                    style={{ display: "none" }}
                                                    onChange={this.upLoadImage}
                                                />
                                            </Button>
                                        </center>
                                    </div>
                                }
                            </Grid>
                            <Grid item xs={12} sm={6}  md={8} className={classes.forms}>
                                <Autocomplete
                                    multiple
                                    size="small"
                                    className={classes.Autocomplete}
                                    margin="dense"
                                    id="tags-outlined"
                                    onChange={(event, value) => this.onChangeAutocomplete(event, value, "ObjetiveDev")}
                                    disabled={this.props.disable}
                                    options={this.state.ObjetiveDevList}
                                    getOptionLabel={option => option.descripcion_objetivo}
                                    value={this.state.ObjetiveDev}
                                    filterSelectedOptions
                                    ChipProps={{
                                        'color': 'primary'
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Objetivo de Desarrollo"
                                            fullWidth
                                            color="primary"
                                        />
                                    )}
                                />
                                 <Autocomplete
                                    multiple
                                    size="small"
                                    className={classes.Autocomplete}
                                    margin="dense"
                                    id="tags-outlined"
                                    onChange={(event, value) => this.onChangeAutocomplete(event, value, "NetworkBelongs")}
                                    disabled={this.props.disable}
                                    options={this.state.networkbelongsStorage}
                                    getOptionLabel={option => option.nombre_red}
                                    value={this.state.NetworkBelongs}
                                    filterSelectedOptions
                                    ChipProps={{
                                        'color': 'primary',
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Redes a las que Pertenece"
                                            fullWidth
                                            color="primary"
                                        />
                                    )}
                                />
                                <Grid item xs={12} sm={6}  md={6} >
                                    <div className={classes.checkbox}>
                                        <a style={{color: 'grey'}}>¿Mostrar Datos de Inversión?</a>
                                        <Checkbox
                                            color="primary"
                                            name="checkbox"
                                            disabled={this.props.disable}
                                            disabled={this.props.disable}
                                            checked={this.state.checkbox}
                                            onChange={this.onChangeValues}
                                        />
                                    </div>
                                </Grid>
                                    <Grid item xs={12} sm={6}  md={6} >
                                        <div className={classes.checkbox2}>
                                            <a style={{color: 'grey'}}>¿Es un Aliado de UER?</a>
                                            <Checkbox
                                                color="primary"
                                                name="checkbox2"
                                                disabled={this.props.disable}
                                                checked={this.state.checkbox2}
                                                onChange={this.onChangeValues}
                                            >
                                            </Checkbox>
                                        </div>
                                    </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const directionData = withStyles(styles, { withTheme: true })(DirectionData);

function mapStateToProps(state) {

    return {
        showUploadImageDirection: state.showUploadImageMyOrganization.showUploadImageDirection,
        imageURLDirection: state.showUploadImageMyOrganization.imageURLDirection,
        spinnerLoadDirection: state.wizardProps.spinnerLoadDirection,
        disable : state.dashboard.disableField
    };
}

export default connect(
    mapStateToProps,
    {
        postUploadImage,
        spinnerImageDirection,
        uploadImageDirection
    }
)(directionData);
