import { 
    OPEN_MODAL_NEW_USER,
    OPEN_MODAL_VIEW_USER,
    OPEN_MODAL_CHANGE_PASSWORD_USER,
    OPEN_MODAL_REMOVE_USER
 } from '../Constants'

export const openModalNewUser = (payload) => {

    return {
        type: OPEN_MODAL_NEW_USER,
        payload: payload,
    }
}

export const openModalViewUser = (payload, editOption, email, userName, userSurname, userType, userCountry) => {
    return {
        type: OPEN_MODAL_VIEW_USER,
        payload: payload,
        editOption: editOption,
        email: email,
        userName: userName,
        userSurname: userSurname,
        userType: userType,
        userCountry: userCountry
    }
}

export const openModalChangePasswordUser = (payload, email) => {
    return {
        type: OPEN_MODAL_CHANGE_PASSWORD_USER,
        payload: payload,
        email: email,
    }
}

export const openModalRemoveUser = (payload) => {
    return {
        type: OPEN_MODAL_REMOVE_USER,
        payload: payload,
    }
}


