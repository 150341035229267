import { SPINNER_MYORGANIZATION, SPINNER_DIRECTION  } from '../Constants'

export const wizardProps = (state = {} ,action) => {
    switch (action.type) {
        
        case SPINNER_MYORGANIZATION:
            return {...state, 
                spinnerLoadOrganization: action.payload,
            }   
        case SPINNER_DIRECTION:
            return {...state, 
                spinnerLoadDirection: action.payload,
            }   
        default:
            return state;
    }
}