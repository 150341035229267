
import axios from 'axios'
import { ORGANIZATION_BY_USER } from '../../Constants';
import { showErrorModal } from '../../Actions/_actionShowErrorModal'
import { wizardAddOrganization, organizationDash } from '../../Actions/_actionDashboard'
import { spinnerImageMyOrganization, spinnerImageDirection } from '../_actionWizardProps'
import { uploadImageMyOrganization, uploadImageDirection } from '../_actionUploadImage'

import { element } from 'prop-types';

export function getOrganizationById(id_organizacion) {

    return dispath => {
        let properties = JSON.parse(localStorage.getItem('properties'));
        let responseLogin = JSON.parse(localStorage.getItem('responseLogin'))
        let ListLov = JSON.parse(localStorage.getItem('ListLov'))
        let Authorization = localStorage.getItem('Authorization');
        let ORGANIZATION_BYID_ENDPOINT = properties.ENPOINTS.ORGANIZATION_BYID_ENDPOINT;
        let loginInfo = responseLogin.data.data


        axios({
            method: 'GET',
            url: ORGANIZATION_BYID_ENDPOINT + id_organizacion,
            headers: {
                "Authorization": "Bearer " + Authorization
            }
        }).then((response) => {
            let res = response.data.data
            let jsonAgentData = [];
            let jsonDirectionData = [];
            var array = [];
            let selectedDate = new Date();
            let Saludo = "";

            if(ListLov){
            ListLov.map(element => {
                if (element.nombre_dominio === "TITULO_PERSONA") {
                    element.valores_dominio.map((val) => {
                            if(res.titulo_contacto === val.codigo_valor){
                                Saludo = val.codigo_valor
                            }
                    })
                }
            })
            }
            
            let Owner
            if (res.lista_aplicaciones != null) {
                res.lista_aplicaciones.map(owner => {
                    Owner = owner.id_usuario_propietario
                })
            }

            try {
                if(res.ano_creacion === null || res.ano_creacion === undefined || res.ano_creacion === '' || res.ano_creacion === 0){
                    selectedDate = null
                }else{
                    selectedDate = selectedDate.setFullYear(res.ano_creacion)
                    selectedDate = new Date(selectedDate)
                }
            } catch (error) { console.log(error) }

            ////////////////////////     PRIMERA SECCIÓN DEL FORMULARIO     //////////////////////


            jsonAgentData.push({
                "NameSurname": res.nombre_organizacion,
                "saludos": res.saludos,
                "typeDonor": res.tipo_donante,
                "IdNumber": res.numero_identificacion,
                "originCountry": res.id_pais_origen,
                "agentType": res.tipo_organizacion,
                "acronym": res.siglas,
                "selectedDate": selectedDate,
                "yearExp": res.ano_experiencia,
                "UserOwner": Owner,
                "Email": res.direccion_correo_electronico,
                "Web": res.direccion_pagina_web,
                "Facebook": res.direccion_facebook,
                "Twitter": res.direccion_twitter,
                "Linkedin": res.direccion_linkedin, 
                "Instagram" : res.direccion_instagram,
                "MisionVision": res.mision_vision.replace(/\\n/gi, "\r\n"),
                "guidelines": res.lineamientos_estrategicos.replace(/\\n/gi, "\r\n"),
                "Name": res.nombre_contacto,
                "Surname": res.apellido_contacto,
                "Greeting": Saludo,
                "Position": res.cargo_contacto,
                "Title": res.titulo_contacto,
                "Email2": res.correo_electronico_contacto,
                "Phone": res.telefono_contacto,
                "ShowContact": res.mostrar_contacto,
        })
        
            localStorage.setItem('jsonAgentData', JSON.stringify(jsonAgentData));
            localStorage.setItem('imgMisOrganizaciones', JSON.stringify(res.foto));  //SE GUARDA FOTO EN EL LOCALSTORAGE
            if(res.foto !== "" && res.foto !== null){
                dispath(uploadImageMyOrganization(true, res.foto))  //SE PINTA EL LOGO CONSULTADA EN EL FORMULARIO 
            }
            if(res.icono !== "" && res.icono !== null){
                dispath(uploadImageDirection(true, res.icono))  //SE PINTA EL LOGO CONSULTADA EN EL FORMULARIO 
            }


            ////////////////////////     SEGUNDA SECCIÓN DEL FORMULARIO     //////////////////////
            // Construcción de objeto "ThematicArea"//
            let areaOfInterest = JSON.parse(localStorage.getItem('areaOfInterest'))
            let ThematicArea = []
            if (res.lista_area_interes != null) {
                areaOfInterest.map(element => {
                    res.lista_area_interes.map(id => {
                        if (id.id_areainteres === element.id_areainteres) {
                            ThematicArea.push({
                                "id_areainteres": element.id_areainteres,
                                "descripcion_areainteres": element.descripcion_areainteres
                            })
                        }
                    })
                })
            }

            //Construcción de objeto "GeographicalScope"//
            let regionCountry = JSON.parse(localStorage.getItem('regionCountry'))
            let GeographicalScope = []
            if (res.lista_EGT != null) {
                regionCountry.map(element => {
                    res.lista_EGT.map(id => {
                        if (id.id_pais === element.id_pais) {
                            GeographicalScope.push({
                                "id_pais": element.id_pais,
                                "es_region": element.es_region,
                                "nombre_pais": element.nombre_pais,
                                "siglas_pais": element.siglas_pais,
                            })
                        }
                    })
                })
            }

            //Construcción de objeto "CountryOperation"//
            let countries = JSON.parse(localStorage.getItem('countries'))
            let CountryOperation = []
            if (res.lista_pais_opera_org != null) {
                countries.map(element => {
                    res.lista_pais_opera_org.map(id => {
                        if (id.id_pais === element.id_pais) {
                            CountryOperation.push({
                                "id_pais": element.id_pais,
                                "es_region": element.es_region,
                                "nombre_pais": element.nombre_pais,
                                "siglas_pais": element.siglas_pais,
                            })
                        }
                    })
                })
            }

            // //Construcción de objeto "ObjetiveDev"//
            let organizationObjectives = JSON.parse(localStorage.getItem('organizationObjectives'))
            let ObjetiveDev = []
            if (res.lista_ODS != null) {
                organizationObjectives.map(element => {
                    res.lista_ODS.map(id => {
                        if (id.id_objetivo === element.id_objetivo) {
                            ObjetiveDev.push({
                                id_objetivo: element.id_objetivo,
                                imagen_objetivo: element.imagen_objetivo,
                                descripcion_objetivo: element.descripcion_objetivo,
                            })
                        }
                    })
                })
            }

            // // Construcción de objeto PubliGoal
            let organizationBeneficiaries = JSON.parse(localStorage.getItem('organizationBeneficiaries'))
            let PubliGoal = []
            if (res.lista_publico_meta != null) {
                organizationBeneficiaries.map(element => {
                    res.lista_publico_meta.map(id => {
                        if (id.id_publico_meta === element.id_beneficiario) {
                            PubliGoal.push({
                                id_beneficiario: element.id_beneficiario,
                                nombre_beneficiario: element.nombre_beneficiario
                            })
                        }
                    })
                })
            }

            // // Construcción de objeto NetworkBelongs
            let NetworkBelongsStorage = JSON.parse(localStorage.getItem('NetworkBelongs'))
            let NetworkBelongs = []
            if (res.lista_redes != null) {
                NetworkBelongsStorage.map(element => {
                    res.lista_redes.map(id => {
                        if (id.id_red === element.id_red) {
                            NetworkBelongs.push({
                                id_red: element.id_red,
                                imagen_red: element.imagen_red,
                                nombre_red: element.nombre_red
                            })
                        }
                    })
                })
            }

            //Se equivale respuesta 1/0 = true/false
            let inversion;
            let alianza;
            if (res.mostrar_inversion = 1) {
                inversion = true
            }
            if (res.mostrar_inversion = 0) {
                inversion = false
            }
            if (res.alianza = 1) {
                alianza = true
            }
            if (res.alianza = 0) {
                alianza = false
            }
            jsonDirectionData.push({
                "ThematicArea": ThematicArea,
                "GeographicalScope": GeographicalScope, // NO LLEGA DEL BACK (ALCANCE GEOGRÁFICO)
                "CountryOperation": CountryOperation,
                "PubliGoal": PubliGoal,
                "Length": res.EGT_longitud != null ? res.EGT_longitud : "",
                "Latitude": res.EGT_latitud != null ? res.EGT_latitud : "",
                "CentralPhone": res.EGT_telefono_central,
                "NetworkBelongs": NetworkBelongs,
                // // "OriginId": res.OriginId, //DATA NO MANEJADA EN EL BACK NI FRONT
                "OficceNumber": res.EGT_num_oficina,
                // // "TypeOng": res.TypeOng, //DATA NO MANEJADA EN EL FRONT (MAQUETAS)
                "ObjetiveDev": ObjetiveDev,
                // // "Personalities": res.Personalities, //DATA NO MANEJADA EN EL BACK, SE OCULTÓ EN EL FRONT
                "checkbox": inversion,
                "checkbox2": alianza,
            })

            localStorage.setItem('jsonDirectionData', JSON.stringify(jsonDirectionData));
            // localStorage.setItem('imgDirection', JSON.stringify(res.icono)); //SE GUARDA ICONO EN EL LOCALSTORAGE 


            ////////////////////////     TERCERA SECCIÓN DEL FORMULARIO     //////////////////////
            let tipoPremio = JSON.parse(localStorage.getItem("tipoPremio"))
            let organizationByUser = JSON.parse(localStorage.getItem("organizationByUser"))
            let organizaciones = organizationByUser.data.data;
            var jsonTables = [];
            let awardsTables = []
            let tableBeneficiary = [];
            let executionInvSocialTable = [];
            let investmentCountryTable = [];
            let investmentAreaTable = [];
            let investmentYearTable = [];

            //Objeto para Lista de premios
            if(res.lista_premios != null){
                res.lista_premios.map(premio => {
                    let awardsTable = {}
                    countries.map(country => {
                        if (country.id_pais == premio.id_pais) {
                            premio.nombre_pais = country.nombre_pais
    
                        }
                    })
    
    
                    tipoPremio.map(tipo => {
                        if (tipo.codigo_valor == premio.tipo_premio) {
                            premio.tipo_premio_name = tipo.presentacion_valor
    
                        }
                    })
    
                    organizaciones.map(org => {
                        if (org.id_organizacion == premio.id_organizacion) {
                            premio.nombre_organizacion = org.nombre_organizacion
    
                        }
                    })
                    // array = premio.ano_premio.split(":");
                    // premio.ano_premio = window.atob(array[2])
    
                    awardsTable.name = premio.nombre_premio;
                    awardsTable.year = premio.ano_premio;
                    awardsTable.type = premio.tipo_premio_name;
                    awardsTable.organization = premio.nombre_organizacion;
                    awardsTable.country = premio.nombre_pais;
                    awardsTable.description = premio.descripcion_premio;
                    awardsTable.id_country = premio.id_pais;
                    awardsTable.id_organization = premio.id_organizacion;
                    awardsTable.id_type = premio.tipo_premio
    
                    awardsTables.push(awardsTable)
    
                })
            }
           

            // Lista de beneficiarios
            if(res.lista_beneficiarios != null){
                res.lista_beneficiarios.map(ben => {
                    let beneficiary = {}
                    organizationBeneficiaries.map(orgBen => {
                        if (orgBen.id_beneficiario == ben.id_beneficiario) {
                            ben.nombre_beneficiario = orgBen.nombre_beneficiario
                        }
                    })
    
                    // array = ben.ano_beneficio.split(":");
                    // ben.ano_beneficio = window.atob(array[2])
    
                    beneficiary.name2 = ben.nombre_beneficiario;
                    beneficiary.year2 = ben.ano_beneficio;
                    beneficiary.amount = ben.monto_beneficio;
                    beneficiary.id = ben.id_beneficiario;
                  
                    tableBeneficiary.push(beneficiary)
    
    
                })
            }         

            // lista  lista_origen_fondos
            if(res.lista_origen_fondos != null){
                res.lista_origen_fondos.map(origen => {
                    let origenFondo = {};
    
                    countries.map(country => {
                        if (country.id_pais == origen.id_pais) {
                            origen.nombre_pais = country.nombre_pais
                        }
                    })
    
                    origenFondo.countryE =  origen.nombre_pais;
                    origenFondo.percent = origen.porcentaje_aporte
    
                    executionInvSocialTable.push(origenFondo)
    
                })
            }

            // lista lista_inv_social_pais
            var monedas = JSON.parse(localStorage.getItem("currencyList"));

            if(res.lista_inv_social_pais != null){
                res.lista_inv_social_pais.map(invPais => {
                    let invSocialPais = {};
    
                    countries.map(country => {
                        if (country.id_pais == invPais.id_pais) {
                            invPais.nombre_pais = country.nombre_pais
                        }
                    })
    
                    monedas.map(moneda => {
                        if (moneda.codigo_valor == invPais.moneda_inversion) {
                            invPais.moneda_inversion_nombre = moneda.presentacion_valor
                        }
    
                    })
    
                    // array = invPais.ano_inversion.split(":");
                    // invPais.ano_inversion = window.atob(array[2])
    
                    invSocialPais.yearIC = invPais.ano_inversion;
                    invSocialPais.amountLocalIC = invPais.monto_inversion_moneda_local;
                    invSocialPais.nameCurrencyIC =  invPais.moneda_inversion_nombre;
                    invSocialPais.amountDollarsIC = invPais.monto_inversion_USD;
                    invSocialPais.contryIC = invPais.nombre_pais;
                    invSocialPais.idCountry = invPais.id_pais;
                    invSocialPais.id = invPais.id_inversion_social_org_pais;
    
                    investmentCountryTable.push(invSocialPais)
                })
            }      

            // lista lista_inv_social_area_interes
            if(res.lista_inv_social_area_interes != null){
                res.lista_inv_social_area_interes.map(invArea => {
                    let invAreaInteres = {};
    
                    monedas.map(moneda => {
                        if (moneda.codigo_valor == invArea.moneda_inversion) {
                            invArea.moneda_inversion_nombre = moneda.presentacion_valor
                        }
    
                    })
    
                    areaOfInterest.map(area => {
                        if (area.id_areainteres == invArea.id_areainteres) {
                            invArea.nombre_area = area.descripcion_areainteres
                        }
                    })
    
                    // array = invArea.ano_inversion.split(":");
                    // invArea.ano_inversion= window.atob(array[2])
    
                    invAreaInteres.yearIA = invArea.ano_inversion;
                    invAreaInteres.amountLocalIA = invArea.monto_inversion_moneda_local;
                    invAreaInteres.nameCurrencyIA = invArea.moneda_inversion_nombre;
                    invAreaInteres.amountDollarsIA = invArea.monto_inversion_USD;
                    invAreaInteres.contryIA = invArea.nombre_area;
                    invAreaInteres.id_areainteres = invArea.id_areainteres;
                    invAreaInteres.id_inversion_social_org_areainteres = invArea.id_inversion_social_org_areainteres;
                    
                    investmentAreaTable.push(invAreaInteres)
                    
    
    
                })
            } 

            // lista lista_inv_social
            if(res.lista_inv_social != null){
                res.lista_inv_social.map(invSocial => {
                    let inversionSocial = {};
    
                    monedas.map(moneda => {
                        if (moneda.codigo_valor == invSocial.moneda_inversion) {
                            invSocial.moneda_inversion_nombre = moneda.presentacion_valor
                        }
    
                    })
    
                    // array = invSocial.ano_inversion.split(":");
                    // invSocial.ano_inversion= window.atob(array[2])
    
                    inversionSocial.yearIY = invSocial.ano_inversion;
                    inversionSocial.amountLocalIY = invSocial.monto_inversion_moneda_local;
                    inversionSocial.nameCurrencyIY = invSocial.moneda_inversion_nombre;
                    inversionSocial.amountDollarsIY = invSocial.monto_inversion_USD;
                    inversionSocial.tipo_inversion = invSocial.tipo_inversion;
                    inversionSocial.moneda_inversion = invSocial.moneda_inversion;
    
                    investmentYearTable.push(inversionSocial);
    
    
                })
            }

            jsonTables.push({
                "awardsTable": awardsTables,
                "tableBeneficiary": tableBeneficiary,
                "executionInvSocialTable" : executionInvSocialTable,
                "investmentCountryTable" : investmentCountryTable,
                "investmentAreaTable" : investmentAreaTable,
                "investmentYearTable" : investmentYearTable
            })


            localStorage.setItem("jsonTables", JSON.stringify(jsonTables))
            dispath(organizationDash(false))
            var readOnly =  localStorage.getItem("readOnlyOrg")
            localStorage.setItem("indentificationOrg", id_organizacion)
            dispath(wizardAddOrganization(true, readOnly == 1 ? true :  false, ))

        }).catch(error => {
            let message = undefined
            if (error.response.data.code) {
                properties.errorMessage.map((element) => {
                    if (element.value === error.response.data.code) {
                        message = error.response.data.code
                    }
                })
            }
            dispath(showErrorModal(true, message))
        });
    }
}
