import { 
    OPEN_MODAL_CHANGE_PASSWORD,
    OPEN_MODAL_NEW_USER,
    ORGANIZATION_DASH,
    WIZARD_ADD_ORGANIZATION,
 } from '../Constants'

export const openModalChangePassword = (payload) => {

    return {
        type: OPEN_MODAL_CHANGE_PASSWORD,
        payload: payload,
    }
}

export const openModalNewUser = (payload) => {

    return {
        type: OPEN_MODAL_NEW_USER,
        payload: payload,
    }
}

export const organizationDash = (payload) => {

    return {
        type: ORGANIZATION_DASH,
        payload: payload,
    }
}

export const wizardAddOrganization = (payload, disable, objeto) => {

    return {
        type: WIZARD_ADD_ORGANIZATION,
        payload: payload,
        disable: disable,
        objeto: objeto
    }
}



