export const styles = theme => ({
    submit: {
      margin: theme.spacing(0, 2, 1, 1),
      backgroundColor: '#494377',
      borderRadius: '0px !important',
    },
    title: {
      margin: theme.spacing(0, 0, 0, 0),
      marginBottom: '-15px'
    },
    typografia: {
      margin: theme.spacing(2, 2, 2, 2),
    },
    Xclose: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      margin: theme.spacing(1, 2, 0, 1),
      cursor: 'pointer',
    },
    icon: {
      color: '#f44336',
      width: '50px',
      height: '50px'
    },
    iconCheck: {
      color: '#5cb660',
      width: '50px',
      height: '50px'
    },
    errorIcon: {
      margin: theme.spacing(0, 0, -2.5, 25),
    },
  });