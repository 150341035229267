import { USER_BY_ID } from '../../Constants';

export const getUserById = (state = {} ,action) => {
    switch (action.type) {
        case USER_BY_ID:
            return {...state, 
                userById: action.userById,
            }
    
        default:
            return state;
    }
}