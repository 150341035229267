import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

import Login from "../Components/Login/";
import Home from "../Components/Dashbord";

export default function RouteWrapper({
  component: Component,
  isPrivate,
  authent,
  ...rest
}) {
  
  var signed = false;
  
  if(localStorage.getItem('signed') !== null){
    signed = true;
  }

  if (isPrivate && !signed) {   
    return <Redirect to="/" />;
  }

  if (!isPrivate && signed) {
    return <Redirect to="/home" />;
  }

  if (authent === true){
    return <Redirect to="/home" />;
  }
  const Layout = signed ? Home : Login;
  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

RouteWrapper.defaultProps = {
  isPrivate: false
};
