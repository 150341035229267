import { height } from "@material-ui/system";

export const styles = theme => ({
    root: {
        // minHeight: "50vh",
        // minWidth: "50vw"
    },
    gridsContainer: {
        margin: theme.spacing(1, 1, 1, 0),
    },
    forms: {
        padding: theme.spacing(1, 1, 1, 1),
    },
    textarea: {
        top: '10%',
        width: '100%'
    },
    sectionFile: {
        marginTop: '6%',
        height: "90%",
        width: "100%",
        border: '2px dashed grey',
    },
    buttonFile: {
        marginTop: '5%', 
        borderRadius: '0%'
    },
    checkbox: {
        margin: theme.spacing(2, 0,0, ),
        // float: "right",
        // display: "inline"
    },
    // containerImage: {
    //     position: "relative",
    //     // width: "50%",
    // },
    // imgLogo: {
    //     width: '100%', 
    //     height: '80%',
    // },
    // fixedButton: {
    //     position: "absolute",
    //     top: "100%",
    //     left: "100%",
    //     transform: "translate(-50%, -50%)",
    //     msTransform: "translate(-50%, -50%)",
    //     backgroundColor: "#494377",
    //     color: "white",
    //     fontSize: "0.8rem",
    //     padding: "5%",
    //     border: "none",
    //     cursor: "pointer",
    //     borderRadius: "100%",
    //     boxShadow: "2px 2px 5px #999",
    // }
  });