import { ALL_USERS } from '../../Constants';

export const getAllUsers = (state = {} ,action) => {
    switch (action.type) {
        case ALL_USERS:
            return {...state, 
                payload: action.payload,
                allUsersObject: action.allUsersObject,
                totalPagePagination: action.totalPagePagination
            }
    
        default:
            return state;
    }
}