import { USERTYPE_COMBO } from '../../Constants';

export const userType = (state = {} ,action) => {
    switch (action.type) {
        case USERTYPE_COMBO:
            return {...state, 
                payload: action.payload,
                typeUser: action.typeCombo,
            }
    
        default:
            return state;
    }
}