export const styles = theme => ({
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
      borderRadius: '0%'
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      borderRadius: '0%',
    },
    buttonFinishNext: {
      borderRadius: '0%',
    },
    styleButtons: {
      marginTop: theme.spacing(5),
    },
  });