
import axios from 'axios'
import { getAllUsers } from '../Services/getAllUsers'
import { showErrorModal } from '../_actionShowErrorModal'

export function postCreateOrganization(METHOD) {
  
    return dispath => {
        let fecha_creacion = new Date()
        let properties = JSON.parse(localStorage.getItem('properties'));
        let responseLogin = JSON.parse(localStorage.getItem('responseLogin'));
        let Authorization = localStorage.getItem('Authorization');
        let jsonAgentData = JSON.parse(localStorage.getItem('jsonAgentData'));
        let jsonDirectionData = JSON.parse(localStorage.getItem('jsonDirectionData')); 
        let jsonTables = JSON.parse(localStorage.getItem('jsonTables'));
        let countries = JSON.parse(localStorage.getItem('countries'));
        var tipoPremio = JSON.parse(localStorage.getItem('tipoPremio'));
        var currencyList = JSON.parse(localStorage.getItem('currencyList'));
        var areaOfInterest = JSON.parse(localStorage.getItem('areaOfInterest'));
        var organizationBeneficiaries = JSON.parse(localStorage.getItem('organizationBeneficiaries'));
        let imgMisOrganizaciones = JSON.parse(localStorage.getItem('imgMisOrganizaciones'));
        let imgDirection = JSON.parse(localStorage.getItem('imgDirection'));
        let UserOwner = jsonAgentData[0].UserOwner

    //CONSTRUCCIÓN DE JSON DE FORMULARIO PASO 1
      
      let mostrar_contacto;
      if(jsonAgentData[0].ShowContact === true){
        mostrar_contacto = 1
      }
      if(jsonAgentData[0].ShowContact === false || jsonAgentData[0].ShowContact === null){
        mostrar_contacto = 0
      } 
      let foto = imgMisOrganizaciones;
      let nombre_organizacion = jsonAgentData[0].NameSurname;
      let tipo_donante = jsonAgentData[0].typeDonor;
      let numero_identificacion = jsonAgentData[0].IdNumber;
      let id_pais_origen = jsonAgentData[0].originCountry;
      let tipo_organizacion = jsonAgentData[0].agentType;
      let siglas = jsonAgentData[0].acronym;
      let ano_creacion = jsonAgentData[0].selectedDate;
      let anos_experiencia = jsonAgentData[0].yearExp;
      if(UserOwner === "" || UserOwner === null){
          UserOwner = responseLogin.data.data.user_id
      }
      let lista_aplicaciones = [
          {
              "id_aplicacion": 3,
              "fecha_creacion": null,
              "usuarioapp_creacion": null,
              "id_usuario_propietario": parseInt(UserOwner)
          }
      ];
      let direccion_correo_electronico = jsonAgentData[0].Email;
      let direccion_pagina_web = jsonAgentData[0].Web;
      let direccion_facebook = jsonAgentData[0].Facebook;
      let direccion_twitter = jsonAgentData[0].Twitter;
      let direccion_linkedin = jsonAgentData[0].Linkedin 
      let direccion_instagram = jsonAgentData[0].Instagram 
      
      let mision_vision = jsonAgentData[0].MisionVision;
      mision_vision = mision_vision.replace(/\n/ig, '\\n');

      let lineamientos_estrategicos = jsonAgentData[0].guidelines;
      lineamientos_estrategicos = lineamientos_estrategicos.replace(/\n/ig, '\\n');

      let nombre_contacto = jsonAgentData[0].Name;
      let apellido_contacto = jsonAgentData[0].Surname;
      // let saludo": "saludo",  //Campo se está desarrollando en el back
      let cargo_contacto = jsonAgentData[0].Position;
      let titulo_contacto = jsonAgentData[0].Title;
      let correo_electronico_contacto = jsonAgentData[0].Email2;
      let telefono_contacto = jsonAgentData[0].Phone;
      mostrar_contacto = parseInt(mostrar_contacto);
         
      //CONSTRUCCIÓN DE JSON DE FORMULARIO PASO 2

      let alianza;
      let mostrar_inversion;
      if(jsonDirectionData[0].checkbox === true){
          mostrar_inversion = 1
      }
      if(jsonDirectionData[0].checkbox === false || jsonDirectionData[0].checkbox === null){
          mostrar_inversion = 0
      }
      if(jsonDirectionData[0].checkbox2 === true){
          alianza = 1
      }
      if(jsonDirectionData[0].checkbox2 === false || jsonDirectionData[0].checkbox2 === null){
          alianza = 0
      }
      let lista_area_interes = []
      let lista_EGT = []
      let lista_pais_opera_org = []
      let lista_publico_meta = []
      let lista_ODS = []    
      let lista_redes = [];

        jsonDirectionData[0].ThematicArea.map((element, i) =>{
          lista_area_interes.push({
            "fecha_creacion": null,
            "id_areainteres": parseInt(element.id_areainteres),
            "usuarioapp_creacion": null,
          })
        })

        jsonDirectionData[0].GeographicalScope.map((element, i) =>{
          lista_EGT.push({
            "id_pais": parseInt(element.id_pais),
            "fecha_creacion": null,
            "usuarioapp_creacion": null
          })
        })

        jsonDirectionData[0].CountryOperation.map((element, i) =>{
          lista_pais_opera_org.push({
            "id_pais": parseInt(element.id_pais),
            "fecha_creacion": null,
            "usuarioapp_creacion": null
          })
        })

        jsonDirectionData[0].PubliGoal.map((element, i) =>{
          lista_publico_meta.push({
            "fecha_creacion": null,
            "id_publico_meta": parseInt(element.id_beneficiario),
            "usuarioapp_creacion": null
          })
        })

        jsonDirectionData[0].ObjetiveDev.map((element, i) =>{
          lista_ODS.push({
            "id_objetivo": parseInt(element.id_objetivo),
            "fecha_creacion": null,
            "usuarioapp_creacion": null
          })
        })
        jsonDirectionData[0].NetworkBelongs.map((element, i) =>{
          lista_redes.push({
            "id_red": element.id_red,
            "fecha_creacion": null,
            "usuarioapp_creacion": null
          })
        })

      let EGT_longitud = jsonDirectionData[0].Length;
      let EGT_latitud = jsonDirectionData[0].Latitude;
      let EGT_telefono_central = jsonDirectionData[0].CentralPhone;
      let EGT_num_oficina = jsonDirectionData[0].OficceNumber;
      let icono = imgDirection;
        
      //CONSTRUCCIÓN DE JSON DE FORMULARIO PASO 3
      let lista_premios = []
      let lista_beneficiarios = []
      let lista_origen_fondos = []
      let lista_inv_social_pais = []
      let lista_inv_social_area_interes = []
      let lista_inv_social = []

          jsonTables[0].awardsTable.map((element) =>{
            let idCountry;
            let idPremio;
            countries.map(id => {
              if(element.country === id.nombre_pais)
                {idCountry = id.id_pais}
            })
            tipoPremio.map(id => {
              if(element.type === id.presentacion_valor)
                {idPremio = id.codigo_valor}
            })
            lista_premios.push({
              "id_pais": parseInt(idCountry),
              "ano_premio": parseInt(element.year),
              "tipo_premio": idPremio.toString(),
              "nombre_premio": element.name.toString(),
              "fecha_creacion": null, 
            //   "id_organizacion": null, //SE ENVÍA NULL Y ME PIDE VALIDACIÓN NUMÉRICA
              "descripcion_premio": element.description,
              "usuarioapp_creacion": null, 
            //   "id_premio_organizacion": null, //SE ENVÍA NULL Y ME PIDE VALIDACIÓN NUMÉRICA
              "organizacion_otorga_premio": element.organization.toString()
            })
          })

          jsonTables[0].tableBeneficiary.map((element) =>{
            let idName;
            organizationBeneficiaries.map(id => {
              if(element.name2 === id.nombre_beneficiario)
                {idName = id.id_beneficiario}
            })
            lista_beneficiarios.push({
                "ano_beneficio": parseInt(element.year2),
                "fecha_creacion": null,
                "id_beneficiario": 1, //SE ENVÍA NULL Y ME PIDE VALIDACIÓN NUMÉRICA. SE ENVÍA 1 PARA QUE EL API FUNCIONE (VALOR NO NECESARIO)
                "monto_beneficio": parseInt(element.amount),
                "usuarioapp_creacion": null, 
                "descripcion_beneficiario_organizacion": idName.toString()
            })
          })
          
          jsonTables[0].executionInvSocialTable.map((element, i) =>{
            
            let idCountry;
            countries.map(id => {
              if(element.countryE === id.nombre_pais)
                {idCountry = id.id_pais}
            })
            lista_origen_fondos.push({
              "id_pais": parseInt(idCountry),
            //   "fecha_creacion": null, //SE ENVÍA NULL Y ME PIDE VALIDACIÓN NUMÉRICA
              "porcentaje_aporte": parseFloat(element.percent),
            //   "id_origen_fondo_org": null, //SE ENVÍA NULL Y ME PIDE VALIDACIÓN NUMÉRICA
            //   "usuarioapp_creacion": null, //SE ENVÍA NULL Y ME PIDE VALIDACIÓN NUMÉRICA
              "descripcion_origen_fondo_org": "" //SE ENVÍA NULL Y ME PIDE VALIDACIÓN NUMÉRICA
            })
          })

          jsonTables[0].investmentCountryTable.map((element, i) =>{
            let idCountry;
            let idAmountCurrency;
            countries.map(id => {
              if(element.contryIC === id.nombre_pais)
                {idCountry = id.id_pais}
            })
            currencyList.map(id => {
              if(element.nameCurrencyIC === id.presentacion_valor)
                {idAmountCurrency = id.codigo_valor}
            })

            lista_inv_social_pais.push({
              "id_pais": parseInt(idCountry),
              "ano_inversion": parseInt(element.yearIC),
              "fecha_creacion": "2020-02-26 09:32:15.000000",
              "moneda_inversion": idAmountCurrency.toString(),
              "monto_inversion_USD": parseFloat(element.amountDollarsIC),
            //   "usuarioapp_creacion": null, //SE ENVÍA NULL Y ME PIDE VALIDACIÓN NUMÉRICA
            //   "id_inversion_social_org_pais": null, //SE ENVÍA NULL Y ME PIDE VALIDACIÓN NUMÉRICA
              "monto_inversion_moneda_local": parseFloat(element.amountLocalIC)
            })
          })

          jsonTables[0].investmentAreaTable.map((element, i) =>{
            let IdAreaInteres;
            let idAmountCurrency;
            areaOfInterest.map(id => {
              if(element.contryIA === id.descripcion_areainteres)
                {IdAreaInteres = id.id_areainteres}
            })
            currencyList.map(id => {
              if(element.nameCurrencyIA === id.presentacion_valor)
                {idAmountCurrency = id.codigo_valor}
            })

            lista_inv_social_area_interes.push({
              "ano_inversion": parseInt(element.yearIA),
              "fecha_creacion": "2020-02-26 09:32:15.000000",
              "id_areainteres": parseInt(IdAreaInteres),
              "moneda_inversion": idAmountCurrency.toString(),
              "monto_inversion_USD": parseFloat(element.amountDollarsIA),
            //   "usuarioapp_creacion": null, //SE ENVÍA NULL Y ME PIDE VALIDACIÓN NUMÉRICA
              "monto_inversion_moneda_local": parseFloat(element.amountLocalIA),
            //   "id_inversion_social_org_areainteres": null //SE ENVÍA NULL Y ME PIDE VALIDACIÓN NUMÉRICA
            })
          })
          
          jsonTables[0].investmentYearTable.map((element, i) =>{
            let idAmountCurrency;
            currencyList.map(id => {
              if(element.nameCurrencyIY === id.presentacion_valor)
                {idAmountCurrency = id.codigo_valor}
            })

            lista_inv_social.push({
              "ano_inversion": parseInt(element.yearIY),
              "fecha_creacion": null,
              "tipo_inversion": "",
              "moneda_inversion": idAmountCurrency.toString(),
              "monto_inversion_USD": parseFloat(element.amountDollarsIY),
              "usuarioapp_creacion": null, //SE ENVÍA NULL Y ME PIDE VALIDACIÓN NUMÉRICA
              "descripcion_inversion": "", //SE ENVÍA NULL Y ME PIDE VALIDACIÓN NUMÉRICA
              "id_inversion_social_org": null, //SE ENVÍA NULL Y ME PIDE VALIDACIÓN NUMÉRICA
              "monto_inversion_moneda_local": parseFloat(element.amountLocalIY)
            })
          })

          let tipo_ONG = "";
          let direccion = "";
          let direccion_ciudad = "";
          let direccion_estado = "";
          let id_pais_direccion = null;
          let descripcion_alianza = "";
          let direccion_codigo_postal = "";
          let descripcion_organizacion = "";
          let tipo_contacto = "";
          let status_organizacion = "";
          let lista_aliados = [];
          let lista_contactos = [];
          let lista_emprendedores = [];
          let lista_programas_servicio = [];
          let lista_personalidades = [];

          var json = {
            "foto" : foto, //PASA VACÍO
            "fecha_creacion": fecha_creacion, //PASA VACÍO
            ///////////////////////PRIMER FORMULARIO///////////////////////////
            "nombre_organizacion" : nombre_organizacion, //OBLIGATORIO
            "tipo_donante" : tipo_donante.toString(), //OBLIGATORIO
            "numero_identificacion" : numero_identificacion.toString(), //PASA VACÍO
            "id_pais_origen" : parseInt(id_pais_origen), //SE ENVÍA NULL Y ME PIDE VALIDACIÓN NUMÉRICA
            "tipo_organizacion" : tipo_organizacion, //OBLIGATORIO
            "siglas" : siglas, //PASA VACÍO
            "ano_creacion" : parseInt(ano_creacion), //SE ENVÍA NULL Y ME PIDE VALIDACIÓN NUMÉRICA
            "anos_experiencia" : parseInt(anos_experiencia), //SE ENVÍA NULL Y ME PIDE VALIDACIÓN NUMÉRICA
            "lista_aplicaciones" : lista_aplicaciones, //OBLIGATORIO
            "direccion_correo_electronico" : direccion_correo_electronico, //PASA VACÍO
            "direccion_pagina_web" : direccion_pagina_web, //PASA VACÍO
            "direccion_facebook" : direccion_facebook, //PASA VACÍO
            "direccion_twitter" : direccion_twitter, //PASA VACÍO
            "direccion_linkedin" : direccion_linkedin, //PASA VACÍO
            "direccion_instagram" : direccion_instagram, //PASA VACÍO
            "mision_vision" : mision_vision, //PASA VACÍO
            "lineamientos_estrategicos" : lineamientos_estrategicos, //PASA VACÍO
            "nombre_contacto" : nombre_contacto, //PASA VACÍO
            "apellido_contacto" : apellido_contacto, //PASA VACÍO
            "cargo_contacto" : cargo_contacto, //PASA VACÍO
            "titulo_contacto" : titulo_contacto, //PASA VACÍO
            "correo_electronico_contacto" : correo_electronico_contacto, //PASA VACÍO
            "telefono_contacto" : telefono_contacto, //PASA VACÍO
            "mostrar_contacto" : mostrar_contacto, //OBLIGATORIO
            ///////////////////////SEGUNDO FORMULARIO///////////////////////////
            "alianza" : alianza,  //PASA 0
            "mostrar_inversion" : mostrar_inversion, //PASA 0
            "lista_area_interes" : lista_area_interes, //PASA []
            "lista_EGT" : lista_EGT, //PASA []
            "lista_pais_opera_org" : lista_pais_opera_org, //PASA []
            "lista_publico_meta" : lista_publico_meta, //PASA []
            "lista_ODS" : lista_ODS, //PASA []
            "EGT_longitud" : parseFloat(EGT_longitud), //PASA 0
            "EGT_latitud" : parseFloat(EGT_latitud), //PASA 0
            "EGT_telefono_central" : EGT_telefono_central, //PASA VACÍO
            "EGT_num_oficina" : EGT_num_oficina,  //PASA VACÍO
            "icono" : icono, //PASA 0
            ///////////////////////TERCER FORMULARIO///////////////////////////
            "lista_premios" : lista_premios, //PASA []
            "lista_beneficiarios" : lista_beneficiarios, //PASA []
            "lista_origen_fondos" : lista_origen_fondos, //PASA []
            "lista_inv_social_pais" : lista_inv_social_pais, //PASA []
            "lista_inv_social_area_interes" : lista_inv_social_area_interes, //PASA []
            "lista_inv_social" : lista_inv_social, //PASA []
            ///////////////////////VARIEBLES NULL///////////////////////////
            "tipo_ONG" : tipo_ONG,
            "direccion" : direccion,
            "direccion_ciudad" : direccion_ciudad,
            "id_pais_direccion" : id_pais_direccion, //SE ENVÍA NULL Y ME PIDE VALIDACIÓN NUMÉRICA
            "descripcion_alianza" : descripcion_alianza,
            "direccion_codigo_postal" : direccion_codigo_postal,
            "descripcion_organizacion" : descripcion_organizacion,
            "tipo_contacto" : tipo_contacto,
            "status_organizacion" : status_organizacion,
            "lista_aliados" : lista_aliados,
            "lista_contactos" : lista_contactos,
            "lista_emprendedores" : lista_emprendedores,
            "lista_programas_servicio" : lista_programas_servicio, 
            "direccion_estado": direccion_estado,
            "lista_redes": lista_redes,
            "lista_personalidades": lista_personalidades,  
          }

          var DYNAMIC_ENDPOINT;
          var id_organizacion
          if(METHOD === 'POST'){
            DYNAMIC_ENDPOINT = properties.ENPOINTS.CREATE_ORGANIZATION_ENDPOINT;
          }
          if(METHOD === 'PUT'){
            DYNAMIC_ENDPOINT = properties.ENPOINTS.UPDATE_ORGANIZATION_ENDPOINT;
              id_organizacion =  localStorage.getItem("indentificationOrg")
              json.id_organizacion = id_organizacion
          }
        axios({
            method: METHOD,
            url: DYNAMIC_ENDPOINT,
            headers: {
                "Authorization": "Bearer " + Authorization
            },
            data: json
        }).then((response) => {

          if(METHOD === 'POST'){
              if(response.data.code === "200"){
                dispath(showErrorModal(true, properties.messages.successCreateOrg, 'private', null, true))
              }else{
                dispath(showErrorModal(true, properties.messages.failCreateOrg, 'private', null, false))
            }
          }

          if(METHOD === 'PUT'){
            if(response.data.code === "200"){
                dispath(showErrorModal(true, properties.messages.successUpdateOrg, 'private', null, true))
            }else{
                dispath(showErrorModal(true, properties.messages.failUpdateOrg, 'private', null, false))
            }
          }
         
        })
          .catch(error => {
            if(METHOD === 'POST'){
              dispath(showErrorModal(true, properties.messages.failCreateOrg, 'private', null, false))
            }

            if(METHOD === 'PUT'){
              dispath(showErrorModal(true, properties.messages.failUpdateOrg, 'private', null, false))
            }
          });
    }
}
