export const styles = theme => ({
    table: {
        minWidth: 650,
      },
      titleTable:{
        margin: theme.spacing(1, 0, 0, 0),
      },
      buttons: {
        margin: theme.spacing(1, 1, 1, 1),
        backgroundColor: '#494377',
        borderRadius: '0px !important',
      },
      textButton: {
        padding: '1px 25px 2px 25px'
      },
      ButtonAndSpinner: {
        padding: '1px 25px 2px 10px',
        display: 'flex',
      },
      icon: {
        background: '#494377',
        padding: '6px',
        marginRight: '4px',
        blockSize: '30px'
      },
      inline: {
        display: 'flex',
      },
      pagination: {
        marginLeft: '25%', 
        marginTop: '2%'
      }
  });