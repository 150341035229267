import { REMEMBER_PASSWORD } from '../../Constants';

export const rememberPass = (state = {} ,action) => {
    switch (action.type) {
        case REMEMBER_PASSWORD:
            return {...state, passwordRemember: action.payload}  
    
        default:
            return state;
    }
}