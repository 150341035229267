import { height } from "@material-ui/system";

export const styles = theme => ({
    root: {
        margin: theme.spacing(5, 0, 0, 0),
    },
    grid1: {
        padding: theme.spacing(3, 0, 0, 2),
    },
    styleButton: {
        marginTop: '0%', 
        borderRadius: '0%',
        float: 'right',
    },
    itemTable: {
        margin: theme.spacing(2, 0, 3, 0),
    },
    headersTable: {
        // margin: theme.spacing(0, 25, 0, 0),
    },
  });

 

export const awardsTableProps = [
    {
        name: "name",
        type: "text",
        label: "Nombre",
    },
    {
        name: "year",
        type: "date",
        label: "Año",
    },
    {
        name: "type",
        type: "select",
        nameOptions: "tipoPremio",
        nameValueKey: "codigo_valor",
        nameMessage:"presentacion_valor",
        label: "Tipo",
    },
    {
        name: "organization",
        type: "text",
        label: "Organización",
    },
    {
        name: "country",
        type: "select",
        nameOptions: "countries",
        nameValueKey: "id_pais",
        nameMessage:"nombre_pais",
        label: "País",
    },
    {
        name: "description",
        type: "text",
        label: "Descripción",
    },
];

export const tablePropsBeneficiary = [
    {
        name: "name2",
        type: "select",
        nameOptions: "organizationBeneficiaries",
        nameValueKey: "id_beneficiario",
        nameMessage:"nombre_beneficiario",
        label: "Nombre",
    },
    {
        name: "year2",
        type: "date",
        format: "number",
        label: "Año",
    },
    {
        name: "amount",
        type: "text",
        format: "number",
        label: "Monto",
    },
];

export const executionInvSocialTableProps = [
    {
        name: "countryE",
        type: "select",
        nameOptions: "countries",
        nameValueKey: "id_pais",
        nameMessage:"nombre_pais",
        label: "Nombre",
    },
    {
        name: "percent",
        type: "text",
        format: "number",
        label: "Año",
    },
];

export const investmentCountryTableProps = [
    {
        name: "yearIC",
        type: "date",
        format: "number",
        label: "Año",
    },
    {
        name: "amountLocalIC",
        type: "text",
        format: "number",
        label: "Monto Moneda Local",
    },
    {
        name: "nameCurrencyIC",
        type: "select",
        label: "Nombre Moneda Local",
        nameOptions: "currencyList",
        nameValueKey: "codigo_valor",
        nameMessage:"presentacion_valor",
    },
    {
        name: "amountDollarsIC",
        type: "text",
        format: "number",
        label: "Monto en Dolares",
    },
    {
        name: "contryIC",
        type: "select",
        label: "País",
        nameOptions: "countries",
        nameValueKey: "id_pais",
        nameMessage:"nombre_pais",
    },
];

export const investmentAreaTableProps = [
    {
        name: "yearIA",
        type: "date",
        format: "number",
        label: "Año",
    },
    {
        name: "amountLocalIA",
        type: "text",
        format: "number",
        label: "Monto Moneda Local",
    },
    {
        name: "nameCurrencyIA",
        type: "select",
        label: "Nombre Moneda Local",
        nameOptions: "currencyList",
        nameValueKey: "codigo_valor",
        nameMessage:"presentacion_valor",
    },
    {
        name: "amountDollarsIA",
        type: "text",
        format: "number",
        label: "Monto en Dolares",
    },
    {
        name: "contryIA",
        type: "select",
        label: "Área Temática",
        nameOptions: "areaOfInterest",
        nameValueKey: "id_areainteres",
        nameMessage:"descripcion_areainteres",
    },
];

export const investmentYearTableProps = [
    {
        name: "yearIY",
        type: "date",
        format: "number",
        label: "Año",
    },
    {
        name: "amountLocalIY",
        type: "text",
        format: "number",
        label: "Monto Moneda Local",
    },
    {
        name: "nameCurrencyIY",
        type: "select",
        label: "Nombre Moneda Local",
        nameOptions: "currencyList",
        nameValueKey: "codigo_valor",
        nameMessage:"presentacion_valor",
    },
    {
        name: "amountDollarsIY",
        type: "text",
        format: "number",
        label: "Monto en Dolares",
    },
];