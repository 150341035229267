
import axios from 'axios'
import { ALL_USERS } from '../../Constants';
import {showErrorModal} from '../../Actions/_actionShowErrorModal'
import { getOrganizationByUser } from '../../Actions/Services/getOrganizationByUser'

export function deleteOrganization(id) {
    
    return dispath => {
        let properties = JSON.parse(localStorage.getItem('properties'));
        let DELETE_ORGANIZATION_ENDPOINT = properties.ENPOINTS.DELETE_ORGANIZATION_ENDPOINT;
        let Authorization = localStorage.getItem('Authorization');
        let responseLogin = JSON.parse(localStorage.getItem('responseLogin'))
    
        let transaction_id = responseLogin.data.data.transaction_id
        axios({
            method: 'DELETE',
            url: DELETE_ORGANIZATION_ENDPOINT+id,
            params: {
                transaction_id: transaction_id
            },
            headers: {
                "Authorization": "Bearer " + Authorization
            }
        }).then((response) => {
            dispath(getOrganizationByUser())
        }).catch(error => {
            let errorCode = properties.message
            if(error.response){
                properties.errorMessage.map((element) => {
                    if(element.value === error.response.data.code){
                        errorCode = error.response.data.code
                    }
                })
            }
            dispath(showErrorModal(true, errorCode))
        });
    }
}