// DEPENDENCIAS
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../constants';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Organizaciones from '../../app/Organizaciones.png';
import Voluntarios from '../../app/Voluntarios.png';
import Print from '../../app/print.png';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import esLocale from "date-fns/locale/es";

//ACTIONS
import { getDashboard } from '../../../Actions/Services/getDashboard'

// COMPONENTES
import Graph from '../Charts/PieGraph';
import LineGraph from '../Charts/LineGraph';
import BarGraph from '../Charts/BarGraph';
import { DATA } from './constants'

import './index.css'
import { Typography } from '@material-ui/core';


// const data = DATA

class AdminSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {
        const { classes } = this.props;
        const { anchorEl } = this.state;

        var data = JSON.parse(localStorage.getItem("dashboard"));

        return (
            <>
                {this.props.dashboard ?
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs>
                            </Grid>
                            {data.totals.map(total => (

                                <Grid item xs={12} md={3} >
                                    <Paper className={classes.papericons} elevation={0}>
                                        <Grid container style={{ marginBottom: -30 }} >

                                            <Grid item xs={1} md={3} >
                                                <i className={"uer-app " + total.icon} style={{ fontSize: 40, marginTop: 10, position: "absolute" }} />
                                            </Grid>
                                            <Grid item xs={11} md={9} >
                                                {total.icon === "uer-icon-Voluntarios" ?
                                                    <>
                                                        <Grid container alignItems="flex-start">
                                                            <Grid item >
                                                                <label className={classes.number} > <strong style={{marginLeft:15}}>{total.value}</strong></label>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container alignItems="flex-start">
                                                            <Grid item>
                                                                <label className={classes.labelicons2} style={{marginLeft:20}}>{total.title}</label>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                    :
                                                    <>
                                                        <Grid container alignItems="flex-start">
                                                            <Grid item >
                                                                <label className={classes.number}> <strong>{total.value}</strong></label>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container alignItems="flex-start">
                                                            <Grid item>
                                                                <label className={classes.labelicons2}>{total.title}</label>
                                                            </Grid>
                                                        </Grid>
                                                    </>

                                                }

                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>




                            ))}


                            {/* <Grid item xs={12} md={3}>
                        <Paper className={classes.papericons} elevation={0}>
                            <Grid container >

                                <Grid item xs={5} md={9}>
                                    <MaterialUIPickers />
                                </Grid>

                                <Grid item xs={2} md={3} >
                                    <img src={Print} className={classes.printIcon} />
                                </Grid>

                            </Grid>
                        </Paper>
                    </Grid> */}
                            <Grid item xs>
                            </Grid>
                        </Grid>


                        <Grid container spacing={2} style={{ marginTop: 30 }} >
                            {data.graphics.map(graph => (
                                <Grid item xs={12} md={graph.rows}>
                                    <Paper className={classes.paper} elevation={2}>
                                        <Typography variant="overline">{graph.title}</Typography>
                                        <Divider style={{ marginBottom: 20 }}></Divider>
                                        {graph.type == 1 ?
                                            <Graph data={graph.chart.data} color={graph.chart.color} />
                                            : graph.type == 2 ?
                                                <LineGraph  data={graph.chart.data} lines={graph.lines}/>
                                                : graph.type == 3 ?
                                                    <BarGraph data={graph.chart.data} lines={graph.lines} />
                                                    : <></>
                                        }
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </>
                    :
                    <Typography>Cargando</Typography>
                }
            </>
        )
    }
}

const summary = withStyles(styles, { withTheme: true })(AdminSummary);

function mapStateToProps(state) {

    return {
        changePasswordModal: state.dashboard.changePasswordModal,
        dashboard: state.dashboard.data,
    };
}

export default connect(
    mapStateToProps,
    {
        getDashboard

    }
)(summary);



function MaterialUIPickers() {
    // The first commit of Material-UI
    const [selectedDate, setSelectedDate] = React.useState(new Date('2020-01-30T21:11:54'));
    const [selectedDate2, setSelectedDate2] = React.useState(new Date('2020-01-30T21:11:54'));

    const handleDateChange = date => {
        setSelectedDate(date);
    };

    const handleDateChange2 = date => {
        setSelectedDate2(date);
    };


    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <Grid container justify="space-around">
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    //   marginBottom="normal"
                    id="date-picker-inline"
                    //   label="Date picker inline"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    className={'MuiTextField-root-2'}

                />
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    //   marginBottom="normal"
                    id="date-picker-inline"
                    //   label="Date picker inline"
                    value={selectedDate2}
                    onChange={handleDateChange2}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                // style={{
                //     border: '1px solid #ada6a6',
                //     borderRadius: '5px',
                //     height: '25px',
                //     fontSize: '15px',
                //     marginTop: '5px',
                //     padding: '5px',
                // }}
                />
            </Grid>
        </MuiPickersUtilsProvider>
    );
}
