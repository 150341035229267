import axios from 'axios'
import { ORGANIZATION_BY_USER } from '../../Constants';
import {showErrorModal} from '../../Actions/_actionShowErrorModal'

export function getListLov() {
    
    return dispath => {
        let properties = JSON.parse(localStorage.getItem('properties'));
        let responseLogin = JSON.parse(localStorage.getItem('responseLogin'))
        let Authorization = localStorage.getItem('Authorization');
        let GET_LOV_ENDPOINT = properties.ENPOINTS.GET_LOV_ENDPOINT;
        let loginInfo = responseLogin.data.data
        axios({
            method: 'GET',
            url: GET_LOV_ENDPOINT,
            headers: {
                "Authorization": "Bearer " + Authorization
            }
        }).then((response) => {
            let tipoPremio = []
            let currencyList = []
                response.data.data.map((element) => {
                    if(element.nombre_dominio === 'TIPO_PREMIO'){
                        element.valores_dominio.map((val) => (
                            tipoPremio.push({
                                "codigo_valor":val.codigo_valor,
                                "presentacion_valor":val.presentacion_valor
                            })
                        ))
                    }
                    if (element.nombre_dominio === 'MONEDA') {
                        element.valores_dominio.map((val) => (
                            currencyList.push({
                                "codigo_valor": val.codigo_valor,
                                "presentacion_valor": val.presentacion_valor
                            })
                        ))
                    }
                })
            localStorage.setItem('tipoPremio', JSON.stringify(tipoPremio))
            localStorage.setItem('currencyList', JSON.stringify(currencyList))
            localStorage.setItem('ListLov', JSON.stringify(response.data.data));
        }).catch(error => {
            let message = undefined
            if(error.response.data.code){
                properties.errorMessage.map((element) => {
                    if(element.value === error.response.data.code){
                        message = error.response.data.code
                    }
                })
            }
            dispath(showErrorModal(true, message))
        });
    }
}