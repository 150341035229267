
import axios from 'axios'
import { ALL_USERS } from '../../Constants';
import { showErrorModal } from '../../Actions/_actionShowErrorModal'
import { loader } from '../../Actions/_actionLoader'
let CancelToken = axios.CancelToken;
let source = CancelToken.source();
export function getUserSearch(id, cancelBool) {
    return dispath => {

        let properties = JSON.parse(localStorage.getItem('properties'));
        let USER_SEACH_ENDPOINT = properties.ENPOINTS.USER_SEACH_ENDPOINT;
        let Authorization = localStorage.getItem('Authorization');
        axios({
            method: 'GET',
            url: USER_SEACH_ENDPOINT,
            cancelToken: source.token,
            params: {
                value: id
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Authorization
            }
        }).then((response) => {
            localStorage.setItem('allUsers', JSON.stringify(response));
            let totalPagePagination = Math.ceil(response.data.data.length / properties.totalRegisterForPagination)

            response.data.data.map(user => {
                user.id = user.user_id
            })
            dispath(loader(false))
            dispath({
                type: ALL_USERS,
                payload: true,
                allUsersObject: response.data.data,
                totalPagePagination: totalPagePagination
            })
        }).catch(error => {
            let message = properties.message
            if (error.response) {
                properties.errorMessage.map((element) => {
                    if (element.value === error.response.data.code) {
                        message = element.message
                    }
                })
            }
            if(error.message !== 'Operation canceled by the user.'){
                dispath(showErrorModal(true, error.response.data.code))
            }
        });
        if(cancelBool){
            source.cancel('Operation canceled by the user.');
            CancelToken = axios.CancelToken;
            source = CancelToken.source();
        }
    }
}
