
import axios from 'axios'
import { ORGANIZATION_BY_USER } from '../../Constants';
import {showErrorModal} from '../../Actions/_actionShowErrorModal'

export function getOrganizationByUser(wordKey) {
    
    return dispath => {
        let properties = JSON.parse(localStorage.getItem('properties'));
        let responseLogin = JSON.parse(localStorage.getItem('responseLogin'))
        let Authorization = localStorage.getItem('Authorization');
        let ORGANIZATION_BYUSER_ENDPOINT = properties.ENPOINTS.ORGANIZATION_BYUSER_ENDPOINT;
        let loginInfo = responseLogin.data.data
        let params = {
            value: loginInfo.user_id,
        }

        if(wordKey !== '' && wordKey !== undefined && wordKey !== null){
            params.palabra_clave = wordKey;
        };
        
        axios({
            method: 'GET',
            url: ORGANIZATION_BYUSER_ENDPOINT,
            params: params,
            headers: {
                "Authorization": "Bearer " + Authorization
            }
        }).then((response) => {
            // var listOrganization = response.data.data
            // localStorage.setItem('organizationByUser', JSON.stringify(response));
            // dispath({
            //     type: ORGANIZATION_BY_USER,
            //     payload: true,
            //     listOrganizationByUser: response.data.data,
            //     totalPagePagination: totalPagePagination
            // })

            localStorage.setItem('organizationByUser', JSON.stringify(response));
            let totalPagePaginationOrganizations = Math.ceil(response.data.data.length / properties.totalRegisterForPagination)
            // dispath(loader(false))
            dispath({
                type: ORGANIZATION_BY_USER,
                payload: true,
                listOrganizationByUser: response.data.data,
                totalPagePaginationOrganizations: totalPagePaginationOrganizations
            })


        }).catch(error => {
            // let message = undefined
            // if(error.response.data.code){
            //     properties.errorMessage.map((element) => {
            //         if(element.value === error.response.data.code){
            //             message = error.response.data.code
            //         }
            //     })
            // }
            // dispath(showErrorModal(true, message))

            dispath({
                type: ORGANIZATION_BY_USER,
                payload: true,
                listOrganizationByUser: false,
                totalPagePaginationOrganizations: null
            })

        });
    }
}
