// DEPENDENCIAS
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { withStyles } from "@material-ui/core/styles";
import { styles } from './constants';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import esLocale from "date-fns/locale/es";
import { ReactComponent as IconEdit } from './img/edit.svg';
import { ReactComponent as IconDelete } from './img/delete.svg';

// CONSTANTES
import {
    awardsTableProps,
    tablePropsBeneficiary,
    executionInvSocialTableProps,
    investmentCountryTableProps,
    investmentAreaTableProps,
    investmentYearTableProps,
} from './constants'

import { postUploadImage } from '../../../../../Actions/Services/postUploadImage'
import { spinnerImageDirection } from '../../../../../Actions/_actionWizardProps'
import { savingJsonTables } from '../../../../../Actions/_actionSavingJsonTables'

const style = styles;
var VARIABLEINDEX = null;
var TABLEUSED = null;
var ADDFIELDSUSED = null;
var icons = []



// COMPONENTE TIPO CLASE
class ComplementaryData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            count: 1,
            inicialCurrentDate: null,
            currentlyEditing: false,
            addFieldsTable1: false,
            addFieldsTable2: false,
            addFieldsTable3: false,
            addFieldsTable4: false,
            addFieldsTable5: false,
            addFieldsTable6: false,
            fieldsAwardsTable: awardsTableProps,
            fieldsTableBeneficiary: tablePropsBeneficiary,
            fieldsExecutionInvSocial: executionInvSocialTableProps,
            fieldsInvestmentCountry: investmentCountryTableProps,
            fieldsInvestmentArea: investmentAreaTableProps,
            fieldsInvestmentYear: investmentYearTableProps,
            awardsTable: [],
            tableBeneficiary: [],
            executionInvSocialTable: [],
            investmentCountryTable: [],
            investmentAreaTable: [],
            investmentYearTable: [],
            jsonTables: [],
        }
    }

    componentWillUnmount = () => {
        let table1 = [];
        let table2 = [];
        let table3 = [];
        let table4 = [];
        let table5 = [];
        let table6 = [];

        this.state.awardsTable.map((element, i) => {
            table1.push({
                "name": element[awardsTableProps[0].name],
                "year": element[awardsTableProps[1].name],
                "type": element[awardsTableProps[2].name],
                "organization": element[awardsTableProps[3].name],
                "country": element[awardsTableProps[4].name],
                "description": element[awardsTableProps[5].name],
            })
        })
        this.state.tableBeneficiary.map((element, i) => {
            table2.push({
                "name2": element[tablePropsBeneficiary[0].name],
                "year2": element[tablePropsBeneficiary[1].name],
                "amount": element[tablePropsBeneficiary[2].name],
            })
        })
        this.state.executionInvSocialTable.map((element, i) => {
            table3.push({
                "countryE": element[executionInvSocialTableProps[0].name],
                "percent": element[executionInvSocialTableProps[1].name],
            })
        })
        this.state.investmentCountryTable.map((element, i) => {
            table4.push({
                "yearIC": element[investmentCountryTableProps[0].name],
                "amountLocalIC": element[investmentCountryTableProps[1].name],
                "nameCurrencyIC": element[investmentCountryTableProps[2].name],
                "amountDollarsIC": element[investmentCountryTableProps[3].name],
                "contryIC": element[investmentCountryTableProps[4].name],
            })
        })
        this.state.investmentAreaTable.map((element, i) => {
            table5.push({
                "yearIA": element[investmentAreaTableProps[0].name],
                "amountLocalIA": element[investmentAreaTableProps[1].name],
                "nameCurrencyIA": element[investmentAreaTableProps[2].name],
                "amountDollarsIA": element[investmentAreaTableProps[3].name],
                "contryIA": element[investmentAreaTableProps[4].name],
            })
        })
        this.state.investmentYearTable.map((element, i) => {
            table6.push({
                "yearIY": element[investmentYearTableProps[0].name],
                "amountLocalIY": element[investmentYearTableProps[1].name],
                "nameCurrencyIY": element[investmentYearTableProps[2].name],
                "amountDollarsIY": element[investmentYearTableProps[3].name],
            })
        })

        this.state.jsonTables.push({
            "awardsTable": table1,
            "tableBeneficiary": table2,
            "executionInvSocialTable": table3,
            "investmentCountryTable": table4,
            "investmentAreaTable": table5,
            "investmentYearTable": table6,
        })
        localStorage.setItem('jsonTables', JSON.stringify(this.state.jsonTables));
        this.props.runApiAndValidationForm()
    }

    componentDidMount = () => {
        let currentDate = new Date()
        this.setState({year: currentDate, year2: currentDate, yearIC: currentDate, yearIA: currentDate, yearIY: currentDate,})

        let ListLov = JSON.parse(localStorage.getItem('ListLov'));
        let countries = JSON.parse(localStorage.getItem('countries'));
        let organizationBeneficiaries = JSON.parse(localStorage.getItem('organizationBeneficiaries'));
        let areaOfInterest = JSON.parse(localStorage.getItem('areaOfInterest'));
        let jsonTables = JSON.parse(localStorage.getItem('jsonTables'));
        let tipoPremio = [];
        let currencyList = [];

        if (ListLov) {
            ListLov.map((element) => {
                if (element.nombre_dominio === 'TIPO_PREMIO') {
                    element.valores_dominio.map((val) => (
                        tipoPremio.push({
                            "codigo_valor": val.codigo_valor,
                            "presentacion_valor": val.presentacion_valor
                        })
                    ))
                }
                if (element.nombre_dominio === 'MONEDA') {
                    element.valores_dominio.map((val) => (
                        currencyList.push({
                            "codigo_valor": val.codigo_valor,
                            "presentacion_valor": val.presentacion_valor
                        })
                    ))
                }
            })
        }
        localStorage.setItem('tipoPremio', JSON.stringify(tipoPremio))
        localStorage.setItem('currencyList', JSON.stringify(currencyList))

        if (jsonTables) {
            this.setState({
                awardsTable: jsonTables[0].awardsTable,
                tableBeneficiary: jsonTables[0].tableBeneficiary,
                executionInvSocialTable: jsonTables[0].executionInvSocialTable,
                investmentCountryTable: jsonTables[0].investmentCountryTable,
                investmentAreaTable: jsonTables[0].investmentAreaTable,
                investmentYearTable: jsonTables[0].investmentYearTable,
            })
        }

        this.setState({
            tipoPremio: tipoPremio,
            countries: countries,
            currencyList: currencyList,
            areaOfInterest: areaOfInterest,
            organizationBeneficiaries: organizationBeneficiaries,
        })

        if (this.props.disable)
            icons = []
        else
            icons = [
                {
                    icono: <IconButton disabled={this.props.disable} style={{ background: '#494377', width: '28px', height: '28px', padding: '6px', marginRight: '2px', blockSize: '30px' }}><IconEdit /></ IconButton>,
                    pointer: 1
                },
                {
                    icono: <IconButton disabled={this.props.disable} style={{ background: '#494377', width: '28px', height: '28px', padding: '6px', marginRight: '2px', blockSize: '30px' }}><IconDelete /></IconButton>,
                    pointer: 2,
                },
            ]
    }

    /**
     * Función que setea los textfields (campos habilitados) para ir llenando cada una de las tablas
     * @param tableMapProps objeto donde se definen las propiedades para cada tabla (en las constantes están los objetos)
     * @param tablePush nombre de la tabla que se está seteando (Ej: awardsTable)
     * @param lengthColumn largo de las columnas de la tabla en cuestión
     * @param addFields Booleano para activar o desactivar los textfields en cada tabla 
     */
    PushNewCell = (tableMapProps, tablePush, lengthColumn, addFields) => {
        let bool = 0;
        let newCell = new Object(tableMapProps.length)

        if ("addFieldsTable1" !== addFields) { this.setState({ addFieldsTable1: false }) }
        if ("addFieldsTable2" !== addFields) { this.setState({ addFieldsTable2: false }) }
        if ("addFieldsTable3" !== addFields) { this.setState({ addFieldsTable3: false }) }
        if ("addFieldsTable4" !== addFields) { this.setState({ addFieldsTable4: false }) }
        if ("addFieldsTable5" !== addFields) { this.setState({ addFieldsTable5: false }) }
        if ("addFieldsTable6" !== addFields) { this.setState({ addFieldsTable6: false }) }

        this.setState({ [addFields]: true })

        this.state[tablePush].map((element, i) => {
            if (TABLEUSED !== null) {
                this.setState({ [TABLEUSED + i]: false })
            }
        })

        //Añadir a la tabla con los textfields habilitados cuando se le de click en el botón "Agregar" de la MISMA tabla.
        tableMapProps.map((element) => {
            if (this.state[element.name] !== undefined && this.state[element.name] !== "" && this.state[element.name] !== null) {
                bool++
            }
            if (bool === lengthColumn) {
                if (addFields === ADDFIELDSUSED) {
                    if (VARIABLEINDEX !== null) {
                        tableMapProps.map((element) => {
                            if (element.type !== "date") {
                                newCell[element.name] = this.state[element.name];
                            } else {
                                newCell[element.name] = this.state[element.name].getFullYear();
                            }
                        })
                        this.state[tablePush][VARIABLEINDEX] = newCell;
                        VARIABLEINDEX = null;
                    } else {
                        tableMapProps.map((element) => {
                            if (element.type !== "date") {
                                newCell[element.name] = this.state[element.name];
                            } else {
                                newCell[element.name] = this.state[element.name].getFullYear()
                            }
                        })
                        this.state[tablePush].push(newCell);
                    }
                }
                tableMapProps.map((element) => {
                    if (element.type !== "date") {
                        if(element.type !== "select"){
                            this.setState({ [element.name]: null })
                        }
                        this.setState({ [element.name]: '' })
                    } else {
                        let typeDateValue = new Date();
                        this.setState({
                            [element.name]: typeDateValue
                        })
                    }
                })
            }
        })

        //Añadir a la tabla con los textfields habilitados cuando se le de click en el botón "Agregar" de otra tabla.
        if (ADDFIELDSUSED !== null) {
            if (addFields !== ADDFIELDSUSED) {
                let namesOfTablesProps = [awardsTableProps, tablePropsBeneficiary, executionInvSocialTableProps, investmentCountryTableProps, investmentAreaTableProps, investmentYearTableProps]
                let nameOfTables = ["awardsTable", "tableBeneficiary", "executionInvSocialTable", "investmentCountryTable", "investmentAreaTable", "investmentYearTable"]
                let numberOfTableIndex = ADDFIELDSUSED.charAt(ADDFIELDSUSED.length - 1)
                let propsTable
                let pushIntoTable
                let bool = true

                for (var i = 0; i <= numberOfTableIndex - 1; i++) {
                    propsTable = namesOfTablesProps[i];
                    pushIntoTable = nameOfTables[i]
                }
                propsTable.map((element) => {
                    if (this.state[element.name] === undefined || this.state[element.name] === "") {
                        bool = false
                    }
                })

                if (bool) {
                    let aux = true

                    this.state[pushIntoTable].map((element, i) => {
                        if (this.state.indexOfTableEdit === i) {
                            aux = false
                        }
                    })

                    if (aux) {
                        propsTable.map((element) => {
                            if (element.type !== "date") {
                                newCell[element.name] = this.state[element.name];
                            } else {
                                newCell[element.name] = this.state[element.name].getFullYear()
                            }
                        })
                        this.state[pushIntoTable].push(newCell);
                    } else{
                        
                        propsTable.map((element) => {
                            if (element.type !== "date") {
                                newCell[element.name] = this.state[element.name];
                            } else {
                                newCell[element.name] = this.state[element.name].getFullYear()
                            }
                        })
                        this.state[pushIntoTable][this.state.indexOfTableEdit] = newCell;
                        VARIABLEINDEX = null;
                    }
                    propsTable.map((element) => {
                        if (element.type !== "date") {
                            if(element.type !== "select"){
                                this.setState({ [element.name]: null })
                            }
                            this.setState({ [element.name]: '' })
                        } else {
                            let typeDateValue = new Date();
                            this.setState({
                                [element.name]: typeDateValue
                            })
                        }
                    })
                }
            }
        }

        ADDFIELDSUSED = addFields
    }

    /**
     * Función que edita las filas de las tablas
     * @param index indice donde se quiere editar
     * @param tableMapProps objeto donde se definen las propiedades para cada tabla (en las constantes están los objetos)
     * @param tablePush nombre de la tabla que se está seteando (Ej: awardsTable)
     * @param tableNumber identidicador de la tabla a editar (Ej: table1)
     * @param addFields Booleano para activar o desactivar los textfields en cada tabla 
     */
    editCell = (index, tableMapProps, tablePush, tableNumber, addFields) => {
        // let newCell = new Object(tableMapProps.length)
        // alert(this.state[tablePush])
        // this.state[tablePush].map((element) => {
        //     this.setState({[tableMapProps[element.name]]: ''})
        // })
        this.setState({ indexOfTableEdit: index })
        ADDFIELDSUSED = addFields

        this.state[tablePush].map((element, i) => {
            if (TABLEUSED !== null) {
                this.setState({ [TABLEUSED + i]: false })
            }
        })
        TABLEUSED = tableNumber;
        VARIABLEINDEX = index;

        let valuesCell = this.state[tablePush][index]
        this.state[tablePush].map((element, i) => {
            this.setState({ [tableNumber + i]: false })
        })

        tableMapProps.map((element) => {
            if (element.type !== "date") {
                this.setState({
                    [element.name]: valuesCell[element.name]
                })
            } else {
                let typeDateValue = new Date()
                typeDateValue.setFullYear(valuesCell[element.name])
                this.setState({
                    [element.name]: typeDateValue
                })
            }

        })

        this.setState({
            addFieldsTable1: false,
            addFieldsTable2: false,
            addFieldsTable3: false,
            addFieldsTable4: false,
            addFieldsTable5: false,
            addFieldsTable6: false,
            [tableNumber + index]: true,
        })
    }

    deleteCell = (index, tablePush, tableNumber) => {
        let indexOfClick
        this.state[tablePush].map((element, i) => {
            if (this.state[tableNumber + i]) {
                indexOfClick = i
            }
        })
        if (indexOfClick !== index) {
            this.setState({
                addFieldsTable1: false,
                addFieldsTable2: false,
                addFieldsTable3: false,
                addFieldsTable4: false,
                addFieldsTable5: false,
                addFieldsTable6: false,
            })
            this.state[tablePush].map((element, i) => {
                this.setState({ [tableNumber + i]: false })
            })
            if (index > -1) {
                this.state[tablePush].splice(index, 1);
                this.setState({ [tablePush]: this.state[tablePush] })
            }
        }
    }

    onChangeValues = (event) => {
        var value = event.target.value;
        var name = event.target.name;
        this.setState({
            [name]: value,
        })
    }

    actions = (event, pointer, index, tableMapProps, tablePush, tableNumber, addFields) => {
        switch (pointer) {
            case 1: //Edit row
                this.editCell(index, tableMapProps, tablePush, tableNumber, addFields)
                break;
            case 2: //Delete row
                this.deleteCell(index, tablePush, tableNumber)
                break;
            default:
                break;
        }
    }

    handleDateChange = (dateSelect, value, name) => {

        this.setState({
            [name]: dateSelect,
        })
    };

    render() {
        const { classes } = this.props;

        return (
            <>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Datos Complementarios del Agente
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.grid1}>
                        <Grid container>
                            <Grid item xs={10}>
                                <Typography variant="h6">
                                    <strong>Reconocimientos Recibidos</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                {this.props.disable ?
                                    ''
                                    :
                                    <Button
                                        margin="dense"
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        disabled={this.props.disable}
                                        className={classes.styleButton}
                                    >
                                        <strong onClick={(event) => this.PushNewCell(awardsTableProps, "awardsTable", 6, "addFieldsTable1")}>+Agregar</strong>
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                        <hr />
                        <Grid container >
                            <TableContainer className={classes.marginTable}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell size="small" align="left"><strong>Nombre</strong></TableCell>
                                            <TableCell size="small" align="left"><strong>Año</strong></TableCell>
                                            <TableCell size="small" align="left"><strong>Tipo</strong></TableCell>
                                            <TableCell size="small" align="left"><strong>Organización</strong></TableCell>
                                            <TableCell size="small" align="left"><strong>País</strong></TableCell>
                                            <TableCell size="small" align="left"><strong>Descripción</strong></TableCell>
                                            <TableCell size="small" align="left"><strong></strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody id='tableBody'>
                                        {this.state.awardsTable !== [] &&
                                            <>
                                                {this.state.awardsTable.map((element, index) => (
                                                    <TableRow >
                                                        <TableCell size="small" align="left">
                                                            {this.state['table1' + index] ?

                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    margin="dense"
                                                                    name={awardsTableProps[0].name}
                                                                    value={this.state[awardsTableProps[0].name]}
                                                                    onChange={(event) => this.onChangeValues(event)}
                                                                />
                                                                :
                                                                element[awardsTableProps[0].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="left">
                                                            {this.state['table1' + index] ?
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                                                    <KeyboardDatePicker
                                                                        inputVariant="outlined"
                                                                        margin="dense"
                                                                        color="primary"
                                                                        views={["year"]}
                                                                        format="yyyy"
                                                                        invalidDateMessage="Formato inválido"
                                                                        maxDateMessage="Superó el límite máximo"
                                                                        minDateMessage="Superó el límite minimo"
                                                                        disablePast={this.props.disable}
                                                                        disableFuture={this.props.disable}
                                                                        inputProps={{ readOnly: this.props.disable }}
                                                                        name={awardsTableProps[1].name}
                                                                        value={this.state[awardsTableProps[1].name]}
                                                                        InputAdornmentProps={{ position: "end" }}
                                                                        onChange={(value, date) => this.handleDateChange(value, date, awardsTableProps[1].name)}
                                                                        KeyboardButtonProps={{
                                                                            'aria-label': 'change date',
                                                                            'color': 'primary'
                                                                        }}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                                :
                                                                element[awardsTableProps[1].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="left">
                                                            {this.state['table1' + index] ?
                                                                <FormControl>
                                                                    {/* <InputLabel>{awardsTableProps[2].label}</InputLabel> */}
                                                                    <Select
                                                                        id="outlined-size-small"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        margin="dense"
                                                                        name={awardsTableProps[2].name}
                                                                        value={this.state[awardsTableProps[2].name]}
                                                                        onChange={(event) => this.onChangeValues(event)}
                                                                    >
                                                                        {this.state.tipoPremio &&
                                                                            this.state.tipoPremio.map((element) => (
                                                                                <MenuItem key={element.codigo_valor} value={element.presentacion_valor}>{element.presentacion_valor}</MenuItem>
                                                                            ))}
                                                                    </Select>
                                                                </FormControl>
                                                                :
                                                                element[awardsTableProps[2].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="left">
                                                            {this.state['table1' + index] ?
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    margin="dense"
                                                                    name={awardsTableProps[3].name}
                                                                    value={this.state[awardsTableProps[3].name]}
                                                                    onChange={(event) => this.onChangeValues(event)}
                                                                />
                                                                :
                                                                element[awardsTableProps[3].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="left">
                                                            {this.state['table1' + index] ?
                                                                <FormControl>
                                                                    {/* <InputLabel>{awardsTableProps[2].label}</InputLabel> */}
                                                                    <Select
                                                                        id="outlined-size-small"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        margin="dense"
                                                                        name={awardsTableProps[4].name}
                                                                        value={this.state[awardsTableProps[4].name]}
                                                                        onChange={(event) => this.onChangeValues(event)}
                                                                    >
                                                                        {this.state.countries &&
                                                                            this.state.countries.map((element) => (
                                                                                <MenuItem key={element.id_pais} value={element.nombre_pais}>{element.nombre_pais}</MenuItem>
                                                                            ))}
                                                                    </Select>
                                                                </FormControl>
                                                                :
                                                                element[awardsTableProps[4].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="left">
                                                            {this.state['table1' + index] ?
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    margin="dense"
                                                                    name={awardsTableProps[5].name}
                                                                    value={this.state[awardsTableProps[5].name]}
                                                                    onChange={(event) => this.onChangeValues(event)}
                                                                />
                                                                :
                                                                element[awardsTableProps[5].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="center">
                                                            {icons.map((icon) => (
                                                                <a onClick={(event) => this.actions(event, icon.pointer, index, awardsTableProps, "awardsTable", "table1", "addFieldsTable1")}>{icon.icono}</a>
                                                            ))}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </>
                                        }
                                        <TableRow>
                                            {this.state.addFieldsTable1 &&
                                                <>
                                                    {this.state.fieldsAwardsTable.map((element) => (
                                                        <>
                                                            {element.type !== "select" ?
                                                                <>
                                                                    {element.type !== "date" ?
                                                                        <TableCell padding="default" size="small" align="right">
                                                                            <TextField
                                                                                id="outlined-size-small"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                margin="dense"
                                                                                type={element.format}
                                                                                name={element.name}
                                                                                value={this.state[element.name]}
                                                                                onChange={(event) => this.onChangeValues(event)}
                                                                            />
                                                                        </TableCell>
                                                                        :
                                                                        <TableCell padding="default" size="small" align="right">
                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                                                                <KeyboardDatePicker
                                                                                    inputVariant="outlined"
                                                                                    margin="dense"
                                                                                    color="primary"
                                                                                    views={["year"]}
                                                                                    format="yyyy"
                                                                                    name={element.name}
                                                                                    invalidDateMessage="Formato inválido"
                                                                                    maxDateMessage="Superó el límite máximo"
                                                                                    minDateMessage="Superó el límite minimo"
                                                                                    disablePast={this.props.disable}
                                                                                    disableFuture={this.props.disable}
                                                                                    inputProps={{ readOnly: this.props.disable }}
                                                                                    value={this.state[element.name]}
                                                                                    InputAdornmentProps={{ position: "end" }}
                                                                                    onChange={(value, date) => this.handleDateChange(value, date, element.name)}
                                                                                    KeyboardButtonProps={{
                                                                                        'aria-label': 'change date',
                                                                                        'color': 'primary'
                                                                                    }}
                                                                                />
                                                                            </MuiPickersUtilsProvider>
                                                                        </TableCell>
                                                                    }
                                                                </>
                                                                :
                                                                <TableCell padding="default" size="small" align="right">
                                                                    <FormControl>
                                                                        {/* <InputLabel>{element.label}</InputLabel> */}
                                                                        <Select
                                                                            id="outlined-size-small"
                                                                            variant="outlined"
                                                                            size="small"
                                                                            margin="dense"
                                                                            name={element.name}
                                                                            value={this.state[element.name]}
                                                                            onChange={(event) => this.onChangeValues(event)}
                                                                        >
                                                                            {this.state[element.nameOptions] &&
                                                                                this.state[element.nameOptions].map((val) => (
                                                                                    <MenuItem key={val[element.nameValueKey]} value={val[element.nameMessage]}>{val[element.nameMessage]}</MenuItem>
                                                                                ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </TableCell>
                                                            }
                                                        </>
                                                    ))}
                                                </>
                                            }
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.root}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Ejecución de Inversión Social
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.grid1}>
                        <Grid container>
                            <Grid item xs={10}>
                                <Typography variant="h6">
                                    <strong>Beneficiario</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                {this.props.disable ?
                                    ''
                                    :
                                    <Button
                                        margin="dense"
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={this.props.disable}
                                        className={classes.styleButton}
                                    >
                                        <strong onClick={(event) => this.PushNewCell(tablePropsBeneficiary, "tableBeneficiary", 3, "addFieldsTable2")}>+Agregar</strong>
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                        <hr />
                        <Grid container >
                            <TableContainer className={classes.marginTable}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell size="small" align="center"><strong>Nombre</strong></TableCell>
                                            <TableCell size="small" align="center"><strong>Año</strong></TableCell>
                                            <TableCell size="small" align="center"><strong>Monto</strong></TableCell>
                                            <TableCell size="small" align="center"><strong></strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody id='tableBody'>
                                        {this.state.tableBeneficiary !== [] &&
                                            <>
                                                {this.state.tableBeneficiary.map((element, index) => (
                                                    <TableRow >
                                                        <TableCell size="small" align="center">
                                                            {this.state['table2' + index] ?
                                                                <FormControl>
                                                                    {/* <InputLabel>{awardsTableProps[2].label}</InputLabel> */}
                                                                    <Select
                                                                        id="outlined-size-small"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        margin="dense"
                                                                        name={tablePropsBeneficiary[0].name}
                                                                        value={this.state[tablePropsBeneficiary[0].name]}
                                                                        onChange={(event) => this.onChangeValues(event)}
                                                                    >
                                                                        {this.state.organizationBeneficiaries &&
                                                                            this.state.organizationBeneficiaries.map((element) => (
                                                                                <MenuItem key={element.id_beneficiario} value={element.nombre_beneficiario}>{element.nombre_beneficiario}</MenuItem>
                                                                            ))}
                                                                    </Select>
                                                                </FormControl>
                                                                :
                                                                element[tablePropsBeneficiary[0].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="center">
                                                            {this.state['table2' + index] ?
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                                                    <KeyboardDatePicker
                                                                        inputVariant="outlined"
                                                                        margin="dense"
                                                                        color="primary"
                                                                        views={["year"]}
                                                                        format="yyyy"
                                                                        invalidDateMessage="Formato inválido"
                                                                        maxDateMessage="Superó el límite máximo"
                                                                        minDateMessage="Superó el límite minimo"
                                                                        disablePast={this.props.disable}
                                                                        disableFuture={this.props.disable}
                                                                        inputProps={{ readOnly: this.props.disable }}
                                                                        name={tablePropsBeneficiary[1].name}
                                                                        value={this.state[tablePropsBeneficiary[1].name]}
                                                                        InputAdornmentProps={{ position: "end" }}
                                                                        onChange={(value, date) => this.handleDateChange(value, date, tablePropsBeneficiary[1].name)}
                                                                        KeyboardButtonProps={{
                                                                            'aria-label': 'change date',
                                                                            'color': 'primary'
                                                                        }}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                                :
                                                                element[tablePropsBeneficiary[1].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="center">
                                                            {this.state['table2' + index] ?
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    margin="dense"
                                                                    type={tablePropsBeneficiary[2].format}
                                                                    name={tablePropsBeneficiary[2].name}
                                                                    value={this.state[tablePropsBeneficiary[2].name]}
                                                                    onChange={(event) => this.onChangeValues(event)}
                                                                />
                                                                :
                                                                element[tablePropsBeneficiary[2].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="right">
                                                            {icons.map((icon) => (
                                                                <a onClick={(event) => this.actions(event, icon.pointer, index, tablePropsBeneficiary, "tableBeneficiary", "table2", "addFieldsTable2")}>{icon.icono}</a>
                                                            ))}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </>
                                        }
                                        <TableRow>
                                            {this.state.addFieldsTable2 &&
                                                <>
                                                    {this.state.fieldsTableBeneficiary.map((element) => (
                                                        <>
                                                            {element.type !== "select" ?
                                                                <>
                                                                    {element.type !== "date" ?
                                                                        <TableCell padding="default" size="small" align="right">
                                                                            <TextField
                                                                                id="outlined-size-small"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                margin="dense"
                                                                                type={element.format}
                                                                                name={element.name}
                                                                                value={this.state[element.name]}
                                                                                onChange={(event) => this.onChangeValues(event)}
                                                                            />
                                                                        </TableCell>
                                                                        :
                                                                        <TableCell padding="default" size="small" align="right">
                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                                                                <KeyboardDatePicker
                                                                                    inputVariant="outlined"
                                                                                    margin="dense"
                                                                                    color="primary"
                                                                                    views={["year"]}
                                                                                    format="yyyy"
                                                                                    name={element.name}
                                                                                    invalidDateMessage="Formato inválido"
                                                                                    maxDateMessage="Superó el límite máximo"
                                                                                    minDateMessage="Superó el límite minimo"
                                                                                    disablePast={this.props.disable}
                                                                                    disableFuture={this.props.disable}
                                                                                    inputProps={{ readOnly: this.props.disable }}
                                                                                    value={this.state[element.name]}
                                                                                    InputAdornmentProps={{ position: "end" }}
                                                                                    onChange={(value, date) => this.handleDateChange(value, date, element.name)}
                                                                                    KeyboardButtonProps={{
                                                                                        'aria-label': 'change date',
                                                                                        'color': 'primary'
                                                                                    }}
                                                                                />
                                                                            </MuiPickersUtilsProvider>
                                                                        </TableCell>
                                                                    }
                                                                </>
                                                                :
                                                                <TableCell padding="default" size="small" align="right">
                                                                    <FormControl>
                                                                        {/* <InputLabel>{element.label}</InputLabel> */}
                                                                        <Select
                                                                            id="outlined-size-small"
                                                                            variant="outlined"
                                                                            size="small"
                                                                            margin="dense"
                                                                            name={element.name}
                                                                            value={this.state[element.name]}
                                                                            onChange={(event) => this.onChangeValues(event)}
                                                                        >
                                                                            {this.state[element.nameOptions] &&
                                                                                this.state[element.nameOptions].map((val) => (
                                                                                    <MenuItem key={val[element.nameValueKey]} value={val[element.nameMessage]}>{val[element.nameMessage]}</MenuItem>
                                                                                ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </TableCell>
                                                            }
                                                        </>
                                                    ))}
                                                </>
                                            }
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.grid1}>
                        <Grid container container >
                            <Grid item xs={10}>
                                <Typography variant="h6">
                                    <strong>Origen de los fondos</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                {this.props.disable ?
                                    ''
                                    :
                                    <Button
                                        margin="dense"
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        disabled={this.props.disable}
                                        className={classes.styleButton}
                                    >
                                        <strong onClick={(event) => this.PushNewCell(executionInvSocialTableProps, "executionInvSocialTable", 2, "addFieldsTable3")}>+Agregar</strong>

                                    </Button>
                                }
                            </Grid>
                        </Grid>
                        <hr />
                        <Grid container >
                            <TableContainer className={classes.marginTable}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell size="small" align="center"><strong>País</strong></TableCell>
                                            <TableCell size="small" align="center"><strong>Porcentaje</strong></TableCell>
                                            <TableCell size="small" align="center"><strong></strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody id='tableBody'>
                                        {this.state.executionInvSocialTable !== [] &&
                                            <>
                                                {this.state.executionInvSocialTable.map((element, index) => (
                                                    <TableRow >
                                                        <TableCell size="small" align="center">
                                                            {this.state['table3' + index] ?
                                                                <FormControl>
                                                                    {/* <InputLabel>{awardsTableProps[2].label}</InputLabel> */}
                                                                    <Select
                                                                        id="outlined-size-small"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        margin="dense"
                                                                        name={executionInvSocialTableProps[0].name}
                                                                        value={this.state[executionInvSocialTableProps[0].name]}
                                                                        onChange={(event) => this.onChangeValues(event)}
                                                                    >
                                                                        {this.state.countries &&
                                                                            this.state.countries.map((element) => (
                                                                                <MenuItem key={element.id_pais} value={element.nombre_pais}>{element.nombre_pais}</MenuItem>
                                                                            ))}
                                                                    </Select>
                                                                </FormControl>
                                                                :
                                                                element[executionInvSocialTableProps[0].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="center">
                                                            {this.state['table3' + index] ?
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    margin="dense"
                                                                    type={executionInvSocialTableProps[1].format}
                                                                    name={executionInvSocialTableProps[1].name}
                                                                    value={this.state[executionInvSocialTableProps[1].name]}
                                                                    onChange={(event) => this.onChangeValues(event)}
                                                                />
                                                                :
                                                                element[executionInvSocialTableProps[1].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="center">
                                                            {icons.map((icon) => (
                                                                <a onClick={(event) => this.actions(event, icon.pointer, index, executionInvSocialTableProps, "executionInvSocialTable", "table3", "addFieldsTable3")}>{icon.icono}</a>
                                                            ))}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </>
                                        }
                                        <TableRow>
                                            {this.state.addFieldsTable3 &&
                                                <>
                                                    {this.state.fieldsExecutionInvSocial.map((element) => (
                                                        <>
                                                            {element.type !== "select" ?
                                                                <TableCell padding="default" size="small" align="center">
                                                                    <TextField
                                                                        id="outlined-size-small"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        margin="dense"
                                                                        type={element.format}
                                                                        name={element.name}
                                                                        value={this.state[element.name]}
                                                                        onChange={(event) => this.onChangeValues(event)}
                                                                    />
                                                                </TableCell>
                                                                :
                                                                <TableCell padding="default" size="small" align="center">
                                                                    <FormControl>
                                                                        {/* <InputLabel>{element.label}</InputLabel> */}
                                                                        <Select
                                                                            id="outlined-size-small"
                                                                            variant="outlined"
                                                                            size="small"
                                                                            margin="dense"
                                                                            name={element.name}
                                                                            value={this.state[element.name]}
                                                                            onChange={(event) => this.onChangeValues(event)}
                                                                        >
                                                                            {this.state[element.nameOptions] &&
                                                                                this.state[element.nameOptions].map((val) => (
                                                                                    <MenuItem key={val[element.nameValueKey]} value={val[element.nameMessage]}>{val[element.nameMessage]}</MenuItem>
                                                                                ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </TableCell>
                                                            }
                                                        </>
                                                    ))}
                                                </>
                                            }
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.grid1}>
                        <Grid container container >
                            <Grid item xs={10}>
                                <Typography variant="h6">
                                    <strong>Inversión Social País</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                {this.props.disable ?
                                    ''
                                    :
                                    <Button
                                        margin="dense"
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        disabled={this.props.disable}
                                        className={classes.styleButton}
                                    >
                                        <strong onClick={(event) => this.PushNewCell(investmentCountryTableProps, "investmentCountryTable", 5, "addFieldsTable4")}>+Agregar</strong>
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                        <hr />
                        <Grid container >
                            <TableContainer className={classes.marginTable}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell size="small" align="center"><strong>Año</strong></TableCell>
                                            <TableCell size="small" align="center"><strong>Monto Moneda Local</strong></TableCell>
                                            <TableCell size="small" align="center"><strong>Moneda Local</strong></TableCell>
                                            <TableCell size="small" align="center"><strong>Monto en Dolares</strong></TableCell>
                                            <TableCell size="small" align="center"><strong>País</strong></TableCell>
                                            <TableCell size="small" align="center"><strong></strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody id='tableBody'>
                                        {this.state.investmentCountryTable !== [] &&
                                            <>
                                                {this.state.investmentCountryTable.map((element, index) => (
                                                    <TableRow >
                                                        <TableCell size="small" align="center">
                                                            {this.state['table4' + index] ?
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                                                    <KeyboardDatePicker
                                                                        inputVariant="outlined"
                                                                        margin="dense"
                                                                        color="primary"
                                                                        views={["year"]}
                                                                        format="yyyy"
                                                                        invalidDateMessage="Formato inválido"
                                                                        maxDateMessage="Superó el límite máximo"
                                                                        minDateMessage="Superó el límite minimo"
                                                                        disablePast={this.props.disable}
                                                                        disableFuture={this.props.disable}
                                                                        inputProps={{ readOnly: this.props.disable }}
                                                                        name={investmentCountryTableProps[0].name}
                                                                        value={this.state[investmentCountryTableProps[0].name]}
                                                                        InputAdornmentProps={{ position: "end" }}
                                                                        onChange={(value, date) => this.handleDateChange(value, date, investmentCountryTableProps[0].name)}
                                                                        KeyboardButtonProps={{
                                                                            'aria-label': 'change date',
                                                                            'color': 'primary'
                                                                        }}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                                :
                                                                element[investmentCountryTableProps[0].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="center">
                                                            {this.state['table4' + index] ?
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    margin="dense"
                                                                    type={investmentCountryTableProps[1].format}
                                                                    name={investmentCountryTableProps[1].name}
                                                                    value={this.state[investmentCountryTableProps[1].name]}
                                                                    onChange={(event) => this.onChangeValues(event)}
                                                                />
                                                                :
                                                                element[investmentCountryTableProps[1].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="center">
                                                            {this.state['table4' + index] ?
                                                                <FormControl>
                                                                    {/* <InputLabel>{awardsTableProps[2].label}</InputLabel> */}
                                                                    <Select
                                                                        id="outlined-size-small"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        margin="dense"
                                                                        name={investmentCountryTableProps[2].name}
                                                                        value={this.state[investmentCountryTableProps[2].name]}
                                                                        onChange={(event) => this.onChangeValues(event)}
                                                                    >
                                                                        {this.state.currencyList &&
                                                                            this.state.currencyList.map((element) => (
                                                                                <MenuItem key={element.codigo_valor} value={element.presentacion_valor}>{element.presentacion_valor}</MenuItem>
                                                                            ))}
                                                                    </Select>
                                                                </FormControl>
                                                                :
                                                                element[investmentCountryTableProps[2].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="center">
                                                            {this.state['table4' + index] ?
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    margin="dense"
                                                                    type={investmentCountryTableProps[3].format}
                                                                    name={investmentCountryTableProps[3].name}
                                                                    value={this.state[investmentCountryTableProps[3].name]}
                                                                    onChange={(event) => this.onChangeValues(event)}
                                                                />
                                                                :
                                                                element[investmentCountryTableProps[3].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="center">
                                                            {this.state['table4' + index] ?
                                                                <FormControl>
                                                                    {/* <InputLabel>{awardsTableProps[2].label}</InputLabel> */}
                                                                    <Select
                                                                        id="outlined-size-small"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        margin="dense"
                                                                        name={investmentCountryTableProps[4].name}
                                                                        value={this.state[investmentCountryTableProps[4].name]}
                                                                        onChange={(event) => this.onChangeValues(event)}
                                                                    >
                                                                        {this.state.countries &&
                                                                            this.state.countries.map((element) => (
                                                                                <MenuItem key={element.id_pais} value={element.nombre_pais}>{element.nombre_pais}</MenuItem>
                                                                            ))}
                                                                    </Select>
                                                                </FormControl>
                                                                :
                                                                element[investmentCountryTableProps[4].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="center">
                                                            {icons.map((icon) => (
                                                                <a onClick={(event) => this.actions(event, icon.pointer, index, investmentCountryTableProps, "investmentCountryTable", "table4", "addFieldsTable4")}>{icon.icono}</a>
                                                            ))}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </>
                                        }
                                        <TableRow>
                                            {this.state.addFieldsTable4 &&
                                                <>
                                                    {this.state.fieldsInvestmentCountry.map((element) => (
                                                        <>
                                                            {element.type !== "select" ?
                                                                <>
                                                                    {element.type !== "date" ?
                                                                        <TableCell padding="default" size="small" align="center">
                                                                            <TextField
                                                                                id="outlined-size-small"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                margin="dense"
                                                                                type={element.format}
                                                                                name={element.name}
                                                                                value={this.state[element.name]}
                                                                                onChange={(event) => this.onChangeValues(event)}
                                                                            />
                                                                        </TableCell>
                                                                        :
                                                                        <TableCell padding="default" size="small" align="right">
                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                                                                <KeyboardDatePicker
                                                                                    inputVariant="outlined"
                                                                                    margin="dense"
                                                                                    color="primary"
                                                                                    views={["year"]}
                                                                                    format="yyyy"
                                                                                    name={element.name}
                                                                                    invalidDateMessage="Formato inválido"
                                                                                    maxDateMessage="Superó el límite máximo"
                                                                                    minDateMessage="Superó el límite minimo"
                                                                                    disablePast={this.props.disable}
                                                                                    disableFuture={this.props.disable}
                                                                                    inputProps={{ readOnly: this.props.disable }}
                                                                                    value={this.state[element.name]}
                                                                                    InputAdornmentProps={{ position: "end" }}
                                                                                    onChange={(value, date) => this.handleDateChange(value, date, element.name)}
                                                                                    KeyboardButtonProps={{
                                                                                        'aria-label': 'change date',
                                                                                        'color': 'primary'
                                                                                    }}
                                                                                />
                                                                            </MuiPickersUtilsProvider>
                                                                        </TableCell>
                                                                    }
                                                                </>
                                                                :
                                                                <TableCell padding="default" size="small" align="center">
                                                                    <FormControl>
                                                                        {/* <InputLabel>{element.label}</InputLabel> */}
                                                                        <Select
                                                                            id="outlined-size-small"
                                                                            variant="outlined"
                                                                            size="small"
                                                                            margin="dense"
                                                                            name={element.name}
                                                                            value={this.state[element.name]}
                                                                            onChange={(event) => this.onChangeValues(event)}
                                                                        >
                                                                            {this.state[element.nameOptions] &&
                                                                                this.state[element.nameOptions].map((val) => (
                                                                                    <MenuItem key={val[element.nameValueKey]} value={val[element.nameMessage]}>{val[element.nameMessage]}</MenuItem>
                                                                                ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </TableCell>
                                                            }
                                                        </>
                                                    ))}
                                                </>
                                            }
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.grid1}>
                        <Grid container container >
                            <Grid item xs={10}>
                                <Typography variant="h6">
                                    <strong>Inversión Social Area Temática</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                {this.props.disable ?
                                    ''
                                    :
                                    <Button
                                        margin="dense"
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        disabled={this.props.disable}
                                        className={classes.styleButton}
                                    >
                                        <strong onClick={(event) => this.PushNewCell(investmentAreaTableProps, "investmentAreaTable", 5, "addFieldsTable5")}>+Agregar</strong>
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                        <hr />
                        <Grid container >
                            <TableContainer className={classes.marginTable}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell size="small" align="center"><strong>Año</strong></TableCell>
                                            <TableCell size="small" align="center"><strong>Monto Moneda Local</strong></TableCell>
                                            <TableCell size="small" align="center"><strong>Moneda Local</strong></TableCell>
                                            <TableCell size="small" align="center"><strong>Monto en Dolares</strong></TableCell>
                                            <TableCell size="small" align="center"><strong>Área Temática</strong></TableCell>
                                            <TableCell size="small" align="center"><strong></strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody id='tableBody'>
                                        {this.state.investmentAreaTable !== [] &&
                                            <>
                                                {this.state.investmentAreaTable.map((element, index) => (
                                                    <TableRow >
                                                        <TableCell size="small" align="center">
                                                            {this.state['table5' + index] ?
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                                                    <KeyboardDatePicker
                                                                        inputVariant="outlined"
                                                                        margin="dense"
                                                                        color="primary"
                                                                        views={["year"]}
                                                                        format="yyyy"
                                                                        invalidDateMessage="Formato inválido"
                                                                        maxDateMessage="Superó el límite máximo"
                                                                        minDateMessage="Superó el límite minimo"
                                                                        disablePast={this.props.disable}
                                                                        disableFuture={this.props.disable}
                                                                        inputProps={{ readOnly: this.props.disable }}
                                                                        name={investmentAreaTableProps[0].name}
                                                                        value={this.state[investmentAreaTableProps[0].name]}
                                                                        InputAdornmentProps={{ position: "end" }}
                                                                        onChange={(value, date) => this.handleDateChange(value, date, investmentAreaTableProps[0].name)}
                                                                        KeyboardButtonProps={{
                                                                            'aria-label': 'change date',
                                                                            'color': 'primary'
                                                                        }}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                                :
                                                                element[investmentAreaTableProps[0].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="center">
                                                            {this.state['table5' + index] ?
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    margin="dense"
                                                                    type={investmentAreaTableProps[1].format}
                                                                    name={investmentAreaTableProps[1].name}
                                                                    value={this.state[investmentAreaTableProps[1].name]}
                                                                    onChange={(event) => this.onChangeValues(event)}
                                                                />
                                                                :
                                                                element[investmentAreaTableProps[1].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="center">
                                                            {this.state['table5' + index] ?
                                                                <FormControl>
                                                                    {/* <InputLabel>{awardsTableProps[2].label}</InputLabel> */}
                                                                    <Select
                                                                        id="outlined-size-small"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        margin="dense"
                                                                        name={investmentAreaTableProps[2].name}
                                                                        value={this.state[investmentAreaTableProps[2].name]}
                                                                        onChange={(event) => this.onChangeValues(event)}
                                                                    >
                                                                        {this.state.currencyList &&
                                                                            this.state.currencyList.map((element) => (
                                                                                <MenuItem key={element.codigo_valor} value={element.presentacion_valor}>{element.presentacion_valor}</MenuItem>
                                                                            ))}
                                                                    </Select>
                                                                </FormControl>
                                                                :
                                                                element[investmentAreaTableProps[2].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="center">
                                                            {this.state['table5' + index] ?
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    margin="dense"
                                                                    type={investmentAreaTableProps[3].format}
                                                                    name={investmentAreaTableProps[3].name}
                                                                    value={this.state[investmentAreaTableProps[3].name]}
                                                                    onChange={(event) => this.onChangeValues(event)}
                                                                />
                                                                :
                                                                element[investmentAreaTableProps[3].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="center">
                                                            {this.state['table5' + index] ?
                                                                <FormControl>
                                                                    {/* <InputLabel>{awardsTableProps[2].label}</InputLabel> */}
                                                                    <Select
                                                                        id="outlined-size-small"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        margin="dense"
                                                                        name={investmentAreaTableProps[4].name}
                                                                        value={this.state[investmentAreaTableProps[4].name]}
                                                                        onChange={(event) => this.onChangeValues(event)}
                                                                    >
                                                                        {this.state.areaOfInterest &&
                                                                            this.state.areaOfInterest.map((element) => (
                                                                                <MenuItem key={element.id_areainteres} value={element.descripcion_areainteres}>{element.descripcion_areainteres}</MenuItem>
                                                                            ))}
                                                                    </Select>
                                                                </FormControl>
                                                                :
                                                                element[investmentAreaTableProps[4].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="center">
                                                            {icons.map((icon) => (
                                                                <a onClick={(event) => this.actions(event, icon.pointer, index, investmentAreaTableProps, "investmentAreaTable", "table5", "addFieldsTable5")}>{icon.icono}</a>
                                                            ))}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </>
                                        }
                                        <TableRow>
                                            {this.state.addFieldsTable5 &&
                                                <>
                                                    {this.state.fieldsInvestmentArea.map((element) => (
                                                        <>
                                                            {element.type !== "select" ?
                                                                <>
                                                                    {element.type !== "date" ?
                                                                        <TableCell padding="default" size="small" align="center">
                                                                            <TextField
                                                                                id="outlined-size-small"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                margin="dense"
                                                                                type={element.format}
                                                                                name={element.name}
                                                                                value={this.state[element.name]}
                                                                                onChange={(event) => this.onChangeValues(event)}
                                                                            />
                                                                        </TableCell>
                                                                        :
                                                                        <TableCell padding="default" size="small" align="right">
                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                                                                <KeyboardDatePicker
                                                                                    inputVariant="outlined"
                                                                                    margin="dense"
                                                                                    color="primary"
                                                                                    views={["year"]}
                                                                                    format="yyyy"
                                                                                    name={element.name}
                                                                                    invalidDateMessage="Formato inválido"
                                                                                    maxDateMessage="Superó el límite máximo"
                                                                                    minDateMessage="Superó el límite minimo"
                                                                                    disablePast={this.props.disable}
                                                                                    disableFuture={this.props.disable}
                                                                                    inputProps={{ readOnly: this.props.disable }}
                                                                                    value={this.state[element.name]}
                                                                                    InputAdornmentProps={{ position: "end" }}
                                                                                    onChange={(value, date) => this.handleDateChange(value, date, element.name)}
                                                                                    KeyboardButtonProps={{
                                                                                        'aria-label': 'change date',
                                                                                        'color': 'primary'
                                                                                    }}
                                                                                />
                                                                            </MuiPickersUtilsProvider>
                                                                        </TableCell>
                                                                    }
                                                                </>
                                                                :
                                                                <TableCell padding="default" size="small" align="center">
                                                                    <FormControl>
                                                                        {/* <InputLabel>{element.label}</InputLabel> */}
                                                                        <Select
                                                                            id="outlined-size-small"
                                                                            variant="outlined"
                                                                            size="small"
                                                                            margin="dense"
                                                                            name={element.name}
                                                                            value={this.state[element.name]}
                                                                            onChange={(event) => this.onChangeValues(event)}
                                                                        >
                                                                            {this.state[element.nameOptions] &&
                                                                                this.state[element.nameOptions].map((val) => (
                                                                                    <MenuItem key={val[element.nameValueKey]} value={val[element.nameMessage]}>{val[element.nameMessage]}</MenuItem>
                                                                                ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </TableCell>
                                                            }
                                                        </>
                                                    ))}
                                                </>
                                            }
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.grid1}>
                        <Grid container>
                            <Grid item xs={10}>
                                <Typography variant="h6">
                                    <strong>Inversión Social Año</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                {this.props.disable ?
                                    ''
                                    :
                                    <Button
                                        margin="dense"
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        disabled={this.props.disable}
                                        className={classes.styleButton}
                                    >
                                        <strong onClick={(event) => this.PushNewCell(investmentYearTableProps, "investmentYearTable", 4, "addFieldsTable6")}>+Agregar</strong>
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                        <hr />
                        <Grid container >
                            <TableContainer className={classes.marginTable}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell size="small" align="center"><strong>Año</strong></TableCell>
                                            <TableCell size="small" align="center"><strong>Monto Moneda Local</strong></TableCell>
                                            <TableCell size="small" align="center"><strong>Moneda Local</strong></TableCell>
                                            <TableCell size="small" align="center"><strong>Monto en Dolares</strong></TableCell>
                                            <TableCell size="small" align="center"><strong></strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody id='tableBody'>
                                        {this.state.investmentYearTable !== [] &&
                                            <>
                                                {this.state.investmentYearTable.map((element, index) => (
                                                    <TableRow >
                                                        <TableCell size="small" align="center">
                                                            {this.state['table6' + index] ?
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                                                    <KeyboardDatePicker
                                                                        inputVariant="outlined"
                                                                        margin="dense"
                                                                        color="primary"
                                                                        views={["year"]}
                                                                        format="yyyy"
                                                                        invalidDateMessage="Formato inválido"
                                                                        maxDateMessage="Superó el límite máximo"
                                                                        minDateMessage="Superó el límite minimo"
                                                                        disablePast={this.props.disable}
                                                                        disableFuture={this.props.disable}
                                                                        inputProps={{ readOnly: this.props.disable }}
                                                                        name={investmentYearTableProps[0].name}
                                                                        value={this.state[investmentYearTableProps[0].name]}
                                                                        InputAdornmentProps={{ position: "end" }}
                                                                        onChange={(value, date) => this.handleDateChange(value, date, investmentYearTableProps[0].name)}
                                                                        KeyboardButtonProps={{
                                                                            'aria-label': 'change date',
                                                                            'color': 'primary'
                                                                        }}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                                :
                                                                element[investmentYearTableProps[0].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="center">
                                                            {this.state['table6' + index] ?
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    margin="dense"
                                                                    type={investmentYearTableProps[1].format}
                                                                    name={investmentYearTableProps[1].name}
                                                                    value={this.state[investmentYearTableProps[1].name]}
                                                                    onChange={(event) => this.onChangeValues(event)}
                                                                />
                                                                :
                                                                element[investmentYearTableProps[1].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="center">
                                                            {this.state['table6' + index] ?
                                                                <FormControl>
                                                                    {/* <InputLabel>{awardsTableProps[2].label}</InputLabel> */}
                                                                    <Select
                                                                        id="outlined-size-small"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        margin="dense"
                                                                        name={investmentYearTableProps[2].name}
                                                                        value={this.state[investmentYearTableProps[2].name]}
                                                                        onChange={(event) => this.onChangeValues(event)}
                                                                    >
                                                                        {this.state.currencyList &&
                                                                            this.state.currencyList.map((element) => (
                                                                                <MenuItem key={element.codigo_valor} value={element.presentacion_valor}>{element.presentacion_valor}</MenuItem>
                                                                            ))}
                                                                    </Select>
                                                                </FormControl>
                                                                :
                                                                element[investmentYearTableProps[2].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="center">
                                                            {this.state['table6' + index] ?
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    margin="dense"
                                                                    type={investmentYearTableProps[3].format}
                                                                    name={investmentYearTableProps[3].name}
                                                                    value={this.state[investmentYearTableProps[3].name]}
                                                                    onChange={(event) => this.onChangeValues(event)}
                                                                />
                                                                :
                                                                element[investmentYearTableProps[3].name]
                                                            }
                                                        </TableCell>
                                                        <TableCell size="small" align="center">
                                                            {icons.map((icon) => (
                                                                <a onClick={(event) => this.actions(event, icon.pointer, index, investmentYearTableProps, "investmentYearTable", "table6", "addFieldsTable6")}>{icon.icono}</a>
                                                            ))}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </>
                                        }
                                        <TableRow>
                                            {this.state.addFieldsTable6 &&
                                                <>
                                                    {this.state.fieldsInvestmentYear.map((element) => (
                                                        <>
                                                            {element.type !== "select" ?
                                                                <>
                                                                    {element.type !== "date" ?
                                                                        <TableCell padding="default" size="small" align="center">
                                                                            <TextField
                                                                                id="outlined-size-small"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                margin="dense"
                                                                                type={element.format}
                                                                                name={element.name}
                                                                                value={this.state[element.name]}
                                                                                onChange={(event) => this.onChangeValues(event)}
                                                                            />
                                                                        </TableCell>
                                                                        :
                                                                        <TableCell padding="default" size="small" align="right">
                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                                                                <KeyboardDatePicker
                                                                                    inputVariant="outlined"
                                                                                    margin="dense"
                                                                                    color="primary"
                                                                                    views={["year"]}
                                                                                    format="yyyy"
                                                                                    name={element.name}
                                                                                    invalidDateMessage="Formato inválido"
                                                                                    maxDateMessage="Superó el límite máximo"
                                                                                    minDateMessage="Superó el límite minimo"
                                                                                    disablePast={this.props.disable}
                                                                                    disableFuture={this.props.disable}
                                                                                    inputProps={{ readOnly: this.props.disable }}
                                                                                    value={this.state[element.name]}
                                                                                    InputAdornmentProps={{ position: "end" }}
                                                                                    onChange={(value, date) => this.handleDateChange(value, date, element.name)}
                                                                                    KeyboardButtonProps={{
                                                                                        'aria-label': 'change date',
                                                                                        'color': 'primary'
                                                                                    }}
                                                                                />
                                                                            </MuiPickersUtilsProvider>
                                                                        </TableCell>
                                                                    }
                                                                </>
                                                                :
                                                                <TableCell padding="default" size="small" align="center">
                                                                    <FormControl>
                                                                        {/* <InputLabel>{element.label}</InputLabel> */}
                                                                        <Select
                                                                            id="outlined-size-small"
                                                                            variant="outlined"
                                                                            size="small"
                                                                            margin="dense"
                                                                            name={element.name}
                                                                            value={this.state[element.name]}
                                                                            onChange={(event) => this.onChangeValues(event)}
                                                                        >
                                                                            {this.state[element.nameOptions] &&
                                                                                this.state[element.nameOptions].map((val) => (
                                                                                    <MenuItem key={val[element.nameValueKey]} value={val[element.nameMessage]}>{val[element.nameMessage]}</MenuItem>
                                                                                ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </TableCell>
                                                            }
                                                        </>
                                                    ))}
                                                </>
                                            }
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const complementaryData = withStyles(styles, { withTheme: true })(ComplementaryData);

function mapStateToProps(state) {

    return {
        disable: state.dashboard.disableField,
        saveJSONTABLES: state.saveForms.saveJSONTABLES,
    };
}

export default connect(
    mapStateToProps,
    {
        savingJsonTables,
        postUploadImage,
        spinnerImageDirection,
        // postCreateOrganization
    }
)(complementaryData);