
import axios from 'axios'
import { PUBLICUSER_REGISTER } from '../../Constants';
import {showErrorModal} from '../_actionShowErrorModal'
import {loader} from '../../Actions/_actionLoader'

export function postPublicUserRegister(name, surname, email, password, typeUser, country, typeRegister) {
    
    return dispath => {
        let properties = JSON.parse(localStorage.getItem('properties'));
        let PUBLICUSERREGISTER_ENPOINT = properties.ENPOINTS.PUBLICUSERREGISTER_ENPOINT;
        const typeUserCombo = JSON.parse(localStorage.getItem('typeUserCombo'));
        const countries = JSON.parse(localStorage.getItem('countries'));
        let id_aplication
        let id_rol
        let country_id
        countries.map((element) => {
            if(element.nombre_pais === country){
                country_id = element.id_pais
            }
        })
        typeUserCombo.map((element) => {
            if(element.nombre_rol === typeUser){
                id_aplication = element.id_aplicacion
                id_rol = element.id_rol
            }
        })


        axios({
            method: 'POST',
            url: PUBLICUSERREGISTER_ENPOINT,
            data: {
                "name":name,
                "lastname":surname,
                "email":email,
                "password":password,
                "transaction_id":null,
                "application_roles":
                [
                    {
                        "IdAplicacion":id_aplication,
                        "Roles":
                        [
                            {
                                "IdRol":id_rol
                            }
                        ]
                    }
                ],
                "country_id":country_id
            }
        }).then((response) => {
            dispath(loader(false))
            if(response.data.code === "200"){
                dispath(showErrorModal(true, properties.messages.successRegister, typeRegister, 0, true))
            }else{
                dispath(showErrorModal(true, properties.messages.failRegister, typeRegister, 1, false))
            }
        }).catch(error => {
                dispath(loader(false))
                let message = properties.message
                if(error.response){
                    properties.errorMessage.map((element) => {
                        if(element.value === error.response.data.code){
                            message = element.message
                        }
                    })
                }   
                dispath(showErrorModal(true, message, typeRegister))
            });
    }
}
