import { UPLOADIMAGE_MYORGANIZATION, UPLOADIMAGE_DIRECTION } from '../Constants'

export const uploadImageMyOrganization = (payload, image) => {
    return {
        type: UPLOADIMAGE_MYORGANIZATION,
        payload: payload,
        image: image,
    }
}

export const uploadImageDirection = (payload, image) => {
    return {
        type: UPLOADIMAGE_DIRECTION,
        payload: payload,
        image: image,
    }
}