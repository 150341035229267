export const styles = theme => ({
    table: {
        // minWidth: 650,
      },
      title: {
        margin: theme.spacing(0, 0, 1, 0),
      },
      rootPaper: {
        margin: theme.spacing(1, 1, 0, 2),
        minWidth: '97%',
      },
      titleTable:{
        margin: theme.spacing(2, 0, 0, 0),
      },
      buttons: {
        margin: theme.spacing(1, 0, 1, 1),
        marginTop: '2%',
        padding: theme.spacing(1, 2, 1, 2),
        float: 'right',
        // height: '50%',
        backgroundColor: '#494377',
        borderRadius: '0px !important',
      },
      buttons2: {
        margin: theme.spacing(1, 0, 1, 35),
        marginTop: '2%',
        padding: theme.spacing(1, 2, 1, 2),
        float: 'right',
        backgroundColor: '#494377',
        borderRadius: '0px !important',
      },
      icon: {
        background: '#494377',
        padding: '6px',
        marginRight: '4px',
        blockSize: '30px'
      },
      titleButton: {
        margin: theme.spacing(0, 0, 1, 0),
      },
      inline: {
        display: 'flex',
      },
      marginTable: {
        margin: theme.spacing(0, 0, 4, 0),
      },
      pagination: {
        marginLeft: '25%', 
        marginTop: '2%'
      }
  });