

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StepButton from '@material-ui/core/StepButton';
import { styles } from './constants'
import Grid from '@material-ui/core/Grid';

// COMPONENTES
import AgentData from './AgentData'
import DirectionData from './DirectionData'
import ComplementaryData from './ComplementaryData'
import ModalAlert from '../../../ModalAlert'

//ACTIONS
import { postCreateOrganization } from '../../../../Actions/Services/postCreateOrganization'
import { savingJsonTables } from '../../../../Actions/_actionSavingJsonTables'
import { wizardAddOrganization, organizationDash } from '../../../../Actions/_actionDashboard'
import { getOrganizationByUser } from '../../../../Actions/Services/getOrganizationByUser'

const steps = ['Datos del agente', 'Dirección', 'Datos complementarios'];
const varsJsonAgentData = ['NameSurname', 'typeDonor', 'agentType']
const varsJsonDirectionData = ['ThematicArea', 'GeographicalScope', 'CountryOperation', 'Length', 'Latitude', 'CentralPhone', 'OriginId', 'OficceNumber', 'TypeOng', 'ObjetiveDev', 'Personalities', 'checkbox', 'checkbox2']
const varsJsonTables = ['beneficiaryTable', 'tableBeneficiary', 'executionInvSocialTable', 'investmentCountryTable', 'investmentAreaTable', 'investmentYearTable']

class Organizations extends Component {

  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      value: '',
      activeStep: 0,
      aux: false,
      count: 0
    }
  }

  getStepContent = (stepIndex, disable) => {
    switch (stepIndex) {
      case 0:
        return <AgentData setClick={click => this.clickChild = click} validateFields={this.validateFields}/>;
      case 1:
        return <DirectionData />;
      case 2:
        return <ComplementaryData runApiAndValidationForm={this.runApiAndValidationForm} />;
      default:
        return 'Unknown stepIndex';
    }
  }
  handleStep = step => () => {
    this.setState({ activeStep: step });
    if(this.state.activeStep === 0)this.validationNextButton()
  };

  validationNextButton = () =>{
    this.clickChild()
  }
  
  validateFields = () =>{
    this.setState({ activeStep: 0 });
  }
  
  runApiAndValidationForm = () => {

    if (this.state.activeStep === 3) {

        this.setState({ aux: true })
        if (this.props.disableField === false) {//PARA OPCIÓN "EDITAR ORGANIZACIÓN" EL VALOR DE this.props.disableField ES FALSE
          this.props.postCreateOrganization('PUT')
        } else {//PARA OPCIÓN "CREAR ORGANIZACIÓN" EL VALOR DE this.props.disableField ES NULL
          this.props.postCreateOrganization('POST')
        }//NOTA: PARA OPCIÓN "VER" EL VALOR DE this.props.disableField ES TRUE POR ENDE NO CUMPLE ESTA CONDICIÓN

    }
  }

  handleNext = () => {
    if (this.state.activeStep === steps.length - 1 && this.props.disableField) {//AL DARLE CLICK AL BOTÓN "SALIR" DESDE LA OPCIÓN VER ORGANIZACIÓN
      this.props.wizardAddOrganization(false)
      this.props.organizationDash(true)
    } else {
      this.setState({ activeStep: this.state.activeStep + 1, aux: false });
      if(this.state.activeStep === 0)this.validationNextButton()      
    }
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };
 
  handleCancel = () => {
    this.props.wizardAddOrganization(false)
    this.props.organizationDash(true)
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  render() {
    const { classes } = this.props;
    const { disable } = this.props;

    return (
      <>
        {this.props.showModal &&
          <ModalAlert></ModalAlert>
        }
        <div className={classes.root}>
          <Stepper activeStep={this.state.activeStep} alternativeLabel nonLinear>
            {steps.map((label, index) => (
              <Step key={label} >
                {/* <StepLabel>{label}</StepLabel> */}
                <StepButton onClick={this.handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <div>
            {this.state.activeStep === steps.length ? (
              <>
                <center>
                  <div>
                    {this.state.aux ?
                      <Typography className={classes.instructions}>
                        <strong>¡Todo el formulario completado con éxito!</strong>
                      </Typography>
                      :
                      <Typography className={classes.instructions}>
                        {/* <strong>Debe llenar los campos obligatorios * en el paso 1</strong> */}
                        <strong>{this.state.ErrorComent}</strong>
                      </Typography>
                    }
                  </div>
                </center>
              </>
            ) : (
                <div>
                  <Typography className={classes.instructions}>{this.getStepContent(this.state.activeStep, disable)}</Typography>
                  <center className={classes.styleButtons}>
                    <div>
                      {this.state.activeStep !== 2 && this.state.activeStep !== 3&&
                        <Button
                          variant="contained" 
                          color="primary"
                          onClick={this.handleCancel}
                          className={classes.backButton}
                        >
                          Cancelar
                        </Button>
                      }
                      
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={this.state.activeStep === 0}
                        onClick={this.handleBack}
                        className={classes.backButton}
                      >
                        Anterior
                        </Button>
                      <Button className={classes.buttonFinishNext} variant="contained" color="primary" onClick={this.handleNext}>
                        {this.state.activeStep === steps.length - 1 ? <>{this.props.disableField ? 'Salir' : 'Guardar'}</> : 'Próximo'}
                      </Button>

                    </div>
                  </center>
                </div>
              )}
          </div>
        </div>
      </>
    );
  }
}

const organizations = withStyles(styles, { withTheme: true })(Organizations);

function mapStateToProps(state) {
  return {
    objeto: state.dashboard.objeto,
    saveJSONTABLES: state.saveForms.saveJSONTABLES,
    disable: state.dashboard.disableField,
    showModal: state.errorShowModal.showModal,
    disableField: state.dashboard.disableField
  };
}


export default connect(
  mapStateToProps,
  {
    postCreateOrganization,
    savingJsonTables,
    wizardAddOrganization,
    organizationDash,
    getOrganizationByUser,
  }
)(organizations);